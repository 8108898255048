<template>
    
            <Card>
                <template #title> <span class="text-base"> {{ institution.ie_id }} : {{institution.institucion_educativa }}</span>  </template>
                <template #content>
                   <div class="grid">
                        <div class="col">
                            <Panel header="Datos Generales" toggleable :collapsed="false" >
                                <template #icons>
                                    <div class="p-panel-header-icon " v-if="institution && role.rol !== 'DIRECTOR' ">
                                        <a   class="kicon-button-dark"  @click="open_edit()">
                                                <div class="icon">
                                                    <i class="duotone icon-pencil"></i>
                                                </div>
                                        </a>
                                    </div>
                                    <div class="p-panel-header-icon ">
                                        
                                        <a   class="kicon-button-dark"  @click="upload_dialog=true">
                                            <div class="icon">
                                                <i class='bx bx-image-add' ></i>
                                            </div>
                                        </a>
                                       
                                    </div>

                                    <div class="p-panel-header-icon " v-if="institution && role.rol !== 'DIRECTOR' ">
                                        
                                        <a   class="kicon-button-dark"  @click="getHistories()">
                                            <div class="icon">
                                                <i class='bx bx-history'></i>
                                            </div>
                                        </a>
                                       
                                    </div>
                                   
                                </template>
                                <div class="grid">
                                    <div class="col-4 kimage-content ">
                                        <div class="klogo">
                                            <img class="kimage-logo" :src="image64" alt="" v-if="image64">
                                            <img class="kimage-logo"  src="/no_image2.png" alt="" style="width: 100%; height: auto;" v-else>
                                        </div>
                                    </div>
                                    <div class="col-8">
                                        <table class="table table-sm">
                                            <tbody>
                                                <tr>
                                                    <td class=" text-end "><strong>Codigo RITT:</strong></td>
                                                    <td class="">{{ institution.ie_id }}</td>
                                                </tr>
                                                <tr>
                                                    <td class=" text-end "><strong>Nombre:</strong> </td>
                                                    <td class="">{{ institution.institucion_educativa }}</td>
                                                </tr>
                                                <tr>
                                                    <td class=" text-end "><strong>Resolución de Creación:</strong> </td>
                                                    <td class=""> {{ institution.numero_resolucion }}</td>
                                                </tr>
                                                <tr>
                                                    <td class=" text-end "><strong>Fecha Resolución:</strong></td>
                                                    <td class=""> {{$filters.dateString(institution.fecha_resolucion ?? '')  }}</td>
                                                </tr>
                                                <tr>
                                                    <td class=" text-end "><strong>Carácter Jurídico:</strong> </td>
                                                    <td class=""> {{ institution.caracter_juridico }}</td>
                                                </tr>
                                                <tr>
                                                    <td class=" text-end "><strong>Estado:</strong></td>
                                                    <td class=""> {{ institution.estado }}</td>
                                                </tr>
                                                <tr>
                                                    <td class=" text-end "> <strong>Tipo:</strong></td>
                                                    <td class=""> {{ institution.tipo_institucion }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div class="flex justify-content-center ">

                                            <router-link class="btn btn-secondary flex " :to="`/institution-certificate/${institution.ie_id}`" target="_blank"  v-if="institution && role.rol !== 'DIRECTOR' ">
                                                <i class='bx bxs-file-pdf text-xl pr-2' ></i>
                                                CERTIFICADO
                                            </router-link >
                                            <div class="pr-2"></div>
                                            <router-link class="btn btn-secondary flex" :to="`/institution-stadistic/${institution.ie_id}`" target="_blank"  v-if="institution && role.rol !== 'DIRECTOR' ">
                                                <i class='bx bxs-file-pdf text-xl pr-2' ></i>
                                                DATOS ESTADÍSTICOS
                                            </router-link >
                                        </div>
                                    </div>
                                    
                                </div>
                            </Panel>
                        </div>
                        <div class="col">
                            <Panel header="Ubicación del Instituto de Formación Artística" toggleable :collapsed="false">
                                <template #icons>
                                    <div class="p-panel-header-icon ">
                                        
                                            <!-- <router-link class="kicon-button-dark" v-tooltip.left="'Cerficado'" :to="`/institution-certificate/${institution.ie_id}`" target="_blank"  v-if="institution">
                                                
                                                <i class='bx bx-printer' ></i>
                                            </router-link > -->
                                            <a   class="kicon-button-dark"  @click="maps_dialog=true; editMap()">
                                                <div class="icon">
                                                    <i class="duotone icon-pencil"></i>
                                                </div>
                                            </a>
                                       
                                    </div>
                                </template>
                                <table class="table table-sm">
                                    <tbody>
                                        <tr>
                                            <td class=" text-end "><strong>Código LE:</strong></td>
                                            <td class="">{{ institution.cod_le }}</td>
                                        <!-- </tr>
                                        <tr> -->
                                            <td class=" text-end "><strong>Departamento:</strong></td>
                                            <td class="">{{ institution.departamento }}</td>
                                        </tr>
                                        <tr>
                                            <td class=" text-end "><strong>Provincia:</strong></td>
                                            <td class="">{{ institution.provincia }}</td>
                                        <!-- </tr>
                                        <tr> -->
                                            <td class=" text-end "><strong>Municipio:</strong></td>
                                            <td class="">{{ institution.municipio }}</td>
                                        </tr>
                                        <tr>
                                            <td class=" text-end "><strong>Canton:</strong></td>
                                            <td class="">{{ institution.canton }}</td>
                                        <!-- </tr>
                                        <tr> -->
                                            <td class=" text-end "><strong>Localidad:</strong></td>
                                            <td class="">{{ institution.localidad }}</td>
                                        </tr>
                                        <tr>
                                            <td class=" text-end "><strong>Zona:</strong></td>
                                            <td class="">{{ institution.zona }}</td>

                                            <td class=" text-end "><strong>Dirección:</strong></td>
                                            <td class="">{{ institution.direccion }}</td>
                                        </tr>

                                    </tbody>
                                </table>
                                
                                <!-- <GoogleMap api-key="AIzaSyCXyIROL1yNeF8Jz9mAiOxh-QMlQAhUGkI" style="width: 100%; height: 200px" :center="center" :zoom="16" v-if="center">
                                
                                    <Marker :key="index" v-for="(m, index) in markers"  :options="{position: m.position, label: 'L', title: 'LADY LIBERTY'} " :draggable="true" > </Marker>
                                </GoogleMap> -->
                                <!-- <GoogleMap api-key="AIzaSyCXyIROL1yNeF8Jz9mAiOxh-QMlQAhUGkI" style="width: 100%; height: 200px"   :center="center" :zoom="16" v-if="center">
                                    <Marker
                                    :key="index"
                                    v-for="(m, index) in markers"
                                    />
                                </GoogleMap> -->
                                <!-- <GMapMap
                                    :center="center"
                                    :zoom="16"
                                    style="width: 100%; height: 200px"
                                    v-if="center"
                                >
                                <GMapMarker
                                    :key="index"
                                    v-for="(m, index) in markers"
                                    :position="m.position"
                                
                                    :icon="'/location.png'"
                                    
                                   
                                    >
                                    <GMapInfoWindow
                                        :opened="true"
                                    >
                                        <div>{{institution.institucion_educativa }} <MyComponent/>
                                        </div>
                                    </GMapInfoWindow>
                                
                                
                                </GMapMarker>
                                </GMapMap> -->
                                <!-- <div style="height: 400px;">
                                    <l-map :zoom="zoom" :center="center">
                                        <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
                                        <l-marker :lat-lng="markerLatLng"></l-marker>
                                    </l-map>
                                </div> -->

                                
                                <div id="mapContainer" style="height: 156px;width: 100%;"></div>
                      
                            </Panel>
                        </div>
                   </div>
                   <div class="grid">
                        <div class="col-12">
                            <DataTableCareerInstitution></DataTableCareerInstitution> 
                            <Panel header="Carreras" toggleable >
                                <CourseIntitutionArtistic></CourseIntitutionArtistic>
                            </Panel>
                        </div>
                   </div>
                   <div class="grid">
                        <div class="col-12">
                            <!-- <DataTableCareerInstitution></DataTableCareerInstitution> -->
                            <Panel header="Cursos" toggleable :collapsed="true">
                                <CareerArtisticInstitutions ></CareerArtisticInstitutions>
                            </Panel>
                        </div>
                   </div>
                </template>
            </Card>
            
            <!-- dialogs here -->

            <Dialog v-model:visible="pdf_dialog" modal header="Certificado" :style="{ width: '50vw' }">
            <iframe :src="`/institution-certificate/${this.$route}`"  width="100%"  style="position: absolute; height: 100%; border: none"></iframe>
            </Dialog>

            <Dialog v-model:visible="upload_dialog" modal header="Logo Instituto" :style="{ width: '40vw' }">
                
                    <div style="display: flex; align-items: center; flex-direction: column; justify-content: center; ">
                        <div class="klogo">
                            <img :src="previewImage" class="kimage-logo" v-if="previewImage"/>
                            <img class="kimage-logo"  src="/no_image2.png" alt="" style="width: 100%; height: auto;" v-else>
                        </div>
                        <div class="p-3">
                        <label for="formFile" class="form-label">Logo de la Institución </label>
                        <input class="form-control" type="file" id="formFile" accept="image/x-png,image/jpeg" @change=uploadImage>
                        </div>
                 
                        <!-- <input type="file" accept="image/jpeg" style=" padding: 5px;"> -->
                        <Button label="Guardar Imagen"   @click="saveImage" icon="bx bx-image-add" />
                    </div>
                
         
            </Dialog>

            <Dialog v-model:visible="maps_dialog" modal header="Editar Ubicación Geografica" :style="{ width: '40vw' }" ref="modalMap">
                <div class="flex  flex-column align-content-between" ref="hijo">
                    <strong>Edite la ubicacion arrastrando el icono <img src="/location.png" > que aparece en el mapa a su ubicacion actual.</strong>

                    <!-- <GMapMap
                            :center="center"
                            :zoom="16"
                            style="width: 100%; height: 400px"
                            v-if="center"
                        >
                        <GMapMarker
                            :key="index"
                            v-for="(m, index) in markers"
                            :position="m.position"
                            :clickable="true"
                            :draggable="true"
                            :icon="'/location.png'"
                            
                            @drag="getPosition"
                            >
                            <GMapInfoWindow
                                :opened="true"
                            >
                                <div>{{institution.institucion_educativa }} <MyComponent/>
                                </div>
                            </GMapInfoWindow>

                            </GMapMarker>
                            
                    </GMapMap> -->
                    <div style="height: 400px;">

                        <!-- <l-map :zoom="13" :center="center" style="height: 400px;">
                            <l-tile-layer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"></l-tile-layer>
                            <l-marker :lat-lng="center"></l-marker>
                        </l-map> -->
                        <map-leaflet :institution_id="this.$route.params.id" @updateLocation="setLocation" ></map-leaflet>
                        <!-- <div id="mapViewer" style="height: 400px;width: 100%;"></div> -->
                    </div>
                    <span><strong> Latitud:</strong>{{ location.lat }}  <strong>Longitud:</strong> {{ location.lng }} </span>
                
                     
                </div>
                <template #footer>
                    <Button label="Cancelar" icon="pi pi-times" @click="maps_dialog = false" text />
                    <Button label="Guardar" icon="pi pi-check" @click="updateGeolocation" autofocus />
                </template>
            </Dialog>

            <Dialog v-model:visible="edit_dialog" modal header="Editar Datos Generales" :style="{ width: '50vw' }">
                
                <div class="flex flex-column">
                    <label for="username" class="font-semibold">Denominación: </label>
                    <InputText id="username" class="flex-auto" autocomplete="off" v-model="form.toDenominacion" />
                </div>

                <div class="flex flex-column pt-2">
                    <label for="email" class="font-semibold ">Caractere Jurídico: </label>
                    <Dropdown v-model="form.dependencia" :options="dependencias" optionLabel="dependencia" placeholder="Seleccionar dependencia " class="w-full " />
                </div>

                <div class="flex flex-column pt-2">
                    <label for="username" class="font-semibold">Descripción: </label>
                    <InputText id="username" class="flex-auto" autocomplete="off" v-model="form.descripcion" />
                </div>

                <div class="flex justify-content-end pt-4" >
                    <Button type="button" label="Cancelar" severity="secondary" @click="edit_dialog = false" ></Button>
                    <div class="pr-2"></div>
                    <Button type="button" label="Actualizar" @click="createHistory()"></Button>
                </div>
            
        
            </Dialog>

            <Dialog v-model:visible="history_dialog" modal :header="`Historial del Instituto `" :style="{ width: '50vw' }">
                <Timeline :value="histories" class="w-full">
                    <template #opposite="slotProps">
                        <div class="flex flex-column">
                            <small class="text-blue-500">{{ $filters.getFecha(slotProps.item.fechaRegistro)}}</small>
                            <small class="text-blue-500">{{ $filters.getHora(slotProps.item.fechaRegistro)}}</small>
                        </div>
                    </template>
                    <template #content="slotProps">
                        <div v-if="slotProps.item.fromDenominacion !== slotProps.item.toDenominacion">
                            <Message severity="success" :closable="false">
                                Se cambio de denominacion de <strong> {{ slotProps.item.fromDenominacion }} </strong> a <strong> {{ slotProps.item.toDenominacion }}</strong>
                            </Message>
                        </div>

                        <div v-if="slotProps.item.fromDependenciaTipoId !== slotProps.item.toDependenciaTipoId">
                            <Message severity="success" :closable="false">
                                Se cambio de Caracter Jurídico de <strong> {{ slotProps.item.fromDependenciaTipo.dependencia }} </strong> a <strong> {{ slotProps.item.toDependenciaTipo.dependencia }}</strong>
                            </Message>
                        </div>

                        <Message severity="info" :closable="false" icon="bx bxs-user">
                                Realizado por <strong> {{ slotProps.item.usuario.persona.nombre }} {{ slotProps.item.usuario.persona.paterno }} {{ slotProps.item.usuario.persona.materno }}</strong> 
                        </Message>
                    </template>
                </Timeline>
                <InlineMessage class="w-full" severity="info" v-if="histories.length === 0 ">No hay cambios historicos del Instituto</InlineMessage >
            </Dialog>

</template>
<script>
import InstitutionDataService from "@/services/InstitutionDataService";
// import { GoogleMap, Marker } from "vue3-google-map";
// // import "leaflet/dist/leaflet.css";
// import { LMap, LTileLayer, LMarker } from 'vue3-leaflet';
import "leaflet/dist/leaflet.css";
import L from "leaflet";
// import DataTableCareerInstitution from '@/components/DataTable   CareerInstitution';
// import HelloWorld from "@/components/HelloWorld.vue";
import CareerArtisticInstitutions from "@/components/CareerArtisticInstitution.vue";
import CourseIntitutionArtistic from "@/components/CourseIntitutionArtistic.vue";
import MapLeaflet from "@/components/MapLeaflet.vue"
// import markerIconPng from "leaflet/dist/images/marker-icon.png"
export default{
   
    data:()=>({
        logo:'',
        institution: {},
        // center:null,
        pdf_dialog:false,
        image64:null,
        upload_dialog:false,
        maps_dialog:false,
        edit_dialog:false,
        history_dialog:false,
        histories: [],
        form:{},
        location: {},
        image_file:{},
        dependencias: [],
        previewImage:null,
        institution_name:'no title',
        markers:[{
          position: {
            lat: 51.093048, lng: 6.842120
          },
        }],
        marker: null,
        mapDiv: null,
        zoom: 16,
        institution_maker: [-16.5061403904391, -68.12774675921834 ],
        institution_center: [-16.5061403904391, -68.12774675921834 ],
        center: [-16.5061403904391, -68.12774675921834 ], // Default center coordinates
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', // Tile layer URL
        attribution: 'Map data © <a href="https://openstreetmap.org">OpenStreetMap</a> contributors', // Attribution
        markerLatLng: [-16.5061403904391, -68.12774675921834 ] // Marker coordinates
    }),
    mounted()
    {

      this.reloadData()
    },
   methods:{

    reloadData()
    {
        this.retrieveInstitution()
        this.getImage()
        this.getDependencias()
    },

    setupLeafletMap() {
        this.mapDiv = L.map("mapContainer").setView(this.center, 15);
        const homeIcon = L.icon({
        iconUrl: '/location.png',
        popupAnchor: [0, -15],
        iconSize: [40, 40],
        // ...
        });
        L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
            maxZoom: 19,
            attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
        }).addTo( this.mapDiv);
       this.marker = new L.marker(this.center, {icon: homeIcon}).addTo( this.mapDiv)
            .bindPopup(`${this.institution.institucion_educativa}`)
            .openPopup();

    },

    setLocation(location )
    {
        console.log('location',location)
        if(location)
        {
            this.location.lat = location[0]
            this.location.lng = location[1]
        }
    },
    editMap() {
        console.log('Renderisando Mapa')
        // let editTag = document.getElementById('modalMap')
        let editRef = this.$refs.modalMap
        console.log(editRef)
        console.log(editRef.$refs.hijo)
       
    },

    getPosition(location)
    {
        this.location = {
                lat: location.latLng.lat(),
                lng: location.latLng.lng(),
            };
        // console.log('new ',this.location)
    },

    async getDependencias()
    {
        console.log('obteniendo dependencias')
        let response = await InstitutionDataService.getDependencias()
        this.dependencias = response.data
        console.log('dependcias', this.dependencias)
        // InstitutionDataService.getDependencias()
        //     .then((response)=>{
        //         this.dependencias = response.data
        //         console.log('dependencias',this.dependencias)
        //     })
    },

    updateGeolocation()
    {
        let payload = {
            cordx: this.location.lat,
            cordy: this.location.lng
        }

        InstitutionDataService.updateGeolocation(this.institution.cod_le,payload)
            .then((response)=>{
                console.log(response.data)
                this.$toast.add({ severity: 'success', summary: 'Se Actualizo la ubicacion', detail: 'Ubicacion Actualizada', life: 3000 });
                this.maps_dialog = false
                // this.retrieveInstitution()
                
                
                this.marker.setLatLng(new L.LatLng(this.location.lat, this.location.lng))
                // this.mapDiv.setLatLng(new L.LatLng(this.location.lat, this.location.lng))
            })
    },

    retrieveInstitution()
    {
        InstitutionDataService.getInfo(this.$route.params.id)
            .then((response) => {
                console.log('instituto',response.data);
                this.institution = response.data;
                // this.center = { lat: -16.5061403904391, lng: -68.12774675921834 }
                this.center = [-16.5061403904391, -68.12774675921834 ]
                // center: [51.505, -0.09]
                if(this.institution.cordx)
                {
                    this.center = [this.institution.cordx, this.institution.cordy]
                    // this.center.lat = this.institution.cordx
                    // this.center.lng = this.institution.cordy
                }
                this.markerLatLng = [this.institution.cordx, this.institution.cordy]
                this.institution_maker [this.institution.cordx, this.institution.cordy]
                this.markers = []
                this.markers.push({ position: this.center})
                // console.log()
                this.setupLeafletMap()

                this.institution_name = this.institution.institucion_educativa
                this.institution_center = Object.assign({}, this.center)
                // console.log(response);
            })
            .catch((e) => {
                console.log(e);
            }); 
    },
    getImage(){
        InstitutionDataService.getImage(this.$route.params.id)
            .then((response)=>{
              
              this.image64 =this._imageEncode(response.data)  
              this.previewImage = this.image64
           
            })
            .catch((e) => {
                this.image64 = null;
                this.previewImage = null
                console.log(e);
            }); 
    },
    _imageEncode (arrayBuffer) {
        let b64encoded = btoa([].reduce.call(new Uint8Array(arrayBuffer),(p,c)=>{return p+String.fromCharCode(c)},''))
        let mimetype="image/jpeg"
        return "data:"+mimetype+";base64,"+b64encoded
    },
    uploadImage(e){
        let image = e.target.files[0];
        console.log(image)
        this.image_file = image
        const reader = new FileReader();
        reader.readAsDataURL(image);
        reader.onload = e =>{
            this.previewImage = e.target.result;
            // console.log(this.previewImage);
        };
    },
    saveImage(){
        let formData = new FormData()
        formData.append('file',this.image_file)
        formData.append('institucion_educativa_id', this.institution.ie_id)
        InstitutionDataService.uploadImage(formData)
            .then((response)=>{
                console.log(response.data)
                this.getImage()
                this.$toast.add({ severity: 'success', summary: 'Se Actualizo la Imagen', detail: 'Imagen Actualizada', life: 3000 });
                this.upload_dialog = false
            })
    },
    async open_edit()
    {
        this.edit_dialog = true
        this.form = {}
        this.form.institucionEducativaId = this.institution.ie_id
        this.form.institucionEducativaSucursalId = this.institution.sucursal_id
        this.form.fromDenominacion = this.institution.institucion_educativa
        this.form.toDenominacion = this.institution.institucion_educativa
        this.form.fromDependenciaTipoId = this.institution.dependencia_id
        this.form.toDependenciaTipoId = this.institution.dependencia_id
        this.form.descripcion = ""
        let response = await InstitutionDataService.getDependenciaTipo(this.institution.dependencia_id)
        this.form.dependencia = response.data

     
    },

    async createHistory()
    {
        let payload = Object.assign({},this.form)
        payload.toDependenciaTipoId = this.form.dependencia.id
        let response = await InstitutionDataService.createHistory(payload)
        console.log(response.data)
        this.reloadData()
        if(response.status === 201)
        {
            this.$toast.add({ severity: 'success', summary: 'Actualizacion Realizada', detail: 'Se actualizo la informacion del Instituto', life: 3000 });
        }else{
            this.$toast.add({ severity: 'error', summary: 'No se pudo Actualizar', detail: 'contactese con en el encargado del sistema', life: 3000 });
        }
        this.edit_dialog = false
        //TODO show toast
    },
    async getHistories()
    {
        let response = await InstitutionDataService.getInstitutionHistory(this.institution.ie_id)
        
        this.histories = response.data
        this.history_dialog = true
    }
   },
   computed: {
        role()
        {
            return this.$store.state.auth.rol
        },
   },
   components:{
        CareerArtisticInstitutions,CourseIntitutionArtistic,
        MapLeaflet,
     
    }

}
</script>

<!-- para cambiar el colordel panel -->
<style scoped>
:deep(.p-panel .p-panel-header) {
    background-color: #e6f5ff;
    border-color: #b3d9ff;
    color: #002e4d;
}

:deep(.p-panel .p-panel-content) {
    border-color:  #b3d9ff;
}
</style>