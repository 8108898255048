<template>
    <div class="card card-w-title">
        <!-- <BlockUI :blocked="props.formData.person !== 0 && props.formData.person !== null && props.formData.person !== undefined"> -->
        <BlockUI :blocked="false">
            <div class="formrid grid">
                <div class="field col-12 md:col-3 mb-0">
                    <label for="identityCard">Cédula de Identidad </label>
                    <InputText id="identityCard" v-model="form.identityCard" :useGrouping="false" placeholder="#######"  :disabled="!form.can_edit"/>
                    <small v-show="validationErrors.identityCard && submitted" class="p-error">es requerido</small>
                </div>
                <div class="field col-12 md:col-3 mb-0">
                    <label for="complement">Complemento</label>
                    <InputText id="complement" v-model="form.complement" type="text" maxlength="2" style="text-transform:uppercase;" :disabled="!form.can_edit"/>
                </div>
                <div class="field col-12 md:col-4 mb-0">
                    <label for="">Tipo de C.I.</label>
                    <div class="flex flex-wrap gap-3">
                        <div class="flex align-items-center">
                            <RadioButton v-model="form.identityCardType" inputId="identityCardTypeN" name="identityCardType" value="1" />
                            <label for="identityCardTypeN" class="ml-2">Nacional</label>
                        </div>
                        <div class="flex align-items-center">
                            <RadioButton v-model="form.identityCardType" inputId="identityCardTypeE" name="identityCardType" value="2" />
                            <label for="identityCardTypeE" class="ml-2">Extranjero</label>
                        </div>
                    </div> 
                    <small v-show="validationErrors.identityCardType && submitted" class="p-error">es requerido</small>
                </div>
                <div class="field col-12 md:col-2 mb-0">
                    <label for="issued">Expedido</label>
                    <AutoComplete v-model="form.issued" id="issued" optionLabel="lugar" :suggestions="filteredIssued" @complete="searchIssued" dropdown forceSelection />
                    <small v-show="validationErrors.issued && submitted" class="p-error">es requerido</small>
                </div>
                <div class="field col-12 md:col-3 mb-0">
                    <label for="birthdate">Fecha de Nacimiento</label>
                    <Calendar id="birthdate" v-model="form.birthdate" dateFormat="dd/mm/yy" :disabled="!form.can_edit"/>
                    <!-- <InputMask id="birthdate" v-model="form.birthdate" placeholder="99/99/9999" slotChar="mm/dd/yyyy" /> -->
                    <small v-show="validationErrors.birthdate && submitted" class="p-error">es requerido</small>
                </div>
                <div class="field col-12 md:col-3 mb-0">
                    <label for="name">Nombre(s)</label>
                    <InputText id="name" type="text" v-model="form.name" :disabled="!form.can_edit"/>
                    <small v-show="validationErrors.name && submitted" class="p-error">es requerido</small>
                </div>
                <div class="field col-12 md:col-3 mb-0">
                    <label for="firstLastName">Paterno</label>
                    <InputText id="firstLastName" type="text" v-model="form.firstLastName" :disabled="!form.can_edit"/>
                    <small v-show="validationErrors.firstLastName && submitted" class="p-error">es requerido</small>
                </div>  
                <div class="field col-12 md:col-3 mb-0">
                    <label for="secondLastName">Materno</label>
                    <InputText id="secondLastName" type="text" v-model="form.secondLastName" :disabled="!form.can_edit" />
                    <small v-show="validationErrors.secondLastName && submitted" class="p-error">es requerido</small>
                </div>         
               
                
                
                
               
               
                
            </div>    
        </BlockUI>    
        <div class="pt-3 flex justify-content-between align-items-center">
            <div class="text-left">
            </div>
            <div class="text-left">
                <Button label="Siguiente" @click="nextPage()" icon="pi pi-angle-right" iconPos="right" />
            </div>
        </div>
    </div>
</template>
        

<script setup>
import { ref, onMounted, onBeforeMount, defineEmits, defineProps } from 'vue';
import TerritoryTypeDataService from '@/services/TerritoryTypeDataService';
import GenderTypeDataService from '@/services/GenderTypeDataService';
import PersonDataService from '@/services/PersonDataService';
import { useToast } from 'primevue/usetoast';

const validationErrors = ref({});
const toast = useToast();
const props = defineProps({
    formData: {
        type: Object,
        default: null
    },
    type: {
        type: String,
        default: ''
    }
});

const form = ref({
    identityCard: null,
    complement: '',
    identityCardType: '',
    issued: '',
    birthdate: '',
    name: '',
    firstLastName: '',
    secondLastName: '',
    country: null,
    department: null,
    province: null,
    municipality: null,
    community: null,
    location: ''    
});

const issued = ref({});
const gender = ref({});
const country = ref({});
const department = ref({});
const province = ref({});
const municipality = ref({});
const community = ref({});

onMounted(() => {

    // listCountry()
    department.value = [];
    province.value = [];
    municipality.value = [];
    community.value = [];
    // console.log('income',props.formData)
    form.value.can_edit = props.formData.can_edit
    form.value.is_student = props.formData.is_student
    if( !props.formData.id ){
        form.value.identityCard = Number(props.formData.identityCard)
        form.value.complement = props.formData.complement
        return;
    }

    if( props.formData.id === 0 ){
        form.value.identityCard = Number(props.formData.identityCard)
        form.value.complement = props.formData.complement
        return;
    }

    form.value.identityCard = props.formData.identityCard
    form.value.complement = props.formData.complement
    form.value.identityCardType = props.formData.identityCardType
    form.value.issued = props.formData.issued
    form.value.birthdate = new Date(`${props.formData.birthdate}T00:00:00`) 
    form.value.name = props.formData.name
    form.value.firstLastName = props.formData.firstLastName
    form.value.secondLastName = props.formData.secondLastName
    form.value.country = props.formData.country
    console.log( '----------------------',props.formData.country)

    if(props.formData.country === null){
        return;
    }

    console.log( '----------------------llegp')
    TerritoryTypeDataService.getDepartment(props.formData.country).then((data) => {
        department.value = data.data.data;
        console.log('departamtentos',department.value)
        if (!Object.is(props.formData.department)){
            form.value.department = (department.value)[findIndexDataById(department.value, props.formData.department)];
        }
    });
    if(props.formData.department === null){
        return;
    }
    form.value.department = props.formData.department
    TerritoryTypeDataService.getProvince(props.formData.department).then((data) => {
        province.value = data.data.data;
        if (!Object.is(props.formData.province)){
            form.value.province = (province.value)[findIndexDataById(province.value, props.formData.province)];
        }
    });
    if(props.formData.province === null){
        return;
    }
    form.value.province = props.formData.province
    TerritoryTypeDataService.getMunicipality(props.formData.province).then((data) => {
        municipality.value = data.data.data;
        if (!Object.is(props.formData.municipality)){
            form.value.municipality = (municipality.value)[findIndexDataById(municipality.value, props.formData.municipality)];
        }
    });
    if(props.formData.municipality === null){
        return;
    }
    form.value.municipality = props.formData.municipality
    TerritoryTypeDataService.getCommunity(props.formData.municipality).then((data) => {
        community.value = data.data.data;
        if (!Object.is(props.formData.community)){
            form.value.community = (community.value)[findIndexDataById(community.value, props.formData.community)];
        }
    });
    form.value.community = props.formData.community
    form.value.location = props.formData.location
   
});

onBeforeMount(() => {
    console.log('onbeforeMount')
    listIssued();
    listGender();
    listCountry();
    if( props.formData.id === 0 ){
        return;
    }
    listProvince();
    listMunicipality();
    listCommunity();

})



const listIssued = () => {
    TerritoryTypeDataService.getIssued().then((data) => {
        issued.value = data.data.data;
        if (!Object.is(props.formData.issued)){
            form.value.issued = (issued.value)[findIndexDataById(issued.value, props.formData.issued)];
        }
    });
}

const filteredIssued = ref();
const searchIssued = (event) => {
    // let _items = [...issued.value.keys()];
    setTimeout(() => {
        if (!event.query.trim().length) {
            filteredIssued.value = [...issued.value];
        } else {
            filteredIssued.value = issued.value.filter((item) => {
                return item.lugar.toLowerCase().startsWith(event.query.toLowerCase());
            });
        }
    }, 250);
}

const listGender = () => {
    GenderTypeDataService.getAll().then((data) => {
        gender.value = data.data.data;
    });
}


const listCountry = () => {
    TerritoryTypeDataService.getCountry().then((data) => {
        country.value = data.data.data;
        console.log('response',data)
        console.log('country',country.value)
        if (!Object.is(props.formData.country)){
            form.value.country = (country.value)[findIndexDataById(country.value, props.formData.country)];
        }
    });
}
const filteredCountry = ref();
const searchCountry = (event) => {
    // let _items = [...country.value.keys()];
    setTimeout(() => {
        if (!event.query.trim().length) {
            filteredCountry.value = [...country.value];
        } else {
            filteredCountry.value = country.value.filter((item) => {
                return item.lugar.toLowerCase().startsWith(event.query.toLowerCase());
            });
        }
    }, 100);
}

const listDeparment = () => {
    filteredDepartment.value = [];
    department.value = [];
    form.value.department = null;
    form.value.province = null;
    form.value.municipality = null;
    form.value.community = null;
    if (form.value.country.id !== 1 && form.value.country.id !== "1"){
        console.log("asdsad");
        return;
    }
    if(!form.value.country){
        return;
    }
    if(!form.value.country.id){
        return;
    }
    console.log('country id',form.value.country.id)
    TerritoryTypeDataService.getDepartment(form.value.country.id).then((data) => {
        department.value = data.data.data;
        if (!Object.is(props.formData.department)){
            form.value.department = (department.value)[findIndexDataById(department.value, props.formData.department)];
        }
    });
}
const filteredDepartment = ref();
const searchDepartment = (event) => {    
    // let _items = [...department.value.keys()];
    setTimeout(() => {
        if (!event.query.trim().length) {
            filteredDepartment.value = [...department.value];
        } else {
            filteredDepartment.value = department.value.filter((item) => {
                return item.lugar.toLowerCase().startsWith(event.query.toLowerCase());
            });
        }
    }, 100);
}

const listProvince = () => {
    filteredProvince.value = [];
    province.value = [];
    form.value.province = null;
    form.value.municipality = null;
    form.value.community = null;
    if(!form.value.department){
        return;
    } 
    if(!form.value.department.id){
        return;
    } 
    TerritoryTypeDataService.getProvince(form.value.department.id).then((data) => {
        province.value = data.data.data;
        if (!Object.is(props.formData.province)){
            form.value.province = (province.value)[findIndexDataById(province.value, props.formData.province)];
        }
    });
}
const filteredProvince = ref();
const searchProvince = (event) => {
    // let _items = [...province.value.keys()];
    setTimeout(() => {
        if (!event.query.trim().length) {
            filteredProvince.value = [...province.value];
        } else {
            filteredProvince.value = province.value.filter((item) => {
                return item.lugar.toLowerCase().startsWith(event.query.toLowerCase());
            });
        }
    }, 100);
}

const listMunicipality = () => {
    filteredMunicipality.value = [];
    municipality.value = [];
    form.value.municipality = null;
    form.value.community = null;
    if(!form.value.province){
        return;
    }
    if(!form.value.province.id){
        return;
    }
    TerritoryTypeDataService.getMunicipality(form.value.province.id).then((data) => {
        municipality.value = data.data.data;
        if (!Object.is(props.formData.municipality)){
            form.value.municipality = (municipality.value)[findIndexDataById(municipality.value, props.formData.municipality)];
        }
    });
}
const filteredMunicipality = ref();
const searchMunicipality = (event) => {
    // let _items = [...municipality.value.keys()];
    setTimeout(() => {
        if (!event.query.trim().length) {
            filteredMunicipality.value = [...municipality.value];
        } else {
            filteredMunicipality.value = municipality.value.filter((item) => {
                return item.lugar.toLowerCase().startsWith(event.query.toLowerCase());
            });
        }
    }, 100);
}

const listCommunity = () => {
    filteredCommunity.value = [];
    community.value = [];
    form.value.community = null;
    if(!form.value.municipality){
        return;
    }
    if(!form.value.municipality.id){
        return;
    }
    TerritoryTypeDataService.getCommunity(form.value.municipality.id).then((data) => {
        community.value = data.data.data;
        if (!Object.is(props.formData.community)){
            form.value.community = (community.value)[findIndexDataById(community.value, props.formData.community)];
        }
    });
}
const filteredCommunity = ref();
const searchCommunity = (event) => {
    // let _items = [...community.value.keys()];
    setTimeout(() => {
        if (!event.query.trim().length) {
            filteredCommunity.value = [...community.value];
        } else {
            filteredCommunity.value = community.value.filter((item) => {
                return item.lugar.toLowerCase().startsWith(event.query.toLowerCase());
            });
        }
    }, 100);
}

const submitted = ref(false);
const emit = defineEmits([]);

const nextPage = (() => {
    if(!form.value.complement){
        form.value.complement = '';
    }
    if(!form.value.paterno){
        form.value.paterno = '';
    }
    submitted.value = true;
    if ( validateForm() ) {
        console.log('formulario ',form.value)
        //validando en el segip antes de enviar datos
        let birthdate = null
        try{
            let date_array = form.value.birthdate.split("/")
            birthdate = new Date(date_array[2],parseInt(date_array[1])-1,date_array[0])
        }
        catch(err){
            birthdate = form.value.birthdate
        }
        // console.log(form.value.birthdate.search("/"))
        // let date_array = form.value.birthdate.split("/")
        form.value.complement = form.value.complement.toUpperCase()
        form.value.firstLastName = form.value.firstLastName.toUpperCase()
        form.value.secondLastName = form.value.secondLastName.toUpperCase()
        form.value.name = form.value.name.toUpperCase()

        form.value.complement = form.value.complement.trim()
        form.value.firstLastName = form.value.firstLastName.trim()
        form.value.secondLastName = form.value.secondLastName.trim()
        form.value.name = form.value.name.trim()

        let payload = {
            carnetIdentidad: form.value.identityCard+"",
            complemento: form.value.complement,
            paterno: form.value.firstLastName ,
            materno: form.value.secondLastName,
            nombre: form.value.name,
            fechaNacimiento: birthdate, 
            cedulaTipoId: parseInt(form.value.identityCardType) 
        }
        console.log('payload', payload)
        PersonDataService.checkSegip(payload)
        .then((response)=>{
            console.log('ok',response)
            if(response.data.statusCode === 201)
            {
                emit('nextPage', {formData: form.value, pageIndex: 0});
            }
            else
            {
                toast.add({ severity: 'info', summary: 'Info', detail: 'No se pudo contrastar la información con Segip, verifique los datos ingresados sean correctos ', life: 3050 });
            }
        })
        .catch((error)=>{
            console.log('error', error.response)
                toast.add({ severity: 'info', summary: 'Info', detail: 'No se pudo contrastar la información con Segip, verifique los datos ingresados sean correctos ', life: 3050 });
        })
        //
    }
});

const formatFecha = (value, type) => {

    console.log('formateando feche',value)
    let va = new Date(value);
    if(type === 1) {

        let array_date = value.split('-') 
        console.log('array',array_date)
        // return new Date(array_date[0],array_date[1]-1,array_date[2])
        return `${array_date[2]}/${array_date[1]}/${array_date[0]}`
        // return va.toLocaleDateString('es-BO', {
        //     day: '2-digit',
        //     month: '2-digit',
        //     year: 'numeric' 
        // });
    } else {
        var getYear = va.toLocaleString('es-BO', { year: "numeric" });
        var getMonth = va.toLocaleString('es-BO', { month: "2-digit" });
        var getDay = va.toLocaleString('es-BO', { day: "2-digit" });
        return getYear + "-" + getMonth + "-" + getDay;
    }
    
}


const validateForm = (() => {
    if (!form.value.identityCard)
        validationErrors.value['identityCard'] = true;
    else
        delete validationErrors.value['identityCard'];
    
    if (!form.value.identityCardType || form.value.identityCardType === null || form.value.identityCardType === 'null' || form.value.identityCardType === '0')
        validationErrors.value['identityCardType'] = true;
    else
        delete validationErrors.value['identityCardType'];

    // if (!form.value.issued)
    //     validationErrors.value['issued'] = true;
    // else
    //     delete validationErrors.value['issued'];

    if (!form.value.birthdate)
        validationErrors.value['birthdate'] = true;
    else
        delete validationErrors.value['birthdate'];

    if (!form.value.name)
        validationErrors.value['name'] = true;
    else
        delete validationErrors.value['name'];

    // if (!form.value.secondLastName)
    //     validationErrors.value['secondLastName'] = true;
    // else
    //     delete validationErrors.value['secondLastName'];

    if (!form.value.country)
        validationErrors.value['country'] = true;
    else
        delete validationErrors.value['country'];
    
    if (!form.value.department && Number(form.value.country?.id) === 1)
        validationErrors.value['department'] = true;
    else
        delete validationErrors.value['department'];

    if (!form.value.province && Number(form.value.country?.id) === 1)
        validationErrors.value['province'] = true;
    else
        delete validationErrors.value['province'];

    if (!form.value.municipality && Number(form.value.country?.id) === 1)
        validationErrors.value['municipality'] = true;
    else
        delete validationErrors.value['municipality'];

    if (!form.value.community && Number(form.value.country?.id) === 1)
        validationErrors.value['community'] = true;
    else
        delete validationErrors.value['community'];

    // if (!form.value.location)
    //     validationErrors.value['location'] = true;
    // else
    //     delete validationErrors.value['location'];

    return !Object.keys(validationErrors.value).length;
});

const findIndexDataById = (data, id) => {
    let index = -1;
    for (let i = 0; i < data.length; i++) {
        if (data[i].id === id) {
            index = i;
            break;
        }
    }
    return index;
};

</script>
