import http from "@/http-common";
class PersonDataService {
    getAll(){
      return http.get("/person/itts");
    }

    getByIdentityCard(data) {
      return http.post(`/persona/busqueda`,data);
    }
  
    // get(id) {
    //   return http.get(`/institucion-educativa/${id}`);
    // }
  
    create(data) {
      return http.post("/persona", data);
    }

    checkSegip(payload)
    { 
      return http.post('/persona/segip',payload)
    }
    // getInfo(id){

    //   return http.get(`/institucion-educativa/itt/${id}`);
    // }

    // getCareers(id){
    //   return http.get(`/carrera-autorizada/ie/${id}`)
    // }

    update(id, data){
      return http.put(`/persona`, data);
    }
  
  //   delete(id: any): Promise<any> {
  //     return http.delete(`/users/${id}`);
  //   }
  
  //   deleteAll(): Promise<any> {
  //     return http.delete(`/users`);
  //   }
  
  //   findByTitle(title: string): Promise<any> {
  //     return http.get(`/users?title=${title}`);
  //   }
  }
  
  export default new PersonDataService();