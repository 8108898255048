export default function authHeader() {
  // const token = localStorage.getItem("access_token");
  const user = JSON.parse(localStorage.getItem('user'))
  // console.log('auth header --------------> ',user?.token)
  if (user) {
    return  "Bearer " + user?.token ;
  } else {
    return 'NO TOKEN';
  }
}
