<template>
     <Toast position="bottom-right" />
    <div class="grid">
        <div class="col-12">
            <Card>
                <template #title>
                    <div class="grid">
                        <div class="col flex flex-column">
                           <span class=""> {{ career.carrera }} </span> <span class="text-xs text-info"> {{ career.institucion_educativa }}</span>
                        </div>
                        <div class="col  ">
                            <div class="flex  justify-content-end w-full">
                    
                                <SplitButton :label="regimen_grado_tipo_selected?.label" :icon="regimen_grado_tipo_selected?.icon"   :model="regimen_grado_tipos" severity="custom"
                                
                                />
                                <div class="pr-2"></div>
                                <SplitButton :label="`${current_period?.periodo === 'Anual'?'':`${current_period?.periodo} -` } ${current_period?.gestion}`" icon="pi pi-clock" severity="info"/>
                                <!-- <div class="pr-2"></div>
                                <SplitButton :label="gestion_selected.label" :icon="gestion_selected.icon"   :model="gestiones"  v-if="current_period"  severity="info" /> -->
                                <div class="pl-1 pr-1">
                                    
                                    <Button icon="bx bxs-pencil" severity="secondary" label="" @click="editAssingTeachers()" />
    
                                </div>
                                
                                <Button icon="pi pi-arrow-left" severity="secondary" label="" @click="returnShow()" />
                            </div>
                        </div>
                    </div>
                    
                </template>
               
                <template #content >
                    <div class="grid">
                        <div class="col ">
                            <div class="flex flex-column w-full">
                                <span class="text-base"> <strong> Area de Formación:</strong> {{ career.area }} </span>
                                <span class="text-base"> <strong> Regimen de Estudio:</strong> {{ instituto_plan_estudio_carrera?.planEstudioCarrera?.intervaloGestionTipo?.intervaloGestion }}</span>
                                <span class="text-base"> <strong> Tiempo de Estudio:</strong> {{ instituto_plan_estudio_carrera?.planEstudioCarrera?.tiempoEstudio }}</span>
                                <span class="text-base"> <strong> Carga horaria:</strong> {{ instituto_plan_estudio_carrera?.planEstudioCarrera?.cargaHoraria }}</span>
                            </div>
                        </div>
                        <div class="col">
                            <div class="flex flex-column w-full">
                                <span class="text-base"> <strong> Resolucion Plan de Estudio:</strong> <span class="text-base font-bold text-blue-500">{{ instituto_plan_estudio_carrera?.planEstudioCarrera?.planEstudioResolucion?.numeroResolucion }}</span> </span>
                                <span class="text-base"> <strong> Total Estudiante Carrera:</strong> {{ total_estudiantes }} </span>
                                <span class="text-base"> <strong> Total Materias:</strong> {{total_asignaturas}}</span>
                            </div>
                        </div>
                        <div class="col">
                            <div class="flex flex-column w-full">
                                <span class="text-base"> <strong> Turnos:</strong> 
                                    <span class="pl-2" v-for="(item, turno_index) in turnos" :key="turno_index">
                                        {{ item.turno }}
                                    </span> 
                                </span>
                                <span class="text-base" v-for="(item, turno_index) in turnos" :key="turno_index">
                                    <strong> Total Paralelos {{ item.turno }}:</strong> {{ item.total_pararelos }}
                                </span>
                                
                            </div>
                        </div>
                    </div>
                    <div class="flex justify-content-center w-full">
                        <Dropdown v-model="vista" :options="vista_tipo" optionLabel="name" placeholder="Seleccionar Vista" class="w-full md:w-15rem" />
                    </div>
              
                    <Divider></Divider>
                    <div class="grid" v-if="vista.id === 2">
                        <div class="col-6" v-for="(asignatura,index) in subjects" :key="index">
                            <Panel :header="`  ${asignatura.planEstudioAsignatura.asignaturaTipo.asignatura}  `"  toggleable
                              :pt="{
                                header: { class: 'bg-secondary' },
                                title: { class: 'text-white' },
                                toggler: { class: 'text-white hover:bg-primary-reverse' },
                                }"
                                collapsed 
                            >
                               <strong>{{ asignatura.planEstudioAsignatura.asignaturaTipo.abreviacion }}</strong>  Horas: {{ asignatura.planEstudioAsignatura.horas }}
                            
                               <div class="card border border-1   text-dark mb-3  bg-light rounded-top" v-for="(aula,index2) in asignatura.aulas " :key="index2" >
                                <div class="card-header  bg-keyrus-dark   "> 
                                    <div class="flex justify-content-between">
                                        <div class="flex flex-column ">
                                            <div class="flex justify-content-between">
                                                <span>Docente: {{ getTeacherName(aula.aulasDocentes) }} </span> 
                                                <span class="pl-2">Cupo: {{ aula.cupo }} </span>
                                            </div>
                                            <span class="font-bold " style="color: #1c353e;" > Paralelo: {{ aula?.paraleloTipo?.paralelo}} {{ aula?.turnoTipo?.turno }}</span>
                                        </div>
                                        <div class="flex align-items-end ">
                                            <div class="justify-content-end p-overlay-badge">
                                                <Badge :value="aula.institutoEstudianteInscripcions.length" severity="primary" v-if="aula.institutoEstudianteInscripcions.length.toString() !== '0'"></Badge>
                                                <Button type="button" icon="bx bxs-user-detail" @click="checkStudents(aula.id)"  v-tooltip.bottom="'Ver Nota Estudiantes'"  :severity="aula.institutoEstudianteInscripcions.length.toString() === '0' ? 'secondary' : '' " />

                                            </div>
                                        </div>

                                    </div>
                                    <!-- <div class="grid">
                                        <div class="col-10">
                                            
                                            
                                            
                                        </div>
                                        <div class="col-2 flex  justify-content-end p-overlay-badge">
                                            
                                        </div>
                                    </div> -->
                                   
                                </div>
                                <table class="table">
                                    <thead>
                                        <th>Aula</th>
                                        <th>Dia</th>
                                        <th>Hora Inicio</th>
                                        <th>Hora Fin</th>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(dia,index3) in aula.aulasDetalles " :key="index3">
                                            <td>{{ dia.numeroAula }} </td>
                                            <td>{{ dia.diaTipo.dia }}</td>
                                            <td>{{ dia.horaInicio }}</td>
                                            <td>{{ dia.horaFin }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                               </div>
                               <!-- {{ JSON.stringify(asignatura) }} -->
                            </Panel>


                        </div>
                    </div>

                    <div class="grid" v-if="vista.id === 1">
                        <div class="col-6" v-for="(paralelo,index) in paralelos" :key="index">
                            <Panel :header="``" toggleable
                                :pt="{
                                    header: { class: 'bg-secondary' },
                                    title: { class: 'text-white' },
                                    toggler: { class: 'text-white hover:bg-primary-reverse' },
                                    }"
                                    collapsed 
                                >
                                <template #header>
                                    <div class="flex align-items-center ">
                                        <span class="text-white font-bold pr-4"> PARALELO {{ paralelo.paralelo ?? ''}}</span>
                                        <span class="font-semibold" style="color: #53afd3;" > TURNO {{ paralelo.turno ?? '' }}</span>
                                    </div>
                                </template>

                                <template #icons>
                                    <button class="p-panel-header-icon p-link mr-2" >
                                        <span class="bx bxs-calendar text-green-300"></span>
                                    </button>
                                    <div class="p-panel-header-icon " style="border-radius: 0px; width: 35px;">
                                        <i class="bx bxs-user-detail text-white " ></i>
                                        <Badge :value="paralelo?.total_estudiantes" severity="success" style="font-size: 8px; margin-bottom: 1px !important;" ></Badge>
                                    </div>
                                </template>

                                <table class="table w-full">
                                    <thead>
                                        <th>Codigo</th>
                                        <th>Materia</th>
                                        <th>Docente</th>
                                        <th>Estudiantes</th>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(aula, aula_index) in paralelo.list" :key="aula_index">
                                            <td class="text-sm" >{{ aula.abreviacion }}</td>
                                            <td class="text-sm">{{ aula.asignatura }}</td>
                                            <td class="text-sm"> {{ `${aula.nombre ?? ''} ${aula.paterno ?? ''} ${aula.materno ?? '' }` }}</td>
                                            <td class="text-sm pl-0 pr-0 p-overlay-badge">
                                                <div class=" flex justify-content-end ">
                                                    <Badge :value="aula.total_estudiantes" severity="success" v-if="aula.total_estudiantes !== '0'"></Badge>
                                                    <Button type="button" icon="bx bxs-user-detail" @click="checkStudents(aula.aula_id)"  class="" v-tooltip.bottom="'Ver Estudiantes'"  :severity="aula.total_estudiantes === '0' ? 'secondary' : '' " />
                                                </div>

                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            
                            </Panel>
                        </div>
                    </div>
                </template>

                <!-- <template #footer>
                    <Button icon="pi pi-check" label="Guardar" @click="assignTeachers()" />
                    <Button icon="pi pi-times" label="Cancelar" severity="secondary" style="margin-left: 0.5em" />
                </template> -->
            </Card>
        </div>  
    </div>
    
</template>
<script>

import CareerDataServie from '@/services/CareerDataServie';
import AcademicOffertDataService from '@/services/AcademicOffertDataService';

export default {
    data: () => ({
        career: {},
        resolution_study: {},
        current_period: null,
        subjects:[],
        teachers: [],
        is_edit:false, 
        gestion_selected: {id:2023,gestion:'2023'},
        gestiones: [],
        paralelos: [],
        regimen_grado_tipo_selected: null,
        instituto_plan_estudio_carrera: {},
        regimen_grado_tipos: [],
        total_estudiantes: 0,
        total_asignaturas: 0,
        turnos: [],
        items: [
        {
            label: '2023',
            icon: 'bx bxs-calendar',
            command: () => {
                this.toast.add({ severity: 'success', summary: 'Updated', detail: 'Se Cambio de agestion 2023', life: 3000 });
            }
        },
        ],
        vista_tipo: [{
            id:1,
            name: 'VER POR PARALELO'
        },{
            id:2,
            name: 'VER POR MATERIA'
        }],
        vista: { id:1, name: 'VER POR PARALELO' }
    }),
    async mounted() {
        await Promise.all(this.getCurrentPeriod(),this.getCareer(),this.getRegimenEstudio())
        
        // console.log('id',this.$route.params.plan_estudio_carrera_id)
        
        
    },
    methods: {
        async getRegimenEstudio(){
            let response = await AcademicOffertDataService.getRegimenStudio(this.$route.params.plan_estudio_carrera_id) //instituto_plan_estudio_carrera_id
            console.log('regimen ===>',response.data)
            this.gestiones = []
            this.regimen_grado_tipos = []
            this.instituto_plan_estudio_carrera = response.data.instituto_plan_estudio_carrera
            response.data.regimen_grado_tipos.forEach(regimen => {
                this.regimen_grado_tipos.push({
                    label: regimen.regimenGrado,
                    icon: 'pi pi-calendar',
                    command: () =>{
                        this.setRegimenGradoTipoSelected(regimen)
                    }
                })
            });

            response.data.gestion_tipos.forEach(gestion => {
                this.gestiones.push({
                    label: gestion.gestion,
                    icon: 'pi pi-clock',
                    command: () =>{
                        this.setGestionSelected(gestion)
                    }
                })
            });
            // await new Promise(r => setTimeout(r, 2000));
            this.setGestionSelected(response.data.gestion_tipos[0])
            this.setRegimenGradoTipoSelected(response.data.regimen_grado_tipos[0]) 
            console.log(' ===============>',this.regimen_grado_tipo_selected)
            this.getParalelos()
        },
        setGestionSelected(item)
        {
            this.gestion_selected = { 
                id: item.id,
                label: item.gestion,
                icon: 'pi pi-clock'
            } 

            this.getParalelos()
            this.getSubjects() 
            // if(this.vista.id === 1)
            // {
            //     this.getParalelos()
            // }

        },
        setRegimenGradoTipoSelected(item)
        {
            this.regimen_grado_tipo_selected = { 
                id: item.id,
                label: item.regimenGrado,
                icon: 'pi pi-calendar'
            } 

            this.getParalelos()
            this.getSubjects() 
            // if(this.vista.id === 1)
            // {
            //     this.getParalelos()
            // }

        },
        async getParalelos()
        {
            console.log('regimen',this.regimen_grado_tipo_selected)
            console.log('current period',this.current_period)
            console.log('gestion',this.gestion_selected)
            if(this.regimen_grado_tipo_selected && this.gestion_selected && this.current_period)
            {
                let response = await AcademicOffertDataService.getParalelos(this.$route.params.plan_estudio_carrera_id,this.regimen_grado_tipo_selected?.id,this.current_period?.gestion,this.current_period.periodo_tipo_id)
                console.log('paralelos', response.data)
                this.paralelos = response.data.paralelos
                this.total_estudiantes = response.data.total_estudiantes
                this.turnos = response.data.total_turnos
                this.total_asignaturas = response.data.total_asignaturas
            }

        },
        getCareer() {
            CareerDataServie.get(this.$route.params.career_id)
                .then((response) => {
                    this.career = response.data.data
                    console.log('carrera',this.career)
                    // this.setIntervals()
                    this.getCurrentPeriod()
                })
        },
        getCurrentPeriod() {
            CareerDataServie.getCurrentPeriod(this.$route.params.career_id)
                .then((response) => {
                    console.log("asdsadsa");
                    console.log(response);
                    this.current_period = response.data.data
                    console.log(response.data.data)
                    this.getSubjects()
                    this.getTeachers()
                })
        },

        getSubjects()
        {
            if(this.current_period)
            {
                console.log(this.$route.params.plan_estudio_carrera_id,this.current_period.gestion, this.current_period.periodo_tipo_id)
                AcademicOffertDataService.getShowSubjectsRegimenGrado(this.$route.params.plan_estudio_carrera_id,this.current_period.gestion, this.current_period.periodo_tipo_id,this.regimen_grado_tipo_selected?.id)
                    .then((response)=>{
                        this.subjects = response.data.data
                        // console.log('subjects',this.subjects)
                    })
            }
        },

        getTeachers()
        {
            // AcademicOffertDataService.getTeachers(this.career.ie_id,this.current_period.gestion, this.current_period.periodo_tipo_id)
            AcademicOffertDataService.getTeachers(this.career.ie_id,this.current_period.gestion, 53)
                .then((response)=>{
                    // console.log('teachers ',response.data)
                    response.data.data.forEach(docente => {
                        this.teachers.push({id: docente.id , nombre: `${docente.paterno} ${docente.materno} ${docente.nombre}` })
                    });
                    // this.teachers = response.data.data
                    console.log(this.teachers)

                })

        },
        assignTeachers()
        {
            let payload = []
            
            this.subjects.forEach(subject => {
                // let object_payload  = { 
                //         id: subject.planEstudioAsignatura.asignaturaTipo.id  
                //     }
                subject.aulas.forEach(aula => {
                    payload.push({
                        aula_id: aula.id,
                        maestro_inscripcion_id: aula.teacher.id,
                        fecha_inicio: aula.fecha_inicio,
                        fecha_fin: aula.fecha_fin

                    })
                });
            });
            
            AcademicOffertDataService.assingTeachers(payload)
                .then((response)=>{
                    console.log(response.data)
                })
        },
        getTeacherName(list)
        {
            let name = ""
            list.forEach(item => {
                if(item.bajaTipoId === 0)
                {
                    name =` ${item.maestroInscripcion.persona.nombre} ${ item.maestroInscripcion.persona.paterno} ${item.maestroInscripcion.persona.materno}` 
                    // console.log(item)
                }
            });
            return name
        },
        checkStudents(aula_id)
        {
            this.$router.push(`/classroom/${aula_id}/${this.$route.params.career_id}`)
        },
        editAssingTeachers()
        {
            this.$router.push(`/academic_offert_show/${this.$route.params.plan_estudio_carrera_id}/${this.$route.params.career_id}`)
        },

        returnShow()
        {
            this.$router.push(`/career/${this.$route.params.career_id}`)
        }

    }


}
</script>
<style>
    .p-card .p-card-content{
        background: white;
        margin-top: 0px;
        padding: 0.25rem 0 !important;
    }
    .p-component .p-button-custom button{
        background:  #2a4ba9 !important;
        border-color: #448de7 !important;
    }
   /* .p-splitbutton .p-splitbutton-defaultbutton {
        background: red !important;
    }*/
</style>