import { createStore } from 'vuex'
import { auth } from "./auth.module"
export default createStore({
  state: {
    breadcrumbs: [],
    message: "",
    severity: "success",
    title:"",
    study_plan: null,
    institution_id: localStorage.getItem('institution_id') ?? null,
    rows_number: window.screen.height > 1080?15:10
  },
  getters: {
    getBrebrumbs (state)
    {
      return state.breadcrumbs
    },
  },
  mutations: {
    setBreadcrumbs(state,breadcrumb_list)
    {
      state.breadcrumbs = breadcrumb_list
    },
    setMessage(state,message)
    {
      state.message = message.message
      state.severity = message.severity
      state.title = message.title 
      console.log(message)
      //show message here
    },
    setStudyPlan(state,study_plan){
      state.study_plan = study_plan
    },
    setInstitutionId(state, institution_id){
      localStorage.setItem('institution_id',institution_id)
      state.institution_id = institution_id
    },
    destroyStudyPlan(state)
    {
      state.study_plan =null
    }
  },
  actions: {
    loadBreadcrumbs(context,breadcrumb_list){
      context.commit('setBreadcrumbs',breadcrumb_list)
    },
    showMessage(context,message){
      context.commit('setMessage',message)
    },
    saveStudyPlan(context,study_plan)
    {
      context.commit('setStudyPlan',study_plan)
    },
    saveInstitutionId(context, institution_id)
    {
      context.commit('setInstitutionId', institution_id)
    },
    deleteStudyPlan(context)
    { 
      context.commit('destroyStudyPlan')
    }

  },
  modules: {
    auth,
  }
})
