import http from "@/http-common";
class OperativeCareerDS {
    getOperativos(carrera_autorizada_id, gestion_id, periodo_tipo_id)
    {
      return http.get(`/operativo-carrera-autorizada/operativos/${carrera_autorizada_id}/${gestion_id}/${periodo_tipo_id}`);
    }

    getOperativeActive(carrera_autorizada_id)
    {
        return http.get(`/operativo-carrera-autorizada/operativo_activo/${carrera_autorizada_id}`)
    }
    // getInstitutionAreas(id){
    //   return http.get(`/jurisdiccion-geografica/2001/codigo/${id}`);
    // }

    // get(id) {
    //   return http.get(`/jurisdiccion-geografica/2001/codigo/${id}`);
    // }
  
  //   create(data: any): Promise<any> {
  //     return http.post("/users", data);
  //   }
  
  //   update(id: any, data: any): Promise<any> {
  //     return http.put(`/users/${id}`, data);
  //   }
  
  //   delete(id: any): Promise<any> {
  //     return http.delete(`/users/${id}`);
  //   }
  
  //   deleteAll(): Promise<any> {
  //     return http.delete(`/users`);
  //   }
  
  //   findByTitle(title: string): Promise<any> {
  //     return http.get(`/users?title=${title}`);
  //   }
  }
  
  export default new OperativeCareerDS();