import http from "@/http-common";
class RegistrationStudentService {
  getAllBySieYearPeriodCareer(sie,year,per,car,plan){
    console.log(`/inscripcion/matriculados/${year}/${per}/${car}/${sie}/${plan}`);
    return http.get(`/inscripcion/matriculados/${year}/${per}/${car}/${sie}/${plan}`);
  }
  getMatriculadosPlanEstudioCarrera(instituto_plan_estudio_carrera_id, gestion_tipo_id, periodo_tipo_id, institucion_educativa_id){
    return http.get(`/inscripcion/matriculados-instituto_plan_estudio_carrera/${instituto_plan_estudio_carrera_id}/${gestion_tipo_id}/${periodo_tipo_id}/${institucion_educativa_id}`)
  }
  // getAll(){
  //   return http.get("/maestro-inscripcion");
  // }

    // getInstitutionAreas(id){
    //   return http.get(`/jurisdiccion-geografica/2001/codigo/${id}`);
    // }

    // get(id) {
    //   return http.get(`/jurisdiccion-geografica/2001/codigo/${id}`);
    // }
  
  create(data) {
    return http.post("/inscripcion/matricula", data);
  }

  importMatriculados (payload) {
    return http.post('/inscripcion/import-matriculados', payload)
  }

  periodosInsitutoPlanEstudioCarrera(instituto_plan_estudio_carrera_id)
  {
    return http.get(`/instituto-plan-estudio-carrera/periodos/${instituto_plan_estudio_carrera_id}`)
  }
  // update(id, data) {
  //   return http.put(`/maestro-inscripcion/`, data);
  // }

  // updateStatus(data) {
  //   return http.put(`/maestro-inscripcion/changeStatusById`, data);
  // }

  // delete(id) {
  //   return http.delete(`/maestro-inscripcion/${id}`);
  // }
  
  //   deleteAll(): Promise<any> {
  //     return http.delete(`/users`);
  //   }
  
  //   findByTitle(title: string): Promise<any> {
  //     return http.get(`/users?title=${title}`);
  //   }
  }
  
  export default new RegistrationStudentService();