import http from "@/http-common";
class TeacherNoteDataService {

    getSubjects(persona_id) {
        return http.get(`/aula-docente/carreras-aulas-persona/${persona_id}`);
    }

    getStudents(aula_id){
        return http.get(`/inscripcion/byAulaId/${aula_id}`);
    }

    getIttList(){
        return http.get('/nota-tipo/lista-itt');
    }

    createNotes(data){
        return http.post('/instituto-estudiante-inscripcion-docente-calificacion',data)
    }

    saveNotes(data){
        return http.post('/instituto-estudiante-inscripcion-docente-calificacion/save-notes',data)
    }

    getStudentNote(instituto_estudiante_inscripcion_id)
    {
        return http.get(`/instituto-estudiante-inscripcion-docente-calificacion/inscripcion/${instituto_estudiante_inscripcion_id}`)
    }


    getStudentNoteModalidad(instituto_estudiante_inscripcion_id,modalidad_id)
    {
        return http.get(`/instituto-estudiante-inscripcion-docente-calificacion/inscripcion-modalidad/${instituto_estudiante_inscripcion_id}/${modalidad_id}`)
    }

    getStudentsRecovery(aula_id,periodo_tipo_id)
    {
        return http.get(`/inscripcion/reprobados-recuperatorio/ByAulaId/${aula_id}/${periodo_tipo_id}`)
    }

    getStudentNotes(aula_id, carrera_autorizada_id,periodo_tipo_id)
    {
        return http.get(`/instituto-estudiante-inscripcion-docente-calificacion/record-signature/${aula_id}/${carrera_autorizada_id}/${periodo_tipo_id}`)
    }

    getStudentNoteRecord(payload)
    {
        return http.post(`/instituto-estudiante-inscripcion-docente-calificacion/student-notes`,payload)
    }

    saveNotesHomologation(payload)
    {
        return http.post(`/instituto-estudiante-inscripcion-docente-calificacion/save-notes-homologation`,payload)
    }

    saveNotesHomologationGestion(payload)
    {
        return http.post(`/instituto-estudiante-inscripcion-docente-calificacion/save-notes-homologation-gestion`,payload)
    }
    //la cris unifico en un solo webservice 
    // averageCalculation(aula_id, periodo_id)
    // {
    //     return http.get(`/instituto-estudiante-inscripcion-docente-calificacion/promedios/aula-periodo/${aula_id}/${periodo_id}`)
    // }

    // averageState(aula_id)
    // {
    //     return http.get(`instituto-estudiante-inscripcion-docente-calificacion/estados-finales/aula/${aula_id}`)
    // }


    // getInstitutionAreas(id){
    //   return http.get(`/jurisdiccion-geografica/2001/codigo/${id}`);
    // }

    // get(id) {
    //   return http.get(`/jurisdiccion-geografica/2001/codigo/${id}`);
    // }
    //new interface 

    getGestionsByTeacher(persona_id, institucion_educativa_sucursal_id)
    {
       return http.get(`/aula-docente/gestions_by_teacher/${persona_id}/${institucion_educativa_sucursal_id}`)
    }

    getResolutionsByTeahcer(payload)
    {
        return http.post(`/aula-docente/resolutions_by_teacher/`,payload)
    }

    getSubjectsByTeahcer(payload)
    {
        return http.post(`/aula-docente/subjects_by_teacher/`,payload)
    }

    getNoteCentralizer(instituto_plan_estudio_carrera_id, paralelo_tipo_id, turno_tipo_id, gestion_tipo_id, periodo_tipo_id, regimen_grado_tipo_id )
    {
        return http.get(`/instituto-estudiante-inscripcion-docente-calificacion/note-centralizer/${instituto_plan_estudio_carrera_id}/${paralelo_tipo_id}/${turno_tipo_id}/${gestion_tipo_id}/${periodo_tipo_id}/${regimen_grado_tipo_id}`)
    }
    

}

export default new TeacherNoteDataService();