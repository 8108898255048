import http from "@/http-common";
class SubjectTypeDataService {
  getAll(){
    return http.get("/asignatura-tipo");
  }

    // getInstitutionAreas(id){
    //   return http.get(`/jurisdiccion-geografica/2001/codigo/${id}`);
    // }

    // get(id) {
    //   return http.get(`/jurisdiccion-geografica/2001/codigo/${id}`);
    // }
  
  create(data) {
    return http.post("/asignatura-tipo", data);
  }

  update(id, data) {
    return http.put(`/asignatura-tipo/`, data);
  }

  delete(id) {
    return http.delete(`/asignatura-tipo/${id}`).catch((error)=>{
      return error.response;
    });
  }
  
  //   deleteAll(): Promise<any> {
  //     return http.delete(`/users`);
  //   }
  
  //   findByTitle(title: string): Promise<any> {
  //     return http.get(`/users?title=${title}`);
  //   }
  }
  
  export default new SubjectTypeDataService();