<template>
     <Toast position="bottom-right" />
    <div class="grid">
        <div class="col-12">
            <Card>
                <template #title>
                    <div class="flex justify-content-between w-full">
                        <div class="flex flex-column">
                            <span>{{classroom?.ofertaCurricular?.planEstudioAsignatura?.asignaturaTipo?.asignatura }} ({{classroom?.ofertaCurricular?.planEstudioAsignatura?.asignaturaTipo?.abreviacion }})</span>
                            <span class="text-sm text-500"> {{career.institucion_educativa }} </span>
                            
                           
                        </div>
                        <div class="flex justify-content-end">
                            <div class="flex align-items-start">
                                <div class="btn-group pr-2" v-if="operativo" >        
                                    <button type="button" :class="`btn btn-primary dropdown-toggle `" data-bs-toggle="dropdown" aria-expanded="false"
                                        v-tooltip.lefts="{ value: `<h5 class='text-white'>Gestion: ${operativo.gestion} </h5><h5 class='text-white'>Evento:${operativo.evento} </h5> `, escape: true, class: 'custom-error' }" 
                                    >
                                        <div class="kdropdown-button">
                                            <div class="kicon" >
                                                <i class="duotone icon-pencil" v-if="operativo.id === operativo_edit.id"></i>
                                                <i class="duotone icon-eye-open" v-else></i>
                                            </div>
                                            <div class="kcontent">
                                                <strong style="font-size: 10px;"> {{ operativo.evento }} - {{ operativo.gestion }} </strong>
                                                <strong v-if="operativo.modalidad_id>0"> {{ operativo.modalidad }} </strong> 
                                                <strong v-else> Periodo: {{ operativo.periodo }}</strong>
                                            </div>
                                        </div>                  
                                    </button>
                                    <ul class="dropdown-menu dropdown-menu-end dropdown-menu-lg-start">
                                        <li v-for="(periodo, index ) in periodos_ademicos" :key="index">
                                            <div class="dropdown-item kitem-list " @click="setOperativo(periodo)"> 
                                            
                                                <div class="kicon">
                                                    
                                                    <i class="duotone icon-pencil" v-if="periodo.id === operativo_edit.id"></i>
                                                    <i class="duotone icon-eye-open" v-else></i>
                                                </div>
                                                <div class="kcontent">
                                                    <span>{{ periodo.evento }} - {{ periodo.gestion }} </span>
                                                    <span v-if="periodo.modalidad_id>0"> {{ periodo.modalidad }}</span>
                                                    <span v-else>Periodo: {{ periodo.periodo }} </span>
                                                </div>
                                                
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <Button icon="pi pi-arrow-left" severity="secondary" label="" @click="$router.go(-1)" />
                            </div> 
                        </div>
                    </div>
                   
                    
                </template>
                <template #content>
                    <div class="grid">
                        <div class="col flex flex-column">
                            <div > <strong>CARRERA:</strong> <span >{{ career.carrera }}</span> </div>
                            <div > <strong>REGIMEN DE ESTUDIO:</strong> <span > {{ career.regimen_estudio  }}</span> </div>
                        </div>

                    </div>
                    <Panel header="Estudiantes">
                        <template #header>
                            <div class="flex justify-content-between w-full">
                                <div class="flex flex-column">
                                    <span :class="`text-normal font-semibold ${docente.bajaTipoId===0?'text-blue-500':'text-gray-500'}`" v-for="(docente, index_docente) in classroom?.aulasDocentes" :key="index_docente"> DOCENTE: {{ `${docente.maestroInscripcion.persona.nombre ?? ''} ${docente.maestroInscripcion.persona.paterno ?? ''} ${docente.maestroInscripcion.persona.materno ?? ''}` }}  <Tag severity="warning" value="Info" rounded v-if="docente.bajaTipoId === 3 "> deshabilitado </Tag>  </span>
                                    <span class="text-normal font-semibold text-blue-500" > PARALELO: {{  classroom?.paraleloTipo?.paralelo}}  <Tag severity="success" value="Info" rounded> {{ classroom?.turnoTipo?.turno }} </Tag>   </span> 
                                    <span class="text-green-400 font-semibold" > APROBADOS: {{ this.aprobados }} </span>
                                    <span class="text-red-400 font-semibold" > REPROBADOS: {{ this.reprobados }} </span>
                                    <span class="text-yellow-400 font-semibold" > NO SE PRESENTO: {{ this.no_asistidos }} </span>
                                </div>
                                <div class="flex align-items-center">
                                    <router-link  v-tooltip.left="{value: 'Registro de Notas'}" class="icon-button" :to="`/record_academic/${this.$route.params.aula_id}/${this.$route.params.career_id}/0`" target="_blank"    >
                                        <div class="kicon-button-danger" >
                                            <i class='bx bxs-file-pdf'></i>
                                        </div>
                                    </router-link>     
                                    <strong>TOTAL ESTUDIANTES : {{ students.length }}    </strong>

                                </div>
                            </div>
                        </template>
                        <table class="table">
                            <thead>
                                <th>N°</th>
                                <th>CI</th>
                                <th>Apellido Paterno</th>
                                <th>Apellido Materno</th>
                                <th>Nombres</th>
                                <th>Nota Teorica</th>
                                <th>Nota Practica</th>
                                <th>Total</th>
                            </thead>
                            <tbody>
                                
                                    <tr v-for="(student,index) in students" :key="index">
                                        <td>{{ index+1 }}</td>
                                        <td>{{ student.carnet_identidad }}</td>
                                        <td>{{ student.paterno }}</td>
                                        <td>{{ student.materno }}</td>
                                        <td>{{ student.nombre }}</td>
                                        <td>{{ student.teorica }}</td>
                                        <td>{{ student.practica  }}</td>
                                        <td :class="getColorStatus(student.suma)"><strong>{{ student.suma?Math.round(student.suma):''   }}</strong> </td>
                                    </tr>
                                
                            </tbody>
                        </table>
                    </Panel>

                </template>
              
            </Card>
        </div>
    </div>
</template>
<script>
import CareerDataServie from '@/services/CareerDataServie';
import ClassroomDataService from '@/services/ClassroomDataService';
import TeacherNoteDataService from '@/services/TeacherNoteDataService';
import NoteTypeDataService from '@/services/NoteTypeDataService';
export default{
    data:()=>({
        career:{},
        operativo: null,
        classroom:{},
        students:[],
        note_type:{},
        operativo_edit: null,
        aula: {},
        periodos_ademicos:[],
        items: [
            {
                label: '2023',
                icon: 'bx bxs-calendar',
                command: () => {
                    // toast.add({ severity: 'success', summary: 'Updated', detail: 'Se Cambio de agestion 2023', life: 3000 });
                }
            },
        ],
        reprobados: 0,
        no_asistidos: 0,
        aprobados:0

    }),
    mounted(){
        this.getCareer()
        this.getClassroom()
        // this.getClassroomEstudents()
        this.getAula()
        this.getNoteType()
        this.getPeriodosAcademicos()
        this.getCurrentPeriod()
    },
    methods:
    {
        getCareer() {
            CareerDataServie.get(this.$route.params.career_id)
                .then((response) => {
                    this.career = response.data.data
                    console.log("career", this.career);
                    // this.setIntervals()
                    this.getCurrentPeriod()
                })
        },
        getCurrentPeriod() {
            CareerDataServie.getCurrentPeriod(this.$route.params.career_id)
                .then((response) => {
                    this.operativo = response.data.data
                    this.operativo_edit = response.data.data
                    this.getStudents()
                    console.log('operativo',response.data.data)
                    
                })
        },
        getPeriodosAcademicos()
        {
            CareerDataServie.getPeriodosAcademicos(this.$route.params.career_id)
                .then((response)=>{
                    this.periodos_ademicos = response.data.data
                    console.log('periodos academicos',response.data)
        
                })
        },
        setOperativo(new_operativo)
        {
            this.operativo = new_operativo
            this.getStudents()
        },
        getPromedio(student){
            // let promedio = parseInt( (parseInt(student.teorica) + parseInt(student.practica))/2 )
            return parseInt(student.teorica) + parseInt(student.practica)
        },
        async getAula()
        {
            let response = await TeacherNoteDataService.getStudentNotes(this.$route.params.aula_id,this.$route.params.career_id,0)
            console.log(response.data)
            this.aula = response.data.aula
    
        },
        getColorStatus(nota){
            let color=  ''
          

            if( parseFloat(nota) === 0 )
            {
               color = 'text-yellow-400' 
            }else{

                if(nota>0 && nota<61)
                {
                    color = 'text-red-500'
                }else{
                    color = 'text-green-500'
                }

            }

            return color
        },
        async getStudents(){
            
            // this.aula = aula
            let response = await TeacherNoteDataService.getStudents(this.$route.params.aula_id)
            this.students = response.data.data
            console.log("estudiantes", this.students)

            // if(this.operativo.evento === 'Calificaciones')
            // {
                let i=0
                let a = 0
                let r = 0
              await Promise.all(  this.students.map(async student => {
                    
                    let result = await TeacherNoteDataService.getStudentNoteModalidad(student.instituto_estudiante_inscripcion_id,this.operativo.modalidad_id)
                    // console.log('result',result.data.data)
                    let practica = _.find(result.data.data,(objeto)=>{ return objeto.nota_tipo === 'PRÁCTICA' } )
                    // console.log('nota_practica',practica)
                    let teorica = _.find(result.data.data,(objeto)=>{ return objeto.nota_tipo === 'TEORÍA' } )
                    let suma = _.find(result.data.data,(objeto)=>{ return objeto.nota_tipo === 'SUMA' } )
                
                    if(teorica)
                    {
                        student.teorica = parseFloat(teorica.cuantitativa) 
                    }
                    if(practica)
                    {
                        student.practica = parseFloat(practica.cuantitativa) 
                    }
                    if(suma)
                    {
                        student.suma = parseFloat(suma.cuantitativa)
                        console.log('suma',student.suma)
                        if(parseFloat(suma.cuantitativa) === 0)
                        {
                            i++
                            console.log('asistido ', suma.cuantitativa)
                            console.log(i)
                        }else{

                            if(parseFloat(suma.cuantitativa) >= 1 && parseFloat(suma.cuantitativa) < 61)
                            {
                                r += 1
                            }
                            else{
                                a += 1
                            }
                        }

                     
                    }

                   
                    return student
                }))

                this.no_asistidos = i
                this.aprobados = a
                this.reprobados = r
                // this.students.forEach(student => {
                    
                //     student.promedio = parseInt( (parseInt(student.teorica) + parseInt(student.practica))/2 )
                //     // student.promedio = this.getPromedio(student)
                //     return student
                // });
            // }
            
            console.log(response.data.data)
                
        },
        getClassroom()
        {
            ClassroomDataService.get(this.$route.params.aula_id)
                .then((response)=>{
                    console.log('aula ',response.data)
                    this.classroom = response.data
                })
        },
        getClassroomEstudents()
        {
            ClassroomDataService.getInscribed(this.$route.params.aula_id)
                .then((response)=>{
                    console.log(response.data.data);
                    this.students = response.data.data
                    let n1 = 0
                    let n2 = 0
                    this.students.forEach(item => {
                        n1= this.randomNumber(5,30)
                        n2 = this.randomNumber(50,70)
                        item.nota1 = n1
                        item.nota2 = n2
                        item.nota_final = n1+n2
                        return item
                    });
                    console.log(this.students)
                } )
        },
        getNoteType()
        {
            ClassroomDataService.getNoteType(7)
                .then((response)=>{
                    this.note_type = response.data
                })
        },
        randomNumber(min, max) {
            return parseInt( Math.random() * (max - min) + min );
        }

    }
}
</script>