<template>
    <Toast position="bottom-right" />
    <div class="flex flex-row-reverse flex-wrap">
        <Button type="button" size="small" label="Adicionar Cursossss" icon="pi pi-plus" @click="createCareer()" />
    </div>

    <DataTable :value="careers" paginator :rows="5" :rowsPerPageOptions="[5, 10, 20, 50]" >
        <Column style="width: 10%"> 
            <!-- <template #body="{ data,index }"> -->
                <template #body="{data}">
                <!-- <button type="button" class="btn btn-primary"> </button> -->
                <div class="btn-group me-2" role="group" aria-label="First group"> 
                    <Button v-tooltip="'Ver Detalle'" icon="mdi mdi-eye" text raised rounded aria-label="Filter" @click="redirectCareerView(data.carrera_autorizada_id)" />
                </div>
               
    
            </template>
        </Column>
        <Column field="carrera" header="Carrera" style="width: 25%"></Column>
        <!-- <Column field="area" header="Area" style="width: 25%"></Column>        -->
        <Column field="numero_resolucion" header="Resolucion Ministerial" style="width: 25%"></Column>
        <Column field="fecha_resolucion" header="Fecha Resolucion" style="width: 25%"></Column>
        <Column field="nivel_academico" header="Nivel de Formacion" style="width: 25%"></Column>
        <Column field="carga_horaria" header="Carga horaria" style="width: 25%"></Column>
        <Column field="tiempo_estudio" header="Tiempo de Estudios" style="width: 25%"></Column>
        <Column field="regimen_estudio" header="Regimen de Estudio" style="width: 25%"></Column>
        <Column field="resuelve" header="Tipo de Tramite" style="width: 25%"></Column>
        <!-- <Column field="representative.name" header="Tipo de Tramite" style="width: 25%"></Column> -->
    </DataTable>

    <Dialog v-model:visible="dialog" modal :header="title" :style="{ width: '80vw' }">
        <div class="formgrid grid">

            <div class="field col-12 md:col-6">
                    <label>Area de Formación</label>
                    <Dropdown v-model="area" :options="areas" optionLabel="area" class=" p-inputtext-sm w-full" placeholder="Seleccionar Area de Formación" />
            </div>

            <div class="field col-12 md:col-6">
                    <label>Curso</label>
                    <Dropdown v-model="career_type"  filter :options="career_types" optionLabel="carrera" class=" p-inputtext-sm w-full " placeholder="Seleccionar Carrera" />
            </div>  
            
            <div class="field col-12 md:col-4">
                    <label>Tipo de Resolución</label>
                    <Dropdown v-model="resolution_type" :options="resolution_types" optionLabel="resolucionTipo" class=" p-inputtext-sm w-full" placeholder="Seleccionar Tipo" />
            </div>

            <div class="field col-12 md:col-4">
                    <label>Resolucion Ministerial</label>
                    <InputText type="text" class="p-inputtext-sm w-full" v-model="career.numero_resolucion" />
            </div>
            
            <div class="field col-12 md:col-4">
                    <label>Fecha de Resolución</label>
                    <Calendar class="p-inputtext-sm w-full" v-model="career.fecha_resolucion" dateFormat="dd/mm/yy"/>
                    
            </div>

            <div class="field col-12 md:col-4">
                    <label>Nivel de Formación </label>
                    <Dropdown v-model="academic_level" :options="academic_levels" @change="reloadTimes()" optionLabel="nivelAcademico" class=" p-inputtext-sm w-full" placeholder="Seleccionar Nivel de Formacion" />
            </div>  

            <div class="field col-12 md:col-4">
                    <label>Regimen de Estudio</label>
                    <Dropdown v-model="study_regimen" :options="study_regimens" @change="reloadTimes()" optionLabel="intervaloGestion" class=" p-inputtext-sm w-full" placeholder="Seleccionar Regimen" />
            </div>  

         
            <div class="field col-12 md:col-4">
                    <label>Tiempo Estudio</label>
                    <Dropdown v-model="study_time" :options="study_times"  optionLabel="id" class=" p-inputtext-sm w-full" placeholder="Seleccionar Tiempo" />
            </div>  

            <div class="field col-12 md:col-4">
                    <label>Carga Horaria</label>
                    <InputText type="text" class="p-inputtext-sm w-full" v-model="career.carga_horaria" />
            </div>
            <div class="field col-12 md:col-4">
                    <label>Descripción</label>
                    <InputText type="text" class="p-inputtext-sm w-full" v-model="career.descripcion" />
            </div> 

            <div class="field col-12 md:col-4">
                    <label>Observación</label>
                    <InputText type="text" class="p-inputtext-sm w-full" v-model="career.resuelve" />
            </div>  
            
        </div>
        <template #footer>
            <Button label="Cancelar" icon="pi pi-times" @click="dialog = false" text />
            <Button label="Guardar" icon="pi pi-check" @click="saveCareer()" autofocus />
        </template>
    </Dialog>
</template>
<script >
import InstitutionDataService from '@/services/InstitutionDataService'
import AreaDataService from '@/services/AreaDataService'
import CareerTypeDataService from '@/services/CareerTypeDataService'
import TrainingLevelDataService from '@/services/TrainingLevelDataService'
import StudyRegimenDataService from '@/services/StudyRegimenDataService'
import StudyTimeDataService from '@/services/StudyTimeDataService'
import ResolutionTypeDataService from '@/services/ResolutionTypeService'
import CareerDataService from '@/services/CareerDataServie'
import CurseDataService from '@/services/CurseDataService'
export default{
    name: 'CareerInsitution',
    props: {
        iu_id: Number,
        sucursal_id: Number,
    },
    data: ()=>({
        careers:[],
        dialog: false,
        career:{},
        career_types:[],
        career_type:{},
        areas: [],
        area:{},
        academic_levels:[],
        academic_level:{},
        study_regimens: [],
        study_regimen:{},
        study_times:[],
        study_time: null,
        resolution_types: [],
        resolution_type: {},
        institution:{},
    }),
    mounted(){
        console.log("obteniendo Carreras")
        this.getInstitution()
        this.retrieveCareers()
        this.retrieveAreas()
        this.retrieveCareerTypes()
        this.retrieveTrainingLevel()
        // console.log(this.academic_level);
        this.retrieveStudyRegimens()
        this.retrieveResolutionTypes()
        
    },
    methods:{
        createCareer()
        {
            this.dialog = true
            this.career = {}
        },
        getInstitution()
        {
            InstitutionDataService.getInfo(this.$route.params.id)
            .then((response)=>{
                this.institution = response.data
                // console.log(response.data)
            })
        },
        retrieveCareers()
        {
            CurseDataService.getInstitutionCourses(this.$route.params.id) 
                .then((response) => {
                    // console.log(response.data.data);
                    this.careers = response.data.data;
                })
                .catch((e) => {
                    console.log(e);
                }); 
        },
        retrieveAreas(){
            CurseDataService.getAreas()
                .then((response)=>{
                    this.areas = response.data.data
                    // console.log(response.data)
                })
                .catch((e)=>{
                    console.log(e)
                })
        },
        retrieveCareerTypes()
        {
            CurseDataService.getCourses()
                .then((response)=>{
                    this.career_types = response.data.data
                    // console.log(response.data)
                })
        },
        retrieveTrainingLevel()
        {
            CurseDataService.getAcademicLevel()
                    .then((response)=>{
                    this.academic_levels = response.data
                    
                    // console.log(response.data)
                })
        },
        retrieveStudyRegimens()
        {
            CurseDataService.getRegimeStudy()
                .then((response)=>{
                    this.study_regimens = response.data
                    console.log("regimen tipo",response.data)
                })
        },
        retrieveResolutionTypes()
        {
            ResolutionTypeDataService.getAll()
                .then((response)=>{
                    // this.study_regimens = response.data
                    this.resolution_types = response.data
                    console.log(response.data)
                })
        },
        reloadTimes()
        {
            console.log("procesando cambios")
            if(Object.hasOwn(this.academic_level, 'id') && Object.hasOwn(this.study_regimen, 'id') )
            {
                console.log("obteniendo lista")
                StudyTimeDataService.getTimes(this.academic_level.id,this.study_regimen.id)
                    .then((response)=>{
                        let array_times = []
                        //para todos los casos de posibles convinaciones siempre debe votar un array unidimencional 
                        response.data.forEach(element => {
                            array_times.push({id:element})
                        });
                        this.study_times = array_times
                        console.log(this.study_times)
                    })
            }
            // console.log(Object.hasOwn(this.training_level, 'id'))
            // console.log(Object.hasOwn(this.study_regimen, 'id'))
        },
        redirectCareerView(id)
        {
            this.$router.push(`/career/${id}`)
        },
        dateToDMY(date) {
            var d = date.getDate();
            var m = date.getMonth() + 1; //Month from 0 to 11
            var y = date.getFullYear();
            return '' + (d <= 9 ? '0' + d : d) + '/' + (m<=9 ? '0' + m : m) + '/' + y;
        },
        saveCareer()
        {
            let new_career = {
                "sucursal_id":this.institution.sucursal_id ,
                "carrera_tipo_id": this.career_type.id,
                "area_tipo_id": this.area.id,
                "resolucion_tipo_id": this.resolution_type.id,
                "numero_resolucion": this.career.numero_resolucion,
                "fecha_resolucion": this.dateToDMY(this.career.fecha_resolucion) ,
                "descripcion": this.career.descripcion??'',
                "resuelve": this.career.resuelve,
                "carga_horaria": parseInt( this.career.carga_horaria),
                "tiempo_estudio": this.study_time.id,
                "intervalo_gestion_tipo_id": this.study_regimen.id,
                "nivel_academico_tipo_id": this.academic_level.id
            }
            CareerDataService.create(new_career)
                .then((response)=>{
                    if(response.data.statusCode ==201)
                    {
                        this.$toast.add({ severity: 'success', summary: 'Success Message', detail: 'Registro completado', life: 3000 })
                            // this.$router.push('/institutions')  
                            this.retrieveCareers()
                            this.dialog =false
                        // console.log(response.data)
                    }
                    

                })
            console.log(new_career)

        }

    },
    computed: {
        institution_id()
        {
            return this.iu_id
        },
        title ()
        {
            return "Adicionar Curso"
        }

    }
}
</script>