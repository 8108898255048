<template>
   
    <div class="flex justify-content-between  align-items-center pb-1">
        <span class="p-input-icon-left pr-2">
            <i class="pi pi-search" />
            <InputText v-model="filters['global'].value" placeholder="Buscar" />
        </span>
        <Button type="button" size="small" label="Adicionar Cursossss" icon="pi pi-plus" @click="createCareer()" v-if="role.rol !== 'DIRECTOR'" />
    </div>
    <DataTable :value="careers" paginator :rows="5" :rowsPerPageOptions="[5, 10, 20, 50]"  >
        <Column> 
            <template #body="{data}">
                <div class="btn-group me-2" role="group" aria-label="First group"> 
                    <Button v-tooltip="'Ver Detalle'" icon="mdi mdi-eye" text raised rounded aria-label="Filter" @click="redirectCourseView(data.id)" />
                </div>
                <div class="btn-group me-2" role="group" aria-label="First group"  v-if="role.rol !== 'DIRECTOR'">
                    <Button v-tooltip="'Editar'" icon="mdi mdi-pencil" text raised rounded aria-label="Filter" @click="editCourse(data)" />
                </div>
                <div class="btn-group me-2" role="group" aria-label="First group"  v-if="role.rol === 'ADMIN'">
                    <Button v-tooltip="'eliminar'" icon="pi pi-trash" severity="danger" text raised rounded aria-label="Filter" @click="deleteCourse(data.id)" />
                </div>
            </template>
        </Column>
        <!--<Column field="id" header="ID RESOLUCION" ></Column>-->
        <Column field="carreraAutorizada" header="NOMBRE CURSO" sortable>
            <template #body="{data}"> {{ data.nombre}} </template>
        </Column>
        <Column field="carreraAutorizada" header="TIPO INSTITUTO" sortable>
            <template #body="{data}"> {{ data.instituto.tipo_artistica}} </template>
        </Column>
        <Column field="carreraAutorizada" header="ÁREA" sortable>
            <template #body="{data}"> {{ data.area.nombre }} </template>
        </Column>       
        <Column field="intervaloGestionTipo" header="DISCIPLINA" sortable>
            <template #body="{data}"> {{ data.disciplina.nombre }} </template>            
        </Column>
        <Column field="nivelAcademicoTipo.nivelAcademico" header="NIVEL DE FORMACIÓN" sortable>
        <template #body="{data}"> {{ data.niveles.nivel }} </template>
        </Column>            
        <Column field="nivelAcademicoTipo.nivelAcademico" header="HORAS" sortable>
        <template #body="{data}"> {{ data.horas }} </template> 
        </Column>           
        <Column field="numeroResolucion" header="GESTIÓN" sortable>
        <template #body="{data}"> {{ data.gestion }} </template>
        </Column>            
        <Column field="fechaResolucion" header="TOTAL CLASES" sortable>
        <template #body="{data}"> {{ data.total_clases }} </template>            
        </Column>
        <Column field="cargaHoraria" header="CARGA HORARIA" sortable>
        <template #body="{data}"> {{ data.carga_horaria }} </template>            
        </Column>
        <Column field="tiempoEstudio" header="TIEMPO DE ESTUDIOS" sortable>
        <template #body="{data}"> {{ data.tiempo_estudio }} </template>            
        </Column>
        <!-- <Column field="resuelve" header="TIPO DE RESOLUCIÓN" sortable></Column> -->
        <template #paginatorstart>
                
        </template>
        <template #paginatorend>
               
            <strong> Total Registros {{ careers.length }}</strong>
     
        </template>
        <!-- <Column field="representative.name" header="Tipo de Tramite" style="width: 25%"></Column> -->
    </DataTable>

    <Dialog v-model:visible="dialog" modal :header="title" :style="{ width: '80vw' }">
        <div class="formgrid grid">
            <div class="field col-12 md:col-12">
                    <label>Nombre del curso</label>
                    <InputText type="text" class="p-inputtext-sm w-full" v-model="registro.nombre"  @input="registro.nombre = registro.nombre.toUpperCase()"
                    :class="{ 'p-invalid': !registro.nombre && submitted }"
                    />
                    <small v-if="!registro.nombre && submitted" class="p-error">Este campo es obligatorio.</small>
            </div>

            <div class="field col-12 md:col-12">
                    <label>Tipo Formación Superior Artística</label>
                    <Dropdown v-model="registro.instituto_id" :options="tipo"  filter optionLabel="tipo_artistica" class=" p-inputtext-sm w-full" placeholder="Seleccionar Tipo de Formación"
                    :class="{ 'p-invalid': !registro.instituto_id && submitted }"
                    />
                    <small v-if="!registro.instituto_id && submitted" class="p-error">Este campo es obligatorio.</small>
            </div>

            <div class="field col-12 md:col-4">
                    <label>Área</label>
                    <Dropdown v-model="registro.areas_formacion_artistica_id"  filter :options="areas" optionLabel="nombre" class=" p-inputtext-sm w-full " placeholder="Seleccionar Área"  @change="filterDisciplinas()"
                    :class="{ 'p-invalid': !registro.areas_formacion_artistica_id && submitted }"
                    />
                    <small v-if="!registro.areas_formacion_artistica_id && submitted" class="p-error">Este campo es obligatorio.</small>
            </div>  
            
            <div class="field col-12 md:col-4">
                    <label>Disciplina</label>
                    <Dropdown v-model="registro.disciplina_formacion_artistica_id" :options="disciplinas" optionLabel="nombre" class=" p-inputtext-sm w-full" placeholder="Seleccionar Disciplina" 
                    :class="{ 'p-invalid': !registro.disciplina_formacion_artistica_id && submitted }"
                    />
                    <small v-if="!registro.disciplina_formacion_artistica_id && submitted" class="p-error">Este campo es obligatorio.</small>
            </div>

            <div class="field col-12 md:col-4">
                    <label>Nivel de Formación</label>
                    <Dropdown v-model="registro.nivel_formacion_artistica_id" :options="nivel" optionLabel="nivel" class=" p-inputtext-sm w-full" placeholder="Seleccionar Nivel" 
                    :class="{ 'p-invalid': !registro.nivel_formacion_artistica_id && submitted }"
                    />
                    <small v-if="!registro.nivel_formacion_artistica_id && submitted" class="p-error">Este campo es obligatorio.</small>
            </div>
            
            <div class="field col-12 md:col-4">
                    <label>Horas</label>
                    <InputText type="number" class="p-inputtext-sm w-full" v-model="registro.horas"  
                    :class="{ 'p-invalid': !registro.horas && submitted }"
                    />
                    <small v-if="!registro.horas && submitted" class="p-error">Este campo es obligatorio.</small>
                    <!--<Calendar class="p-inputtext-sm w-full" v-model="career.fecha_resolucion"  dateFormat="dd/mm/yy"/>-->
            </div>

            <div class="field col-12 md:col-4">
                    <label>Gestión</label>
                    <Dropdown v-model="registro.gestion" :options="gestion" optionLabel="gestion" class=" p-inputtext-sm w-full" placeholder="Seleccionar Gestión" 
                    :class="{ 'p-invalid': !registro.gestion && submitted }"
                    />
                    <small v-if="!registro.gestion && submitted" class="p-error">Este campo es obligatorio.</small>
                    <!--<Dropdown v-model="academic_level" :options="academic_levels" @change="reloadTimes()" optionLabel="nivelAcademico" class=" p-inputtext-sm w-full" placeholder="Seleccionar Nivel de Formacion" />-->
            </div>  

            <div class="field col-12 md:col-4">
                    <label>Total Clases</label>
                    <InputText type="number" class="p-inputtext-sm w-full" v-model="registro.total_clases"  
                    :class="{ 'p-invalid': !registro.total_clases && submitted }"
                    />
                    <small v-if="!registro.total_clases && submitted" class="p-error">Este campo es obligatorio.</small>
                    <!--<Dropdown v-model="study_regimen" :options="study_regimens" @change="reloadTimes()" optionLabel="intervaloGestion" class=" p-inputtext-sm w-full" placeholder="Seleccionar Regimen" />-->
            </div>  

            <div class="field col-12 md:col-4">
                    <label>Carga Horaria</label>
                    <InputText type="number" class="p-inputtext-sm w-full" v-model="registro.carga_horaria"  
                    :class="{ 'p-invalid': !registro.carga_horaria && submitted }"
                    />
                    <small v-if="!registro.carga_horaria && submitted" class="p-error">Este campo es obligatorio.</small>
            </div>

            <div class="field col-12 md:col-4">
                    <label>Tiempo Estudio</label>
                    <InputText type="number" class="p-inputtext-sm w-full" v-model="registro.tiempo_estudio"   
                    :class="{ 'p-invalid': !registro.tiempo_estudio && submitted }"
                    />
                    <small v-if="!registro.tiempo_estudio && submitted" class="p-error">Este campo es obligatorio.</small>
                    <!-- <Dropdown v-model="study_time" :options="study_times"  optionLabel="id" class=" p-inputtext-sm w-full" placeholder="Seleccionar Tiempo" /> -->
            </div>  
            <!-- <div class="field col-12 md:col-4">
                    <label>Descripción</label>
                    <InputText type="text" class="p-inputtext-sm w-full" v-model="career.descripcion" />
            </div> 

            <div class="field col-12 md:col-4">
                    <label>Observación</label>
                    <InputText type="text" class="p-inputtext-sm w-full" v-model="career.resuelve" />
            </div>   -->
            
        </div>
        <template #footer>
            <Button label="Cancelar" icon="pi pi-times" @click="dialog = false" text />
            <Button label="Guardar" icon="pi pi-check" @click="submitForm" autofocus :disabled="!isFormValid" />
        </template>
    </Dialog>

    <Dialog v-model:visible="dialog_edit" modal header="Historial de Carrera " :style="{ width: '80vw' }">
        <div class="formgrid grid">
            <div class="field col-12 md:col-5">
                    <label>Area de Formación</label>
                    <Dropdown v-model="form.areaTipo" :options="areas" optionLabel="area" class=" p-inputtext-sm w-full" placeholder="Seleccionar Area de Formación" disabled="true" />
            </div>

            <div class="field col-12 md:col-5">
                    <label>Carrera</label>
                    <Dropdown v-model="form.carreraTipo"  filter :options="career_types" optionLabel="carrera" class=" p-inputtext-sm w-full " placeholder="Seleccionar Carrera" disabled="true" />
            </div>  
            
            <!-- <div class="col-12 md:col-2 flex align-items-center justify-content-center">
                <Button label="Guardar" icon="pi pi-check" @click="saveCareer()" autofocus  :disabled="bunton_disabled" />
            </div> -->
            <div class="flex align-items-center">
                <Button label="Adicionar Resolucion" severity="success" icon="pi pi-plus" @click="addResolution()" autofocus  :disabled="bunton_disabled" />
            </div>
            <table class="table">
                <thead>
                    <th> </th>
                    <th v-if="role.rol === 'ADMIN'"> ID </th>
                    <th>Tipo</th>
                    <th>N° Resolucion</th>
                    <th>Fecha Resolucion</th>
                    <th>Nivel Academico</th>
                    <th>Intervalo Gesion Tipo</th>
                    <th>Tiempo Estudio</th>
                    <th>Carga horaria</th>
                    <th></th>
                   
                </thead>
                <tbody>
                    <tr v-for="(resolution, index) in form.resoluciones" :key="index">
                        
                        <td v-if="!resolution.is_edited" class="flex  align-items-center"> 
                            <Button icon="pi pi-pencil" severity="info" text rounded aria-label="Editar" @click="resolution.is_edited = true"  v-if="role.rol !== 'DIRECTOR'"  />
                            <Button icon="pi pi-trash" severity="danger" text rounded aria-label="Eliminar" @click="deleteResolutionCareer(resolution)"  v-if="role.rol !== 'DIRECTOR' && resolution.ultimo === false"/>
                        </td>
                        <td v-else class="flex align-items-center">
                            <Button icon="pi pi-save" severity="success" text rounded aria-label="Guardar" @click="editResolutionCareer(resolution)"   v-if="role.rol !== 'DIRECTOR'" />
                            <Button icon="pi pi-times" severity="danger" text rounded aria-label="Eliminar"  @click="removeResolution(resolution, index)"  v-if="role.rol !== 'DIRECTOR'" />
                        </td>
                        <td v-if="role.rol === 'ADMIN'"> {{ resolution.id }} </td>
                        <td v-if="!resolution.is_edited"> {{ resolution?.resolucionTipo.resolucionTipo }}</td>
                        <td v-else> <Dropdown v-model=" resolution.resolucionTipo" :options="resolution_list" optionLabel="resolucionTipo" class=" p-inputtext-sm" placeholder="Seleccionar Tipo" /> </td>

                        <td v-if="!resolution.is_edited" > {{ resolution.numeroResolucion }}</td>
                        <td v-else>  <InputText type="text" class="p-inputtext-sm " v-model="resolution.numeroResolucion" />  </td>

                        <td v-if="!resolution.is_edited"> {{ $filters.dateFormat(resolution.fechaResolucion) }} </td>
                        <td v-else> <Calendar class="p-inputtext-sm w-full" v-model="resolution.fechaResolucion" dateFormat="dd/mm/yy" />  </td>

                        <td v-if="!resolution.is_edited"> {{ resolution?.nivelAcademicoTipo.nivelAcademico }}</td>
                        <td v-else> <Dropdown v-model="resolution.nivelAcademicoTipo" :options="academic_levels" optionLabel="nivelAcademico" class=" p-inputtext-sm w-full" placeholder="Seleccionar Nivel de Formacion" />  </td>

                        <td v-if="!resolution.is_edited"> {{ resolution?.intervaloGestionTipo.intervaloGestion}}</td>
                        <td v-else>  <Dropdown v-model="resolution.intervaloGestionTipo" :options="study_regimens" optionLabel="intervaloGestion" class=" p-inputtext-sm w-full" placeholder="Seleccionar Regimen" /></td>
                        
                        <td v-if="!resolution.is_edited"> {{ resolution?.tiempoEstudio }}</td>
                        <td v-else> <InputText type="text" class="p-inputtext-sm " v-model="resolution.tiempoEstudio" /> </td>

                        <td v-if="!resolution.is_edited"> {{ resolution?.cargaHoraria  }}</td>
                        <td v-else> <InputText type="text" class="p-inputtext-sm " v-model="resolution.cargaHoraria" /> </td>


                        <td v-if="!resolution.is_edited">
                            <Tag  severity="success" value="Carrera Habilitada"   v-if="resolution.ultimo"></Tag>
                             <!-- <InputSwitch v-model="resolution.ultimo" /> -->
                         </td>
                        <td v-else></td>

                      
                    </tr>
                </tbody>
            </table>
           
            
        </div>
        <!-- <template #footer>
            <Button label="Cancelar" icon="pi pi-times" @click="dialog = false" text />
            <Button label="Guardar" icon="pi pi-check" @click="saveCareer()" autofocus  :disabled="bunton_disabled" />
        </template> -->
    </Dialog>
</template>
<script >
import InstitutionDataService from '@/services/InstitutionDataService'
import ArtisticaDataService from '@/services/ArtisticaDataService'
import CareerTypeDataService from '@/services/CareerTypeDataService'
import TrainingLevelDataService from '@/services/TrainingLevelDataService'
import StudyRegimenDataService from '@/services/StudyRegimenDataService'
import StudyTimeDataService from '@/services/StudyTimeDataService'
import ResolutionTypeDataService from '@/services/ResolutionTypeService'
import CareerDataService from '@/services/CareerDataServie'
import { FilterMatchMode } from 'primevue/api'
import ArtisticaAreasService from '@/services/ArtisticaAreasService'
import ArtisticaDisciplinasService from '@/services/ArtisticaDisciplinasService'
import ArtisticaNivelService from '@/services/ArtisticaNivelService'
import ArtisticaGestionService from '@/services/ArtisticaGestionService'
import FormacionSuperiorArtisticaService from '@/services/FormacionSuperiorArtisticaService'
import { resolveTransitionHooks } from 'vue'
import RegistrationStudentService from '@/services/RegistrationStudentService'

export default{
    name: 'CareerInsitution',
    props: {
        iu_id: Number,
        sucursal_id: Number,
    },
    data: ()=>({
        type :'',
        registro: {
            nombre: '',
            instituto_id: null,
            areas_formacion_artistica_id: null,
            disciplina_formacion_artistica_id: null,
            nivel_formacion_artistica_id: null,
            horas: '',
            gestion: null,
            total_clases: '',
            carga_horaria: '',
            tiempo_estudio: ''  
        },
        Registros:[],
        instituto:[],
        tipo: [],
        careers:[],
        dialog: false,
        dialog_edit: false,
        career:{},
        form: {},
        career_types:[],
        career_types_all:[],
        career_type:{},
        areas: [],
        gestiones: [],
        niveles: [],
        area:{},
        disciplinas: [],  
        academic_levels:[],
        academic_level:{},
        study_regimens: [],
        study_regimen:{},
        study_times:[],
        study_time: null,
        resolution_types: [],
        resolution_list: [],
        resolution_type: {},
        isEditMode: false,
        institution:{},
        submitted: false,
        filters:{
                  global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                  carrera: { value: null, matchMode: FilterMatchMode.CONTAINS },
                  area: { value: null, matchMode: FilterMatchMode.CONTAINS },
                  nivel_academico: { value: null, matchMode: FilterMatchMode.CONTAINS },
                
        }
    }),
    
    mounted(){
        console.log("obteniendo Carreras");
        this.retrieveTipo();
        this.retrieveAreas();
        this.retrieveNivel();
        this.retrieveGestion();
        this.getCursosByInstitutoId();
        this.submitForm();
        this.registro.institucion_educativa_id = this.$route.params.id;
        
    },
    computed: {
        institution_id()
        {
            return this.iu_id
        },
        title ()
        {
            return "Adicionar Curso"
        },
        role()
        {
            return this.$store.state.auth.rol
        },

        isFormValid() {
            return (
            this.registro.nombre &&
            this.registro.instituto_id !== null &&
            this.registro.areas_formacion_artistica_id !== null &&
            this.registro.disciplina_formacion_artistica_id !== null &&
            this.registro.nivel_formacion_artistica_id !== null &&
            this.registro.horas &&
            this.registro.gestion !== null &&
            this.registro.total_clases &&
            this.registro.carga_horaria &&
            this.registro.tiempo_estudio
            );
            
  }
},
    methods:{

        defaultRegistro() {
           this.registro = {};
        },
        format_date(value){
            if (value) {
            console.log('-->',value.substring(0, 10));
            return value.substring(0, 10)
            }
        },
        createCareer()
        {
            this.dialog = true
            this.career = {},
            this.type = 'nuevo';
        },
        async show(carrera_autorizada_id)
        {
            // console.log(carrera_autorizada_id)
           let response =  await CareerDataService.show(carrera_autorizada_id)
           this.form = response.data
           this.dialog_edit =  true
           
           this.form.resoluciones.forEach(element => {
            element.is_edited = false
            element.fechaResolucion = new Date(element.fechaResolucion)
            return element
           });
           console.log(this.form)
        },
        getInstitution()
        {
            InstitutionDataService.getInfo(this.$route.params.id)
            .then((response)=>{
                this.institution = response.data
                // console.log(response.data)
            })
        },
        retrieveCareers()
        {
            InstitutionDataService.getCareerListInstitute(this.$route.params.id)
                .then((response) => {
                    console.log("lista de carreras");
                    console.log(response.data);
                    this.careers = response.data;
                })
                .catch((e) => {
                    console.log(e);
                }); 
        },
        retrieveTipo(){
            console.log('entra al servicio del front');
            ArtisticaDataService.getAll()
                .then((response)=>{
                    //this.tipo = response.data.data       //para que traiga toda la data  
                    this.tipo = response.data.data.filter(option => option.id === 1);  //quitar para que traiga toda la data
                    console.log('josel', this.tipo)
                    if (this.tipo.length > 0) {
                        this.registro.instituto_id = this.tipo[0].id;
                    }
                })
                .catch((e)=>{
                    console.log(e)
                })
        },
        retrieveAreas(){
            console.log('entra al servicio del front');
            ArtisticaAreasService.getAll()
                .then((response)=>{
                    this. areas = response.data.data
                     console.log('joseeee', response.data)
                })
                .catch((e)=>{
                    console.log(e)
                })
        },
        retrieveNivel(){
            console.log('entra al servicio del front');
            ArtisticaNivelService.getAll()
                .then((response)=>{
                    //this. nivel = response.data.data
                    this.nivel = response.data.data.filter(option => option.id === 1);  //quitar para que traiga toda la data 
                    console.log('jose', response.data)
                })
                .catch((e)=>{
                    console.log(e)
                })
        },
        retrieveGestion(){
            console.log('entra al servicio del front');
            ArtisticaGestionService.getAll()
                .then((response)=>{
                    //this. gestion = response.data.data
                    this.gestion = response.data.data.filter(option => option.id === 2024);  //quitar para que traiga toda la data 
                     console.log('jose', response.data)
                })
                .catch((e)=>{
                    console.log(e)
                })
        },
        retrieveCareerTypes()
        {
            CareerTypeDataService.getAllCareer()
                .then((response)=>{
                    this.career_types = response.data
                    // console.log('career types',response.data)
                })
        },
        
        retrieveTrainingLevel()
        {
            TrainingLevelDataService.getAll()
                    .then((response)=>{
                    this.academic_levels = response.data
                    
                    // console.log(response.data)
                })
        },
        retrieveStudyRegimens()
        {
            StudyRegimenDataService.getAll()
                .then((response)=>{
                    this.study_regimens = response.data
                    // console.log(response.data)
                })
        },
        retrieveResolutionTypes()
        {
            ResolutionTypeDataService.getAll()
                .then((response)=>{
                    // this.study_regimens = response.data
                    this.resolution_types = response.data
                    console.log(response.data)
                })
            ResolutionTypeDataService.getList()
                .then((response) =>{
                    this.resolution_list = response.data
                } )
            
        },
        reloadTimes()
        {
            console.log("procesando cambios")
            if(Object.hasOwn(this.academic_level, 'id') && Object.hasOwn(this.study_regimen, 'id') )
            {
                console.log("academic ", this.academic_level)
                console.log("regimen estudio", this.study_regimen)
                if(this.academic_level.nivelAcademico === 'TECNICO MEDIO' && this.study_regimen.intervaloGestion === 'SEMESTRE')
                {
                    this.study_time = 4
                }

                if(this.academic_level.nivelAcademico === 'TECNICO MEDIO' && this.study_regimen.intervaloGestion === 'AÑO')
                {
                    this.study_time = 2
                }

                if(this.academic_level.nivelAcademico === 'TECNICO SUPERIOR' && this.study_regimen.intervaloGestion === 'SEMESTRE')
                {
                    this.study_time = 6
                }

                if(this.academic_level.nivelAcademico === 'TECNICO SUPERIOR' && this.study_regimen.intervaloGestion === 'AÑO')
                {
                    this.study_time = 3
                }

                // StudyTimeDataService.getTimes(this.academic_level.id,this.study_regimen.id)
                //     .then((response)=>{
                //         let array_times = []
                //         //para todos los casos de posibles convinaciones siempre debe votar un array unidimencional 
                //         response.data.forEach(element => {
                //             array_times.push({id:element})
                //         });
                //         this.study_times = array_times
                //         console.log(this.study_times)
                //     })
            }
            // console.log(Object.hasOwn(this.training_level, 'id'))
            // console.log(Object.hasOwn(this.study_regimen, 'id'))
        },
        redirectCourseView(id)
        {   
            console.log('id ===>',id);
            this.$router.push(`/course/${id}`)
        },
        dateToDMY(date) {
            var d = date.getDate();
            var m = date.getMonth() + 1; //Month from 0 to 11
            var y = date.getFullYear();
            return '' + (d <= 9 ? '0' + d : d) + '/' + (m<=9 ? '0' + m : m) + '/' + y;
        },

        formatFecha(value) {
            return va.toLocaleDateString('es-BO', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric' 
        });
        },

        saveCourse()
        {
            if(this.type == 'nuevo'){
                //NUEVO REGISTRO
                
                this.registro.instituto_id = this.registro.instituto_id.id;
                this.registro.areas_formacion_artistica_id = this.registro.areas_formacion_artistica_id.id;
                this.registro.disciplina_formacion_artistica_id = this.registro.disciplina_formacion_artistica_id.id;
                this.registro.nivel_formacion_artistica_id = this.registro.nivel_formacion_artistica_id.id;
                this.registro.horas = this.registro.horas;
                this.registro.gestion = this.registro.gestion.id;
                this.registro.total_clases = this.registro.total_clases;
                this.registro.carga_horaria = this.registro.carga_horaria;
                this.registro.tiempo_estudio = this.registro.tiempo_estudio;
                console.log(this.registro);
                FormacionSuperiorArtisticaService.create(this.registro)
                    .then((response)=>{
                        if(response.data.statusCode ==201)
                        {
                            console.log(response.data);
                            this.getCursosByInstitutoId();        
                            this.$toast.add({ severity: 'success', summary: 'Success Message', detail: 'Registro completado', life: 3000 })
                            this.dialog = false;
                        }
                    })
            }
            if(this.type == 'editar'){
                //EDITAR REGISTRO.
                this.registro.instituto_id = this.registro.instituto_id.id;
                this.registro.areas_formacion_artistica_id = this.registro.areas_formacion_artistica_id.id;
                this.registro.disciplina_formacion_artistica_id = this.registro.disciplina_formacion_artistica_id.id;
                this.registro.nivel_formacion_artistica_id = this.registro.nivel_formacion_artistica_id.id;
                this.registro.horas = this.registro.horas;
                this.registro.gestion = this.registro.gestion.id;
                this.registro.total_clases = this.registro.total_clases;
                this.registro.carga_horaria = this.registro.carga_horaria;
                this.registro.tiempo_estudio = this.registro.tiempo_estudio;

                FormacionSuperiorArtisticaService.editar(this.registro,data.id)
                    .then((response)=>{
                        if(response.data.statusCode ==201)
                        {
                            console.log(response.data);
                            this.getCursosByInstitutoId();        
                            this.$toast.add({ severity: 'success', summary: 'Success Message', detail: 'Registro completado', life: 3000 })
                            this.dialog = false;
                        }
                    })
                /*FormacionSuperiorArtisticaService.create(this.registro,data.id)
                    .then((response)=>{
                        if(response.data.statusCode ==201)
                        {
                            console.log(response.data);
                            this.getAll();        
                            //this.$toast.add({ severity: 'success', summary: 'Success Message', detail: 'Registro completado', life: 3000 })
                        }
                    })*/
            }
                        
        },
        editCourse(data) 
        {
            console.log("Editando curso...",data);
            this.registro = data;
            this.registro.instituto_id = data.instituto;
            this.registro.areas_formacion_artistica_id = data.area;
            this.registro.disciplina_formacion_artistica_id = data.disciplina;
            this.registro.nivel_formacion_artistica_id = data.niveles;
            this.registro.gestion = data.gestiones;
            this.type = 'editar';
            // Lógica para editar el curso
            this.createCareer();
            this.isEditMode = true;
        },

        addResolution(){
            this.form.resoluciones.push({is_edited:true,
                                        numeroResolucion:'',
                                        resolucionTipo: {},
                                        nivelAcademicoTipo: {},
                                        intervaloGestionTipo: {},
                                        tiempoEstudio: '',
                                        cargaHoraria: '',
                                        fechaResolucion: new Date(),})
        },
        removeResolution(item,index){
            if(!item.id)
            {
                this.form.resoluciones.splice(index,1)
            }else
            {
                item.is_edited= false
            }

        },
        async editResolutionCareer(resolucion)
        {
            console.log('edit resolution',resolucion)
            let payload = {  
                numeroResolucion: resolucion.numeroResolucion+'',
                fechaResolucion: resolucion.fechaResolucion,
                resolucionTipoId: resolucion.resolucionTipo.id ,
                nivelAcademicoTipoId: resolucion.nivelAcademicoTipo.id,
                intervaloGestionTipoId: resolucion.intervaloGestionTipo.id,
                tiempoEstudio: parseInt(resolucion.tiempoEstudio) ,
                cargaHoraria: parseInt(resolucion.cargaHoraria),
                ultimo: resolucion.ultimo ?? false,
            }
            if(resolucion.id)
            {
                let response = await CareerDataService.editCareerResolution(resolucion.id, payload)
                console.log(response.data)
            }else{
                payload.carreraAutorizadaId = this.form.id
                let response = await CareerDataService.createCareerResolution(payload)
                console.log(response.data)
            }

           let response =  await CareerDataService.show(this.form.id)
           this.form = response.data
           this.dialog_edit =  false
           this.$toast.add({ severity: 'success', summary: 'Success Message', detail: 'Registro completado', life: 3000 }) 
            

            
        },
        async deleteResolutionCareer(resolucion)
        {
            this.$confirm.require({
                message: 'Esta seguro de eliminar la resolucion de la carrera?',
                header: 'Confirmacion',
                icon: 'pi pi-exclamation-triangle',
                rejectClass: 'p-button-secondary p-button-outlined',
                rejectLabel: 'NO',
                acceptLabel: 'SI',
                accept: async () => {
                    let response = await CareerDataService.deleteCareerResolution(resolucion.id)
                    console.log(response.data)
                    this.dialog_edit =  false
                    this.$toast.add({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted', life: 3000 });
                },
                reject: () => {
                    // this.$toast.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
                }
            });
        },
        async deleteCourse(id)
        {   console.log('=====>',id);
            this.$confirm.require({
                message: 'Esta seguro de eliminar el curso?',
                header: 'Confirmacion',
                icon: 'pi pi-exclamation-triangle',
                rejectClass: 'p-button-secondary p-button-outlined',
                rejectLabel: 'NO',
                acceptLabel: 'SI',
                accept: async () => {
                    let response = await FormacionSuperiorArtisticaService.eliminar(id)
                    console.log(response.data);
                    this.$toast.add({ severity: 'success', summary: 'Info', detail: 'Se elimino la carrera', life: 3000 });
                    this.getAll ();
                },
                reject: () => {
                    // this.$toast.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
                }
            });
        },

        getCursosByInstitutoId(){
            console.log('listar resultado');
            FormacionSuperiorArtisticaService.getCursosByInstitutoId(this.$route.params.id)
                .then((response)=>{
                    if(response.data.statusCode == 200)
                    {
                        console.log('**********************');
                        console.log(response.data.data);
                        this.careers = [];
                        this.careers = response.data.data;
                        //this.$toast.add({ severity: 'success', summary: 'Success Message', detail: 'Registro completado', life: 3000 })
                    }
            })
        },

        filterDisciplinas(){
            console.log('area seleccionada => ', this.registro.areas_formacion_artistica_id);
            this.disciplinas = this.registro.areas_formacion_artistica_id.disciplinas;
        },

        submitForm() {
            console.log("aquiiiiiiiiiiiii")
            this.submitted = true;
            if (this.isFormValid) {
                // Guardar los datos del formulario
                console.log("Formulario válido, guardando datos...", this.registro);
                this.saveCourse();
            }
         }


    }
}

</script>