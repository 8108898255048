import http from "@/http-common";
class CareerTypeDataSerevice {
    getAll(){
      return http.get("/carrera-tipo/carreras");
    }

    getAllCareer()
    {
      return http.get('/carrera-tipo/getAll')
    }

    // getInstitutionAreas(id){
    //   return http.get(`/jurisdiccion-geografica/2001/codigo/${id}`);
    // }

    // get(id) {
    //   return http.get(`/jurisdiccion-geografica/2001/codigo/${id}`);
    // }
  
  create(data) {
    return http.post("/carrera-tipo", data);
  }
  
  update(id, data) {
    return http.put(`/carrera-tipo`, data);
  }
  
  delete(id) {
    return http.delete(`/carrera-tipo/${id}`).catch((error)=>{
      return error.response;
    });
  }
  
  //   deleteAll(): Promise<any> {
  //     return http.delete(`/users`);
  //   }
  
  //   findByTitle(title: string): Promise<any> {
  //     return http.get(`/users?title=${title}`);
  //   }
  }
  
  export default new CareerTypeDataSerevice();