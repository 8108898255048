import http from "@/http-common";
class InstitutionDataService {
    getAll(){
      return http.get("/institucion-educativa-sucursal/itts");  
    }

    getArtistic(){
      return http.get("/institucion-educativa-sucursal/itts-artistica");  
    }
  
    get(id) {
      return http.get(`/institucion-educativa/${id}`);
    }
  
    create(data) {
      return http.post("/institucion-educativa/create", data);
    }

    getInfo(id){
      return http.get(`/institucion-educativa/itt/${id}`);
    }

    getCareers(id){
      return http.get(`/carrera-autorizada/ie/${id}`)
    }

    getCareerListInstitute(id){
      return http.get(`/carrera-autorizada/career_list/${id}`)
    }

    getImage(id)
    {
      return http.get(`/institucion-educativa-imagen/download-logo/${id}`,{
        responseType: 'arraybuffer' 
    })
    }
    /**
     * 
     * institucion_educativa_id: 421515
     * file: baner.jpg
     */
    uploadImage(data)
    {
      return http.post('/institucion-educativa-imagen',data,{
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
    }

     /**
     * 
     * institucion_educativa_id: 421515
     * file: baner.jpg
     */
     uploadFile(data)
     {
       return http.post('/institucion-educativa-imagen/upload',data,{
         headers: {
           'Content-Type': 'multipart/form-data'
         }
       })
     }

    updateGeolocation(cod_le,data)
    {
      return http.put(`/jurisdiccion-geografica/${cod_le}`,data)
    }

    getInstitionTeacher(codigo_ritt,carrera_tipo_id){
      return http.get(`/institucion-educativa/teachers/${codigo_ritt}/${carrera_tipo_id}`)
    }

    checkAuth()
    {
      return http.post('/institucion-educativa/check_auth')
    }
        
    getDependencias(){
      return http.get('dependencia-tipo');
    }

    getDependenciaTipo(id){
      return http.get(`dependencia-tipo/${id}`);
    }
  
    createHistory(payload)
    {
      return http.post('institucion-educativa-historial/create-history', payload)
    }

    getInstitutionHistory(institucion_educativa_id)
    {
      return http.get(`institucion-educativa-historial/${institucion_educativa_id}`)
    }

    getInstituteCertificate (institucion_educativa_id)
    {
      return http.get(`institucion-educativa-imagen/certificado/${institucion_educativa_id}`,{responseType: 'arraybuffer'})
    }

    getTeachers(institucion_educativa_id)
    {
      return http.get(`maestro-inscripcion/teacher-instituto/${institucion_educativa_id}`)
    }

    getTeachersGender(institucion_educativa_id)
    {
      return http.get(`maestro-inscripcion/teacher-gender/${institucion_educativa_id}`)
    }

    migrate(institucion_educativa_id) {
      return http.get(`/institucion-educativa/migrate/${institucion_educativa_id}`)
    }
  
  //   update(id: any, data: any): Promise<any> {
  //     return http.put(`/users/${id}`, data);
  //   }
  
  //   delete(id: any): Promise<any> {
  //     return http.delete(`/users/${id}`);
  //   }
  
  //   deleteAll(): Promise<any> {
  //     return http.delete(`/users`);
  //   }
  
  //   findByTitle(title: string): Promise<any> {
  //     return http.get(`/users?title=${title}`);
  //   }
  }
  
  export default new InstitutionDataService();