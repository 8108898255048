<template>
    <div class="card card-w-title">
        <!-- <BlockUI :blocked="props.formData.person !== 0 && props.formData.person !== null && props.formData.person !== undefined"> -->
        <BlockUI :blocked="false">
            <div class="formrid grid">      
                <div class="field col-12 md:col-3 mb-0">
                    <label for="gender">Sexo</label>
                    <AutoComplete v-model="formRef.gender" id="gender" optionLabel="genero" :suggestions="filteredGender" @complete="searchGender" dropdown forceSelection />
                    <small v-show="validationErrors.gender && submitted" class="p-error">es requerido</small>
                </div>                
                <div class="field col-12 md:col-3 mb-0">
                    <label for="email">Correo electrónico</label>
                    <input type="email" id="email" v-model="formRef.email" pattern=".+@globex\.com" required class="p-inputtext p-component" >
                    <small v-show="validationErrors.email && submitted" class="p-error">es requerido</small>
                </div>
                <div class="field col-12 md:col-3 mb-0">
                    <label for="civilStatus">Estado Civil</label>
                    <AutoComplete v-model="formRef.civilStatus" id="civilStatus" optionLabel="estado_civil" :suggestions="filteredCivilStatus" @complete="searchCivilStatus" dropdown forceSelection  />
                    <small v-show="validationErrors.civilStatus && submitted" class="p-error">es requerido</small>
                </div>
                <div class="field col-12 md:col-3 mb-0">
                    <label for="blood">Tipo de Sangre</label>
                    <AutoComplete v-model="formRef.blood" id="blood" optionLabel="sangre" :suggestions="filteredBlood" @complete="searchBlood" dropdown forceSelection />
                    <small v-show="validationErrors.blood && submitted" class="p-error">es requerido</small>
                </div>
                <div class="field col-12 md:col-3 mb-0">
                    <label for="languageNative">Idioma Natal</label>
                    <AutoComplete v-model="formRef.languageNative" id="languageNative" optionLabel="idioma" :suggestions="filteredLanguageNative" @complete="searchLanguageNative" dropdown forceSelection />
                    <small v-show="validationErrors.languageNative && submitted" class="p-error">es requerido</small>
                </div>
                <div class="field col-12 md:col-3 mb-0">
                    <label for="disability">¿Tiene alguna discapacidad?</label>
                    <SelectButton v-model="formRef.disability" id="disability" :options="disability" aria-labelledby="basic" />
                    <small v-show="validationErrors.disability && submitted" class="p-error">es requerido</small>
                </div>
                
                <div class="field col-12 md:col-3 mb-0" v-if="formRef.disability === 'SI'" >
                    <label for="ibc">Carnet IBC</label>
                    <InputText id="ibc" type="text" v-model="formRef.ibc" />
                </div>  
                <div class="field col-12 md:col-3 mb-0">
                    <label for="doubleNationality">¿Tiene doble nacionalidad?</label>
                    <SelectButton v-model="formRef.doubleNationality" id="doubleNationality" :options="doubleNationality" aria-labelledby="basic" />
                    <small v-show="validationErrors.doubleNationality && submitted" class="p-error">es requerido</small>
                </div>    
                <!-- <div class="field col-12 md:col-3 mb-0">
                    <label for="official">Oficialia</label>
                    <InputText id="official" type="text" v-model="formRef.official" />
                    <small v-show="validationErrors.official && submitted" class="p-error">es requerido</small>
                </div>  
                <div class="field col-12 md:col-3 mb-0">
                    <label for="book">Libro</label>
                    <InputText id="book" type="text" v-model="formRef.book" />
                    <small v-show="validationErrors.book && submitted" class="p-error">es requerido</small>
                </div>  
                <div class="field col-12 md:col-3 mb-0">
                    <label for="departure">Partida</label>
                    <InputText id="departure" type="text" v-model="formRef.departure" />
                    <small v-show="validationErrors.departure && submitted" class="p-error">es requerido</small>
                </div>  
                <div class="field col-12 md:col-3 mb-0">
                    <label for="invoice">Folio</label>
                    <InputText id="invoice" type="text" v-model="formRef.invoice" />
                    <small v-show="validationErrors.invoice && submitted" class="p-error">es requerido</small>
                </div>      -->
                 
                <div class="field col-12 md:col-3 mb-0" v-if="formRef.doubleNationality ==='SI'">
                    <label for="passport">Pasaporte</label>
                    <InputText id="passport" type="text" v-model="formRef.passport" />
                </div>   
                <div class="field col-12 md:col-3 mb-0">
                    <label for="phone">Teléfono Celular</label>
                    <InputMask v-model="formRef.phone" id="phone" date="phone" mask="99999999" placeholder="99999999" />
                    <small v-show="validationErrors.phone && submitted" class="p-error">es requerido</small>
                </div>     
                <div class="field col-12 md:col-3 mb-0">
                    <label for="militaryCard">Libreta Militar</label>
                    <InputText id="militaryCard" type="text" v-model="formRef.militaryCard" />
                </div>   
            </div>
        </BlockUI>
        <div class="pt-3 flex justify-content-between align-items-center">
            <div class="text-left">
                <Button label="Anterior" @click="prevPage()" icon="pi pi-angle-left" />
            </div>
            <div class="text-left" v-if="props.type !== 'student'">
                <Button label="Siguiente" @click="nextPage()" icon="pi pi-angle-right" iconPos="right" />
            </div>
            <div class="text-left" v-if="props.type === 'student'">
                <Button label="Matricular" @click="complete()" icon="pi pi-angle-right" iconPos="right" />
            </div>
        </div>
    </div>
</template>
        

<script setup>
import { ref, onMounted, onBeforeMount, onUnmounted, onActivated, defineEmits, defineProps } from 'vue';
import GenderTypeDataService from '@/services/GenderTypeDataService';
import CivilStatusTypeDataService from '@/services/CivilStatusTypeDataService';
import BloodTypeDataService from '@/services/BloodTypeDataService';
import LanguageTypeDataService from '@/services/LanguageTypeDataService';
import { objectExpression } from '@babel/types';

const validationErrors = ref({});

const props = defineProps({
    formData: {
        type: Object,
        default: null
    },
    type: {
        type: String,
        default: ''
    }
});

const formRef = ref({    
    gender: null,
    email: '',
    civilStatus: null,
    blood: null,
    languageNative: null,
    disability: '',
    doubleNationality: '',
    rda: '',    
    official: '',
    book: '',
    departure: '',
    invoice: '',
    ibc: '',
    passport: '',
    phone: '',
    militaryCard: ''
});

const gender = ref({});
const civilStatus = ref({});
const blood = ref({});
const languageNative = ref({});
const disability = ref(['NO', 'SI']);
const doubleNationality = ref(['NO', 'SI']);


onMounted(async() => {        
    await Promise.all([listGender(),listCivilStatus(),listBlood(),listLanguajeNative()]);
    if( props.formData.id === 0 ){
        return;
    }

    formRef.value.email = props.formData.email
    formRef.value.disability = props.formData.disability ? 'SI' : 'NO'
    formRef.value.doubleNationality = props.formData.doubleNationality ? 'SI' : 'NO'
    formRef.value.rda = props.formData.rda
    formRef.value.official = props.formData.official
    formRef.value.book = props.formData.book
    formRef.value.departure = props.formData.departure
    formRef.value.invoice = props.formData.invoice
    formRef.value.ibc = props.formData.ibc
    formRef.value.passport = props.formData.passport
    formRef.value.phone = props.formData.phone
    formRef.value.militaryCard = props.formData.militaryCard
});

// onBeforeMount(() => {
// });

onActivated(() => {
});

onUnmounted(() => {
    // console.log('unmounted has been called')
});

const listGender = () => {
    GenderTypeDataService.getAll().then((data) => {
        gender.value = data.data.data;
        if (!Object.is(props.formData.gender)){
            formRef.value.gender = (gender.value)[findIndexDataById(gender.value, props.formData.gender)];
        } 
    });
}
const filteredGender = ref();
const searchGender = (event) => {
    // let _items = [...civilStatus.value.keys()];
    setTimeout(() => {
        if (!event.query.trim().length) {
            filteredGender.value = [...gender.value];
        } else {
            filteredGender.value = gender.value.filter((item) => {
                return item.estado_civil.toLowerCase().startsWith(event.query.toLowerCase());
            });
        }
    }, 100);
}


const listCivilStatus = () => {
    CivilStatusTypeDataService.getAll().then((data) => {
        civilStatus.value = data.data.data;
        if (!Object.is(props.formData.civilStatus)){
            formRef.value.civilStatus = (civilStatus.value)[findIndexDataById(civilStatus.value, props.formData.civilStatus)];
        }
    });
}
const filteredCivilStatus = ref();
const searchCivilStatus = (event) => {
    // let _items = [...civilStatus.value.keys()];
    setTimeout(() => {
        if (!event.query.trim().length) {
            filteredCivilStatus.value = [...civilStatus.value];
        } else {
            filteredCivilStatus.value = civilStatus.value.filter((item) => {
                return item.estado_civil.toLowerCase().startsWith(event.query.toLowerCase());
            });
        }
    }, 100);
}

const listBlood = () => {
    BloodTypeDataService.getAll().then((data) => {
        blood.value = data.data.data;
        if (!Object.is(props.formData.blood)){
            formRef.value.blood = (blood.value)[findIndexDataById(blood.value, props.formData.blood)];
        }
    });
}
const filteredBlood = ref();
const searchBlood = (event) => {
    // let _items = [...blood.value.keys()];
    setTimeout(() => {
        if (!event.query.trim().length) {
            filteredBlood.value = [...blood.value];
        } else {
            filteredBlood.value = blood.value.filter((item) => {
                return item.sangre.toLowerCase().startsWith(event.query.toLowerCase());
            });
        }
    }, 100);
}

const listLanguajeNative = () => {
    LanguageTypeDataService.getAll().then((data) => {
        languageNative.value = data.data.data;
        if (!Object.is(props.formData.languageNative)){
            formRef.value.languageNative = (languageNative.value)[findIndexDataById(languageNative.value, props.formData.languageNative)];
        }
    });
}
const filteredLanguageNative = ref();
const searchLanguageNative = (event) => {
    // let _items = [...languageNative.value.keys()];
    setTimeout(() => {
        if (!event.query.trim().length) {
            filteredLanguageNative.value = [...languageNative.value];
        } else {
            filteredLanguageNative.value = languageNative.value.filter((item) => {
                return item.idioma.toLowerCase().startsWith(event.query.toLowerCase());
            });
        }
    }, 100);
}

const submitted = ref(false);
const emit = defineEmits([]);
const nextPage = (() => {
    if(!formRef.value.rda){
        formRef.value.rda = '';
    }
    if(!formRef.value.ibc){
        formRef.value.ibc = '';
    }
    if(!formRef.value.passport){
        formRef.value.passport = '';
    }
    if(!formRef.value.militaryCard){
        formRef.value.militaryCard = '';
    }
    submitted.value = true;
    if (validateForm() ) {
        emit('nextPage', {formData: formRef.value, pageIndex: 1});
    }
});

const prevPage = () => {
    emit('prevPage', {pageIndex: 1});
}

const complete = (() => {

    console.log('complete event',formRef.value)
    

    if(!formRef.value.ibc){
        formRef.value.ibc = '';
    }
    if(!formRef.value.passport){
        formRef.value.passport = '';
    }
    if(!formRef.value.militaryCard){
        formRef.value.militaryCard = '';
    }

    submitted.value = true;
    if (validateForm() ) {
        emit('complete', {formData: formRef.value});
    }    
});

const validateForm = (() => {
    if (!formRef.value.gender)
        validationErrors.value['gender'] = true;
    else
        delete validationErrors.value['gender'];

    if (!formRef.value.email)
        validationErrors.value['email'] = true;
    else
        delete validationErrors.value['email'];

    if (!formRef.value.civilStatus)
        validationErrors.value['civilStatus'] = true;
    else
        delete validationErrors.value['civilStatus'];

    if (!formRef.value.blood)
        validationErrors.value['blood'] = true;
    else
        delete validationErrors.value['blood'];

    if (!formRef.value.languageNative)
        validationErrors.value['languageNative'] = true;
    else
        delete validationErrors.value['languageNative'];

    if (!formRef.value.disability)
        validationErrors.value['disability'] = true;
    else
        delete validationErrors.value['disability'];

    if (!formRef.value.doubleNationality)
        validationErrors.value['doubleNationality'] = true;
    else
        delete validationErrors.value['doubleNationality'];

    // if (!formRef.value.official)
    //     validationErrors.value['official'] = true;
    // else
    //     delete validationErrors.value['official'];

    // if (!formRef.value.book)
    //     validationErrors.value['book'] = true;
    // else
    //     delete validationErrors.value['book'];

    // if (!formRef.value.departure)
    //     validationErrors.value['departure'] = true;
    // else
    //     delete validationErrors.value['departure'];

    // if (!formRef.value.invoice)
    //     validationErrors.value['invoice'] = true;
    // else
    //     delete validationErrors.value['invoice'];

    if (!formRef.value.phone)
        validationErrors.value['phone'] = true;
    else
        delete validationErrors.value['phone'];

    return !Object.keys(validationErrors.value).length;
});

const findIndexDataById = (data, id) => {
    let index = -1;
    for (let i = 0; i < data.length; i++) {
        if (data[i].id === id) {
            index = i;
            break;
        }
    }
    return index;
};


</script>
