<template>
    <Dialog v-model:visible="dialog" modal header="Cambiar Password" :style="{ width: '50vw' }">
    <div class="grid">
        <div class="field md:col-4">
            <label for="firstname6">Usuario</label>
            <InputText type="text" :value="`${user?.persona?.carnetIdentidad}${user?.persona?.complemento}`" class="w-full" disabled />
        </div>
        <div class="field md:col-8">
            <label for="firstname6">Nombre </label>
            <InputText type="text" :modelValue="`${user?.persona?.nombre} ${user?.persona?.paterno} ${user?.persona?.materno}`" class="w-full" disabled />
        </div>
        <div class="field md:col-6">
            <label for="">Nuevo Password</label>
            <InputText type="password" v-model="user.newpass" class="w-full"/>
        </div>
        <div class="field md:col-6">
            <label for="">Confirmar Nuevo Password</label>
            <InputText type="password" v-model="user.newpass2" class="w-full" />
        </div>
    </div>
    <template #footer>
        <Button label="Cancelar" icon="pi pi-times" @click="closeDialog" text />
        <Button label="Guardar" icon="pi pi-check" @click="resetPassword" autofocus />
    </template>
    </Dialog>
</template>
<script setup>
import { computed, onMounted, ref, toRefs } from 'vue';
const props = defineProps(['dialog','user'])
const emit = defineEmits(['closeDialog','resetPassword'])
const dialog = computed({
    get(){
        return props.dialog
    },
    set(){
        closeDialog()
    }
})

const user = computed(()=>{
    let user  = props.user
    return user
})

const closeDialog=()=>{
    emit('closeDialog',false)
}

const resetPassword = () =>{
    emit('resetPassword',user.value)
}

onMounted(()=>{
    console.log('cargando component',props)
})
</script>