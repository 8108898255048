
<template>
    <Card>
        <template #title>
            <div class="flex flex-wrap gap-2 align-items-center justify-content-between">
                <span>Carreras / Cursos</span>
                <div>
                    <span class="p-input-icon-left pr-2">
                        <i class="pi pi-search" />
                        <InputText v-model="filters['global'].value" placeholder="Buscar" />
                    </span>
                    <Button label="Nuevo" icon="pi pi-plus" severity="success" @click="openNew" />
                </div>
            </div>
        </template>
        <template #content>
            
            <DataTable :value="list" paginator :rows="rows_numbers" :rowsPerPageOptions="[5, 10, 15, 20, 50]" v-model:filters="filters"
                    tableStyle="min-width: 50rem" :loading="loading">
               
                <template #empty> No se encontraron institutos. </template>
                <template #loading> Loading customers data. Please wait. </template>
                <Column field="carrera" header="NOMBRE" ></Column>                     
                <Column field="carreraGrupoTipo" header="TIPO">
                    <template #body="slotProps">
                        <Tag :value="slotProps.data.carreraGrupoTipo.grupo" :severity="getStatusLabel(slotProps.data.carreraGrupoTipo.id)" />
                    </template>
                </Column>                   
                <Column :exportable="false" style="min-width: 2rem" class="pl-0 pr-0" bodyStyle="text-align:center">
                    <template #body="slotProps">
                        <div class="flex">
                            <div class="kicon-button-primary" @click="editRow(slotProps.data)">
                                <i class="bx bxs-pencil"></i>
                            </div>
                            <div class="kicon-button-danger" @click="deleteRecord(slotProps.data)">
                                <i class="bx bxs-trash-alt"></i>
                            </div>
                        </div>
                    </template>
                </Column>
            </DataTable>
      

            <Dialog v-model:visible="recordDialog" :style="{width: '450px'}" :header="title" :modal="true" class="p-fluid">
                <div class="field">
                    <label for="carrera">Carrera</label>
                    <InputText id="carrera" v-model.trim="record.carrera" required="true" autofocus :class="{'p-invalid': submitted && !record.carrera}" />
                    <small class="p-error" v-if="submitted && !record.carrera">Es requerido.</small>
                </div>

                <div class="field">
                    <label for="carreraGrupoTipo" class="mb-3">Tipo de carrera</label>
                    <Dropdown id="carreraGrupoTipo" v-model="record.carreraGrupoTipo" :options="statuses" optionLabel="grupo" placeholder="Seleccionar tipo">
                        <template #value="slotProps">                            
                            <div v-if="slotProps.value && slotProps.value.value">
                                <Tag :value="slotProps.value.grupo" :severity="getStatusLabel(slotProps.value.id)" />
                            </div>
                            <div v-else-if="slotProps.value && !slotProps.value.value">
                                <Tag :value="slotProps.value.grupo" :severity="getStatusLabel(slotProps.value.id)" />
                            </div>
                            <span v-else>
                                {{slotProps.placeholder}}
                            </span>
                        </template>
                    </Dropdown>
                </div>
                <template #footer>
                    <Button label="Cancelar" icon="pi pi-times" text @click="hideDialog"/>
                    <Button label="Guardar" icon="pi pi-check" text @click="saveRecord" />
                </template>
            </Dialog>

        </template>
    </Card>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import { FilterMatchMode } from 'primevue/api';
import CareerTypeDataService from '@/services/CareerTypeDataService';
import { useToast } from 'primevue/usetoast';
import { useStore } from 'vuex';
import { useConfirm } from "primevue/useconfirm";

const store = useStore()
const toast = useToast();
const confirm = useConfirm()
const filters = ref({
                        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                        Carrera: { value: null, matchMode: FilterMatchMode.CONTAINS },
                    })
const list = ref([])
const record = ref({});
const recordDialog = ref(false);
const submitted = ref(false);
const statuses = ref([
    { grupo: 'CARRERA', id: 1 },
    { grupo: 'CURSOS', id: 2 }
]);
const rows_numbers = computed(()=>{
    return store.state.rows_number
})
const loading = false;
const title = computed(()=>{
    let title = 'Nuevo'
    if(record?.value?.id)
    {
        title = 'Editar'
    }
    return title
})
onMounted(() => {    
   getList()
});



const getList = async ()=>{
    // console.log('obteniendo lista')
    // loading.value = true
    let response = await  CareerTypeDataService.getAll()
    list.value = response.data.data
    // loading.value = false
    console.log('lista carreras',response.data)
}

const getStatusLabel = (status) => {
    switch (status) {
        case 1:
            return 'primary';
        case 2:
            return 'success';
        default:
            return 'danger';
    }
};

const editRow = (rowData)=> 
{    
    record.value =  Object.assign({}, rowData)
    recordDialog.value = true
    submitted.value = false
}

const deleteRecord = (rowData) => {
    confirm.require({
        message:`¿Esta de acuerdo en eliminar la carrera: ${rowData.carrera}? `,
        header: 'Eliminar Carrera',
        icon: 'bx bx-error',
        acceptClass: 'p-button-danger',
        acceptLabel: 'Si',
        accept: () => {
            CareerTypeDataService.delete(rowData.id).then((data) => {
                if(data.data.statusCode !== 203){
                    toast.add({ severity: 'error', summary: 'Error', detail: 'No puede eliminarse el registro', life: 3000 });
                }
                getList()
                toast.add({severity:'success', summary: 'Correcto', detail: 'Registro eliminado', life: 3000});
            })
        },
        reject: () => {
            // toast.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
            console.log('cancelando la operacion')
        }
    })   
   
};

const openNew = () => {
    record.value = {};
    submitted.value = false;
    recordDialog.value = true;
};

const hideDialog = () => {
    recordDialog.value = false;
    submitted.value = false;
};

const saveRecord = () => {
    submitted.value = true;
    if(!(record.value.carrera && record.value.carreraGrupoTipo))
    {
        console.log('no tiene asignatura o abrevicion')
        // toast.add({ severity: 'info', summary: 'Falta Informacion', detail: 'Debe llenar información en los datos requeridos', life: 3000 });
    }
    else{

        if (record.value.carrera.trim()) {
            if (record.value.id) {
                CareerTypeDataService.update(record.value.id, record.value).then((data) => {
                    if(data.data.statusCode !== 202){
                        record.value = {}    
                        toast.add({severity:'danger', summary: 'Error', detail: 'No puede actualizar el registro', life: 3000});
                        return;
                    }
                    toast.add({severity:'success', summary: 'Correcto', detail: 'Registro actualizado', life: 3000});
                    recordDialog.value = false
                    getList()
                });            
            }
            else {
                CareerTypeDataService.create({carrera: record.value.carrera, carreraGrupoTipoId: record.value.carreraGrupoTipo.id}).then(async (data) => {
                    if(!data.data.statusCode === 201){
                        record.value = {}    
                        recordDialog.value = false;
                        toast.add({severity:'danger', summary: 'Error', detail: 'Registro no creado', life: 3000});
                        return;
                    }            
                    toast.add({severity:'success', summary: 'Correcto', detail: 'Registro creado', life: 3000}); 
                    record.value = {}    
                    recordDialog.value = false  
                    getList()
                });             
            }
        }
    }
   

};

</script>

