import http from "@/http-common";
class StudyRegimenDataService {
    getAll(){
      return http.get("/intervalo-gestion-tipo/itt");
    }

    getRegimeDegree(name)
    {
      return http.get(`/regimen-grado-tipo/${name}`)
    }

    getSubjets(plan_estudio_carrera_id,regimen_grado_tipo_id)
    {
      return http.get(`/plan-estudio-asignatura/plan-regimen/${plan_estudio_carrera_id}/${regimen_grado_tipo_id}`) 
    }

    getStudyPlans()
    {
      return http.get('/plan-estudio-resolucion/lista-carreras')
    }
    // getInstitutionAreas(id){
    //   return http.get(`/jurisdiccion-geografica/2001/codigo/${id}`);
    // }

    // get(id) {
    //   return http.get(`/jurisdiccion-geografica/2001/codigo/${id}`);
    // }
  
  //   create(data: any): Promise<any> {
  //     return http.post("/users", data);
  //   }
  
  //   update(id: any, data: any): Promise<any> {
  //     return http.put(`/users/${id}`, data);
  //   }
  
  //   delete(id: any): Promise<any> {
  //     return http.delete(`/users/${id}`);
  //   }
  
  //   deleteAll(): Promise<any> {
  //     return http.delete(`/users`);
  //   }
  
  //   findByTitle(title: string): Promise<any> {
  //     return http.get(`/users?title=${title}`);
  //   }
  }
  
  export default new StudyRegimenDataService();