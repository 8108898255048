<template>
   
    <div class="flex justify-content-between  align-items-center pb-1">
        <span class="p-input-icon-left pr-2">
            <i class="pi pi-search" />
            <InputText v-model="filters['global'].value" placeholder="Buscar" />
        </span>
        <Button type="button" size="small" label="Adicionar Carrera" icon="pi pi-plus" @click="createCareer()" v-if="role.rol !== 'DIRECTOR'" />
    </div>
    <DataTable :value="careers" paginator :rows="5" :rowsPerPageOptions="[5, 10, 20, 50]"  v-model:filters="filters">
        <Column> 
            <!-- <template #body="{ data,index }"> -->
            <template #body="{data}">
                <!-- <button type="button" class="btn btn-primary"> </button> -->
                <div class="btn-group me-2" role="group" aria-label="First group">
                    <Button v-tooltip="'Ver Detalle'" icon="mdi mdi-eye" text raised rounded aria-label="Filter" @click="redirectCareerView(data.carreraAutorizada?.id)" />
                </div>
                <div class="btn-group me-2" role="group" aria-label="First group"  v-if="role.rol !== 'DIRECTOR'">
                    <Button v-tooltip="'Editar'" icon="mdi mdi-pencil" text raised rounded aria-label="Filter" @click="show(data.carreraAutorizada?.id)" />
                </div>
                <div class="btn-group me-2" role="group" aria-label="First group"  v-if="role.rol === 'ADMIN'">
                    <Button v-tooltip="'eliminar'" icon="pi pi-trash" severity="danger" text raised rounded aria-label="Filter" @click="deleteCareer(data.carreraAutorizada?.id)" />
                </div>
            </template>
        </Column>
        <Column field="id" header="ID RESOLUCION" v-if="role.rol === 'ADMIN'"></Column>
        <Column field="carreraAutorizada" header="NOMBRE CARRERA" sortable>
            <template #body="{data}"> {{ data.carreraAutorizada?.carreraTipo?.carrera }} </template>
        </Column>
       
        <Column field="carreraAutorizada" header="ÁREA" sortable>
            <template #body="{data}"> {{ data.carreraAutorizada?.areaTipo?.area }} </template>
        </Column>       
        <Column field="intervaloGestionTipo" header="RÉGIMEN DE ESTUDIO" sortable>
            <template #body="{data}">   
                <div v-if="data.intervaloGestionTipo">
                    <Tag :value="data.intervaloGestionTipo?.intervaloGestion" :severity="data.intervaloGestionTipo?.intervaloGestion === 'AÑO' ? 'warning' : 'success'"></Tag>   
                </div>
            </template> 
        </Column>
        <Column field="nivelAcademicoTipo.nivelAcademico" header="NIVEL DE FORMACIÓN" sortable></Column>
        <Column field="numeroResolucion" header="RESOLUCIÓN" sortable></Column>
      
        
        <Column field="fechaResolucion" header="FECHA DE RESOLUCIÓN" sortable>
            <template #body="{ data }">
                {{ $filters.dateFormat(data.fechaResolucion) }}
            </template>
        </Column>

        
        <Column field="cargaHoraria" header="CARGA HORARIA" sortable></Column>
        <Column field="tiempoEstudio" header="TIEMPO DE ESTUDIOS" sortable></Column>
      
        <!-- <Column field="resuelve" header="TIPO DE RESOLUCIÓN" sortable></Column> -->
        <template #paginatorstart>
                
        </template>
        <template #paginatorend>
               
            <strong> Total Registros {{ careers.length }}</strong>
     
        </template>
        <!-- <Column field="representative.name" header="Tipo de Tramite" style="width: 25%"></Column> -->
    </DataTable>

    <Dialog v-model:visible="dialog" modal :header="title" :style="{ width: '80vw' }">
        <div class="formgrid grid">

            <div class="field col-12 md:col-6">
                    <label>Area de Formación</label>
                    <Dropdown v-model="area" :options="areas"  filter optionLabel="area" class=" p-inputtext-sm w-full" placeholder="Seleccionar Area de Formación" />
            </div>

            <div class="field col-12 md:col-6">
                    <label>Carrera</label>
                    <Dropdown v-model="career_type"  filter :options="career_types" optionLabel="carrera" class=" p-inputtext-sm w-full " placeholder="Seleccionar Carrera" />
            </div>  
            
            <div class="field col-12 md:col-4">
                    <label>Tipo de Resolución</label>
                    <Dropdown v-model="resolution_type" :options="resolution_types" optionLabel="resolucionTipo" class=" p-inputtext-sm w-full" placeholder="Seleccionar Tipo" />
            </div>

            <div class="field col-12 md:col-4">
                    <label>Resolucion Ministerial</label>
                    <InputMask id="basic" class="p-inputtext-sm w-full" v-model="career.numero_resolucion" mask="9999/9999" placeholder="0000/yyyy" />
            </div>
            
            <div class="field col-12 md:col-4">
                    <label>Fecha de Resolución</label>
                    <Calendar class="p-inputtext-sm w-full" v-model="career.fecha_resolucion"  dateFormat="dd/mm/yy"/>
                    
            </div>

            <div class="field col-12 md:col-4">
                    <label>Nivel de Formación </label>
                    <Dropdown v-model="academic_level" :options="academic_levels" @change="reloadTimes()" optionLabel="nivelAcademico" class=" p-inputtext-sm w-full" placeholder="Seleccionar Nivel de Formacion" />
            </div>  

            <div class="field col-12 md:col-4">
                    <label>Regimen de Estudio</label>
                    <Dropdown v-model="study_regimen" :options="study_regimens" @change="reloadTimes()" optionLabel="intervaloGestion" class=" p-inputtext-sm w-full" placeholder="Seleccionar Regimen" />
            </div>  

         
            <div class="field col-12 md:col-4">
                    <label>Tiempo Estudio</label>
                    <InputNumber id="number-input" class="p-inputtext-sm w-full" v-model="study_time"  :disabled="true"  />
                    <!-- <Dropdown v-model="study_time" :options="study_times"  optionLabel="id" class=" p-inputtext-sm w-full" placeholder="Seleccionar Tiempo" /> -->
            </div>  

            <div class="field col-12 md:col-4">
                    <label>Carga Horaria</label>
                    <InputNumber id="number-input" class="p-inputtext-sm w-full" v-model="career.carga_horaria"  />
            </div>
            <!-- <div class="field col-12 md:col-4">
                    <label>Descripción</label>
                    <InputText type="text" class="p-inputtext-sm w-full" v-model="career.descripcion" />
            </div> 

            <div class="field col-12 md:col-4">
                    <label>Observación</label>
                    <InputText type="text" class="p-inputtext-sm w-full" v-model="career.resuelve" />
            </div>   -->
            
        </div>
        <template #footer>
            <Button label="Cancelar" icon="pi pi-times" @click="dialog = false" text />
            <Button label="Guardar" icon="pi pi-check" @click="saveCareer()" autofocus  :disabled="bunton_disabled" />
        </template>
    </Dialog>

    <Dialog v-model:visible="dialog_edit" modal header="Historial de Carrera " :style="{ width: '80vw' }">
        <div class="formgrid grid">
            <div class="field col-12 md:col-5">
                    <label>Area de Formación</label>
                    <Dropdown v-model="form.areaTipo" :options="areas" optionLabel="area" class=" p-inputtext-sm w-full" placeholder="Seleccionar Area de Formación" disabled="true" />
            </div>

            <div class="field col-12 md:col-5">
                    <label>Carrera</label>
                    <Dropdown v-model="form.carreraTipo"  filter :options="career_types" optionLabel="carrera" class=" p-inputtext-sm w-full " placeholder="Seleccionar Carrera" disabled="true" />
            </div>  
            
            <!-- <div class="col-12 md:col-2 flex align-items-center justify-content-center">
                <Button label="Guardar" icon="pi pi-check" @click="saveCareer()" autofocus  :disabled="bunton_disabled" />
            </div> -->
            <div class="flex align-items-center">
                <Button label="Adicionar Resolucion" severity="success" icon="pi pi-plus" @click="addResolution()" autofocus  :disabled="bunton_disabled" />
            </div>
            <table class="table">
                <thead>
                    <th> </th>
                    <th v-if="role.rol === 'ADMIN'"> ID </th>
                    <th>Tipo</th>
                    <th>N° Resolucion</th>
                    <th>Fecha Resolucion</th>
                    <th>Nivel Academico</th>
                    <th>Intervalo Gesion Tipo</th>
                    <th>Tiempo Estudio</th>
                    <th>Carga horaria</th>
                    <th></th>
                   
                </thead>
                <tbody>
                    <tr v-for="(resolution, index) in form.resoluciones" :key="index">
                        
                        <td v-if="!resolution.is_edited" class="flex  align-items-center"> 
                            <Button icon="pi pi-pencil" severity="info" text rounded aria-label="Editar" @click="resolution.is_edited = true"  v-if="role.rol !== 'DIRECTOR'"  />
                            <Button icon="pi pi-trash" severity="danger" text rounded aria-label="Eliminar" @click="deleteResolutionCareer(resolution)"  v-if="role.rol !== 'DIRECTOR' && resolution.ultimo === false"/>
                        </td>
                        <td v-else class="flex align-items-center">
                            <Button icon="pi pi-save" severity="success" text rounded aria-label="Guardar" @click="editResolutionCareer(resolution)"   v-if="role.rol !== 'DIRECTOR'" />
                            <Button icon="pi pi-times" severity="danger" text rounded aria-label="Eliminar"  @click="removeResolution(resolution, index)"  v-if="role.rol !== 'DIRECTOR'" />
                        </td>
                        <td v-if="role.rol === 'ADMIN'"> {{ resolution.id }} </td>
                        <td v-if="!resolution.is_edited"> {{ resolution?.resolucionTipo.resolucionTipo }}</td>
                        <td v-else> <Dropdown v-model=" resolution.resolucionTipo" :options="resolution_list" optionLabel="resolucionTipo" class=" p-inputtext-sm" placeholder="Seleccionar Tipo" /> </td>

                        <td v-if="!resolution.is_edited" > {{ resolution.numeroResolucion }}</td>
                        <td v-else>  <InputText type="text" class="p-inputtext-sm " v-model="resolution.numeroResolucion" />  </td>

                        <td v-if="!resolution.is_edited"> {{ $filters.dateFormat(resolution.fechaResolucion) }} </td>
                        <td v-else> <Calendar class="p-inputtext-sm w-full" v-model="resolution.fechaResolucion" dateFormat="dd/mm/yy" />  </td>

                        <td v-if="!resolution.is_edited"> {{ resolution?.nivelAcademicoTipo.nivelAcademico }}</td>
                        <td v-else> <Dropdown v-model="resolution.nivelAcademicoTipo" :options="academic_levels" optionLabel="nivelAcademico" class=" p-inputtext-sm w-full" placeholder="Seleccionar Nivel de Formacion" />  </td>

                        <td v-if="!resolution.is_edited"> {{ resolution?.intervaloGestionTipo.intervaloGestion}}</td>
                        <td v-else>  <Dropdown v-model="resolution.intervaloGestionTipo" :options="study_regimens" optionLabel="intervaloGestion" class=" p-inputtext-sm w-full" placeholder="Seleccionar Regimen" /></td>
                        
                        <td v-if="!resolution.is_edited"> {{ resolution?.tiempoEstudio }}</td>
                        <td v-else> <InputText type="text" class="p-inputtext-sm " v-model="resolution.tiempoEstudio" /> </td>

                        <td v-if="!resolution.is_edited"> {{ resolution?.cargaHoraria  }}</td>
                        <td v-else> <InputText type="text" class="p-inputtext-sm " v-model="resolution.cargaHoraria" /> </td>


                        <td v-if="!resolution.is_edited">
                            <Tag  severity="success" value="Carrera Habilitada"   v-if="resolution.ultimo"></Tag>
                             <!-- <InputSwitch v-model="resolution.ultimo" /> -->
                         </td>
                        <td v-else></td>

                      
                    </tr>
                </tbody>
            </table>
           
            
        </div>
        <!-- <template #footer>
            <Button label="Cancelar" icon="pi pi-times" @click="dialog = false" text />
            <Button label="Guardar" icon="pi pi-check" @click="saveCareer()" autofocus  :disabled="bunton_disabled" />
        </template> -->
    </Dialog>
</template>
<script >
import InstitutionDataService from '@/services/InstitutionDataService'
import AreaDataService from '@/services/AreaDataService'
import CareerTypeDataService from '@/services/CareerTypeDataService'
import TrainingLevelDataService from '@/services/TrainingLevelDataService'
import StudyRegimenDataService from '@/services/StudyRegimenDataService'
import StudyTimeDataService from '@/services/StudyTimeDataService'
import ResolutionTypeDataService from '@/services/ResolutionTypeService'
import CareerDataService from '@/services/CareerDataServie'
import { FilterMatchMode } from 'primevue/api';


export default{
    name: 'CareerInsitution',
    props: {
        iu_id: Number,
        sucursal_id: Number,
    },
    data: ()=>({
        careers:[],
        dialog: false,
        dialog_edit: false,
        career:{},
        form: {},
        career_types:[],
        career_types_all:[],
        career_type:{},
        areas: [],
        area:{},
        academic_levels:[],
        academic_level:{},
        study_regimens: [],
        study_regimen:{},
        study_times:[],
        study_time: null,
        resolution_types: [],
        resolution_list: [],
        resolution_type: {},
        bunton_disabled: false,
        institution:{},
        filters:{
                  global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                  carrera: { value: null, matchMode: FilterMatchMode.CONTAINS },
                  area: { value: null, matchMode: FilterMatchMode.CONTAINS },
                  nivel_academico: { value: null, matchMode: FilterMatchMode.CONTAINS },
                
        }
    }),
    mounted(){
        console.log("obteniendo Carreras")
        this.getInstitution()
        this.retrieveCareers()
        this.retrieveAreas()
        this.retrieveCareerTypes()
        this.retrieveTrainingLevel()
        // console.log(this.academic_level);
        this.retrieveStudyRegimens()
        this.retrieveResolutionTypes()

        
    },
    methods:{
        format_date(value){
            if (value) {
            console.log('-->',value.substring(0, 10));
            return value.substring(0, 10)
            }
        },
        createCareer()
        {
            this.dialog = true
            this.career = {}
        },
        async show(carrera_autorizada_id)
        {
            // console.log(carrera_autorizada_id)
           let response =  await CareerDataService.show(carrera_autorizada_id)
           this.form = response.data
           this.dialog_edit =  true
           
           this.form.resoluciones.forEach(element => {
            element.is_edited = false
            element.fechaResolucion = new Date(element.fechaResolucion)
            return element
           });
           console.log(this.form)
        },
        getInstitution()
        {
            InstitutionDataService.getInfo(this.$route.params.id)
            .then((response)=>{
                this.institution = response.data
                // console.log(response.data)
            })
        },
        retrieveCareers()
        {
            InstitutionDataService.getCareerListInstitute(this.$route.params.id)
                .then((response) => {
                    console.log("lista de carreras");
                    console.log(response.data);
                    this.careers = response.data;
                })
                .catch((e) => {
                    console.log(e);
                }); 
        },
        retrieveAreas(){
            AreaDataService.getAll()
                .then((response)=>{
                    this.areas = response.data.data
                    // console.log(response.data)
                })
                .catch((e)=>{
                    console.log(e)
                })
        },
        retrieveCareerTypes()
        {
            CareerTypeDataService.getAllCareer()
                .then((response)=>{
                    this.career_types = response.data
                    // console.log('career types',response.data)
                })
        },
        
        retrieveTrainingLevel()
        {
            TrainingLevelDataService.getAll()
                    .then((response)=>{
                    this.academic_levels = response.data
                    
                    // console.log(response.data)
                })
        },
        retrieveStudyRegimens()
        {
            StudyRegimenDataService.getAll()
                .then((response)=>{
                    this.study_regimens = response.data
                    // console.log(response.data)
                })
        },
        retrieveResolutionTypes()
        {
            ResolutionTypeDataService.getAll()
                .then((response)=>{
                    // this.study_regimens = response.data
                    this.resolution_types = response.data
                    console.log(response.data)
                })
            ResolutionTypeDataService.getList()
                .then((response) =>{
                    this.resolution_list = response.data
                } )
            
        },
        reloadTimes()
        {
            console.log("procesando cambios")
            if(Object.hasOwn(this.academic_level, 'id') && Object.hasOwn(this.study_regimen, 'id') )
            {
                console.log("academic ", this.academic_level)
                console.log("regimen estudio", this.study_regimen)
                if(this.academic_level.nivelAcademico === 'TECNICO MEDIO' && this.study_regimen.intervaloGestion === 'SEMESTRE')
                {
                    this.study_time = 4
                }

                if(this.academic_level.nivelAcademico === 'TECNICO MEDIO' && this.study_regimen.intervaloGestion === 'AÑO')
                {
                    this.study_time = 2
                }

                if(this.academic_level.nivelAcademico === 'TECNICO SUPERIOR' && this.study_regimen.intervaloGestion === 'SEMESTRE')
                {
                    this.study_time = 6
                }

                if(this.academic_level.nivelAcademico === 'TECNICO SUPERIOR' && this.study_regimen.intervaloGestion === 'AÑO')
                {
                    this.study_time = 3
                }

                // StudyTimeDataService.getTimes(this.academic_level.id,this.study_regimen.id)
                //     .then((response)=>{
                //         let array_times = []
                //         //para todos los casos de posibles convinaciones siempre debe votar un array unidimencional 
                //         response.data.forEach(element => {
                //             array_times.push({id:element})
                //         });
                //         this.study_times = array_times
                //         console.log(this.study_times)
                //     })
            }
            // console.log(Object.hasOwn(this.training_level, 'id'))
            // console.log(Object.hasOwn(this.study_regimen, 'id'))
        },
        redirectCareerView(id)
        {
            this.$router.push(`/career/${id}`)
        },
        dateToDMY(date) {
            var d = date.getDate();
            var m = date.getMonth() + 1; //Month from 0 to 11
            var y = date.getFullYear();
            return '' + (d <= 9 ? '0' + d : d) + '/' + (m<=9 ? '0' + m : m) + '/' + y;
        },

        formatFecha(value) {
            return va.toLocaleDateString('es-BO', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric' 
        });
        },

        saveCareer()
        {
            let new_career = {
                "sucursal_id":this.institution.sucursal_id ,
                "carrera_tipo_id": this.career_type.id,
                "area_tipo_id": this.area.id,
                "resolucion_tipo_id": this.resolution_type.id,
                "numero_resolucion": this.career.numero_resolucion,
                "fecha_resolucion": this.dateToDMY(this.career.fecha_resolucion) ,
                "descripcion": this.career.descripcion??'',
                "resuelve": this.career.resuelve,
                "carga_horaria": parseInt( this.career.carga_horaria),
                "tiempo_estudio": parseInt(this.study_time) ,
                "intervalo_gestion_tipo_id": this.study_regimen.id,
                "nivel_academico_tipo_id": this.academic_level.id
            }
            this.bunton_disabled = true
            CareerDataService.create(new_career)
                .then((response)=>{
                    if(response.data.statusCode ==201)
                    {
                        this.$toast.add({ severity: 'success', summary: 'Success Message', detail: 'Registro completado', life: 3000 })
                            // this.$router.push('/institutions')  
                            this.retrieveCareers()
                            this.dialog =false
                            this.bunton_disabled = false
                        // console.log(response.data)
                    }
                    

                })
            console.log(new_career)

        },
        addResolution(){
            this.form.resoluciones.push({is_edited:true,
                                        numeroResolucion:'',
                                        resolucionTipo: {},
                                        nivelAcademicoTipo: {},
                                        intervaloGestionTipo: {},
                                        tiempoEstudio: '',
                                        cargaHoraria: '',
                                        fechaResolucion: new Date(),})
        },
        removeResolution(item,index){
            if(!item.id)
            {
                this.form.resoluciones.splice(index,1)
            }else
            {
                item.is_edited= false
            }

        },
        async editResolutionCareer(resolucion)
        {
            console.log('edit resolution',resolucion)
            let payload = {  
                numeroResolucion: resolucion.numeroResolucion+'',
                fechaResolucion: resolucion.fechaResolucion,
                resolucionTipoId: resolucion.resolucionTipo.id ,
                nivelAcademicoTipoId: resolucion.nivelAcademicoTipo.id,
                intervaloGestionTipoId: resolucion.intervaloGestionTipo.id,
                tiempoEstudio: parseInt(resolucion.tiempoEstudio) ,
                cargaHoraria: parseInt(resolucion.cargaHoraria),
                ultimo: resolucion.ultimo ?? false,
            }
            if(resolucion.id)
            {
                let response = await CareerDataService.editCareerResolution(resolucion.id, payload)
                console.log(response.data)
            }else{
                payload.carreraAutorizadaId = this.form.id
                let response = await CareerDataService.createCareerResolution(payload)
                console.log(response.data)
            }

           let response =  await CareerDataService.show(this.form.id)
           this.form = response.data
           this.dialog_edit =  false
           this.$toast.add({ severity: 'success', summary: 'Success Message', detail: 'Registro completado', life: 3000 }) 
            

            
        },
        async deleteResolutionCareer(resolucion)
        {
            this.$confirm.require({
                message: 'Esta seguro de eliminar la resolucion de la carrera?',
                header: 'Confirmacion',
                icon: 'pi pi-exclamation-triangle',
                rejectClass: 'p-button-secondary p-button-outlined',
                rejectLabel: 'NO',
                acceptLabel: 'SI',
                accept: async () => {
                    let response = await CareerDataService.deleteCareerResolution(resolucion.id)
                    console.log(response.data)
                    this.dialog_edit =  false
                    this.$toast.add({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted', life: 3000 });
                },
                reject: () => {
                    // this.$toast.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
                }
            });
        },
        async deleteCareer(id)
        {
            this.$confirm.require({
                message: 'Esta seguro de eliminar la Carrera?',
                header: 'Confirmacion',
                icon: 'pi pi-exclamation-triangle',
                rejectClass: 'p-button-secondary p-button-outlined',
                rejectLabel: 'NO',
                acceptLabel: 'SI',
                accept: async () => {
                    let response = await CareerDataService.delete(id)
                    console.log(response.data)
                    this.retrieveCareers()
                    this.dialog_edit =  false
                    this.$toast.add({ severity: 'success', summary: 'Info', detail: 'Se elimino la carrera', life: 3000 });
                },
                reject: () => {
                    // this.$toast.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
                }
            });
        }



    },
    computed: {
        institution_id()
        {
            return this.iu_id
        },
        title ()
        {
            return "Adicionar Carrera"
        },
        role()
        {
            return this.$store.state.auth.rol
        }

    }
}

</script>