<template>
	<Toast position="bottom-right" />
	<div class="limiter">
		<div class="container-login100">
			<!-- <img class="wimage-login" src="/books.png" alt="a"> -->
			<div class="wrap-login100">
				<div class="login-logo">
					<div class="login100-pic js-tilt" data-tilt data-tilt-scale="1.1" ref="tiltRef">
						<img src="/logomin2.jpg" alt="IMG">
					</div>
				</div>
				<div class="login-content">
					<div class="login100-form validate-form">
						<span class="login100-form-title">
							RITT Académico
						</span>
						<div class="owl">
								
								<div class="academic"></div>
                                <div :class="owl_hide?'hand password':'hand'"></div>
                                <div :class="owl_hide?'hand hand-r password':'hand hand-r'"></div>
                                <div class="arms">
                                    <div :class="owl_hide?'arm password':'arm'"></div>
                                    <div :class="owl_hide?'arm arm-r password':'arm arm-r'"></div>
                                </div>
                        </div>

						<div class="wrap-input100 validate-input" data-validate="Valid email is required: ex@abc.xyz">
							<input class="input100" type="text" name="user" placeholder="Usuario" v-model="username">
							<span class="focus-input100"></span>
							<span class="symbol-input100">
								<i class='bx bxs-user' aria-hidden="true"></i>
							</span>
						</div>
	
						<div class="wrap-input100 validate-input" data-validate="Password is required">
							<input class="input100" type="password" name="pass" placeholder="Password" v-model="password" @focus="owl_hide = true"  @blur="owl_hide = false"  @keyup.enter="getValidate()">
							<span class="focus-input100"></span>
							<span class="symbol-input100">
								<i class='bx bxs-lock-alt' aria-hidden="true" ></i>
							</span>
						</div>
	
						<div class="container-login100-form-btn">
							<button type="button" class="login100-form-btn" @click="getValidate()">
								Iniciar Sesión
							</button>
						</div>
	
						<div class="text-center pt-5">
							<span class="txt1">
								
							</span>
							<a class="txt1" href="#">
								Formulario de Registro de Instituto
							</a>
						</div>
	
						<div class="text-center pt-2">
							<a class="txt2" href="#">
								Seguimiento de Trámite
								<i class="fa fa-long-arrow-right m-l-5" aria-hidden="true"></i>
							</a>
						</div>
					
					</div>
					
				</div>
			</div>
		</div>
	</div>
</template>
<!-- <script type="text/javascript" src="vanilla-tilt.js"></script> -->
<script>
import '@/scss/login.scss';
import VanillaTilt from 'vanilla-tilt';
// import AuthDataService from '@/services/AuthDataService';

export default {
	name: 'Login',
	data: () => ({
		username: "",
		password: "",
		owl_hide: false,
	}),
	mounted() {
		VanillaTilt.init(this.$refs.tiltRef)
	},
	computed: {
		loggedIn() {
			return this.$store.state.auth.status.loggedIn;
		},
	},
	methods:
	{
		getValidate() {
			console.log("Validando datos")
			this.handleLogin()
		},

		handleLogin() {
			this.loading = true;

			this.$store.dispatch("auth/login", { username: this.username, password: this.password })
				.then(
					(data) => {
						// console.log(data)
						if(data.statusCode==200)
						{
							// console.log(data)
							let selectedRol = null
							
							let selectedInstitution = null

							data.institutos.forEach(institution => {
								selectedInstitution = institution
							});

							if(selectedInstitution)
							{

								this.$store.dispatch('auth/changeInstitution', selectedInstitution);
								selectedInstitution.roles.forEach(rol => {
									selectedRol = rol
								});
							}



							if(selectedRol){
								 this.$store.dispatch('auth/changeRol', selectedRol);
							}

							this.$router.push("/");

							
						}else{
							this.$toast.add({ severity: 'info', summary: 'Autentificacion', detail: 'Usuario y/o password incorrectos', life: 3000 });
						}
							
					},
					(error) => {

						// solucion temporal revisar metodos de autentificacion
						this.$toast.add({ severity: 'info', summary: 'Autentificacion', detail: 'Usuario y/o password incorrectos', life: 3000 });
						this.loading = false;
						this.message =
							(error.response &&
								error.response.data &&
								error.response.data.message) ||
							error.message ||
							error.toString();
						console.log(error)
					}
				);
		},

		// login(){
		// 	AuthDataService.loging({username:this.username,password:this.password})
		// 		.then((response)=>{
		// 			if(response.data.statusCode===200)
		// 			{
		// 				console.log("loguear")
		// 			}else{
		// 				console.log('not login')
		// 			}
		// 			console.log(response.data)
		// 		})
		// }
	}
}
</script>
