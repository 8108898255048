import http from "@/http-common";
class TerritoryTypeDataSerevice {
    // getAll(){
    //   return http.get("/carrera-tipo/carreras");
    // }

    getIssued(){    
      return http.get(`/user/getAllExpedidoTipo`);
    }

    getCountry(){    
      return http.get(`/user/getAllPaisTipo`);
    }

    getDepartment(country){    
      return http.get(`/user/getAllDeptoTipo/${country}`);
    }

    getProvince(department){    
      return http.get(`/user/getAllProvByDeptoId/${department}`);
    }

    getMunicipality(province){    
      return http.get(`/user/getAllMuniByProvId/${province}`);
    }

    getCommunity(municipality){    
      return http.get(`/user/getAllComByMunId/${municipality}`);
    }

    getLocality(locality){    
      return http.get(`/user/getAllComByLocalidadId/${locality}`);
    }

    getDepartment2001(country){    
      return http.get(`/user/getAllDepto2001Tipo/${country}`);
    }

    getProvince2001(department){    
      return http.get(`/user/getAllProv2001ByDeptoId/${department}`);
    }

    getMunicipality2001(province){    
      return http.get(`/user/getAllMuni2001ByProvId/${province}`);
    }

    getCanton2001(municipality)
    {
      return http.get(`/user/getAllCanton2001ByProvId/${municipality}`);
    }
    
    getLocality2001(canton){    
      return http.get(`/user/getAllLocal2001ByCantonId/${canton}`);
    }


  }
  
  export default new TerritoryTypeDataSerevice();