<template>
   

      <div class="card">
        
        <div class="card-body">
            <div class="card-title">
            <h1>Estudiantes</h1>
            <div class="position-absolute top-0 end-0">

                <button class="btn btn-primary" type="button" @click="openModal()">
                    <i class="me-3 mdi mdi-account-plus fs-3" aria-hidden="true"></i>
                    Nuevo 
                </button>
                
            </div>
            
        </div>
          <!-- <DataTable :data="rows" class="display">
              <thead>
                  <tr>
                      <th>Nombres</th>
                      <th>Apellidos</th>
                      <th>Unidad Educativa</th>
                      <th>Edad</th>
                  </tr>
              </thead>
          </DataTable> -->
          <DataTable :value="students" tableStyle="min-width: 50rem">
              <Column field="names" header="Nombres"></Column>
              <Column field="lastnames" header="Apellidos"></Column>
              <Column field="unit_educative" header="Unidad Educativa"></Column>
              <Column field="age" header="Edad"></Column>
              <Column style="flex: 0 0 4rem"> 
                  <template #body="{ data,index }">
                      <!-- <button type="button" class="btn btn-primary"> </button> -->
                      <div class="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
                        <div class="btn-group me-2" role="group" aria-label="First group">
                          <button type="button" class="btn btn-outline-info" @click="editStudent(data)" >
                            <i class="pi pi-pencil"></i> 
                          </button>

                        </div>
                        <div class="btn-group me-2" role="group" aria-label="Second group">
                          <button type="button" class="btn btn-outline-danger"  @click="deleteStudent(data,index)">
                            <i class="pi pi-trash"></i>

                          </button>

                        </div>
                      </div>
                      
                  </template>
              </Column>
          </DataTable>
        </div>
      </div>
      
     

      <modal-student :dialog="dialog" @close="close" :student="student"  @student="updateStundent"></modal-student>
 
  </template>

<script >
// import DataTable from 'datatables.net-vue3'
// import Select from 'datatables.net-select';
// import DataTablesCore from 'datatables.net-bs5';
 
// DataTable.use(DataTablesCore);
 
// DataTable.use(Select);
import ModalStudent from '@/components/ModalStudent.vue'


export default {
  name: 'StudenView',
  data:()=>({
    // rows: [
    //   ['David', "Torrez Salinas",'Algun Colegio',105],
    //   ['leyner', "Peñafiel",'Algun Colegio',10],
    // ],
    students:[
      {
        names: 'Leandro David ',
        lastnames: 'Torrez Salinas',
        unit_educative: 'CEDEIN',
        age: '105'
      },
      {
        names: 'Leyner ',
        lastnames: 'peñafiel',
        unit_educative: 'vichito de luz jaja',
        age: '7'
      },

    ],

    dialog: false,
    student: {},

  }),
  mounted(){
    console.log('estudiantess')
    // this.$axios.get('https://httpbin.org/get')
    //       .then(function (response) {
    //       // handle success
    //       console.log(response);
    //     })
    //     .catch(function (error) {
    //       // handle error
    //       console.log(error);
    //     })
    //     .finally(function () {
    //       // always executed
    //     });

  
  },
  methods:{
    openModal(){
      this.dialog = true
      this.student = {}
      console.log('open modal')
    },
    close(){
      this.dialog = false;
    },
    editStudent(data)
    {
      //consumir web service
      this.student = data
      this.dialog = true
      console.log(data)
    },
    updateStundent(student)
    { 
      console.log(student)
      //consumir web service
      // this.rows.push([student.names,student.lastnames,student.unit_educative,student.age]);
      this.students.push(student)
      this.dialog = false
    },
    deleteStudent(data,index)
    {
      //consumir web service
      console.log(data,index)
      this.students.splice(index,1)
    }
  },
  components: {
    // DataTable,
    ModalStudent
  }
}
</script>
<!-- 
<style>

@import 'datatables.net-bs5';
</style> -->