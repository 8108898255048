import http from "@/http-common";
class InscriptionTeacherService {
  getAllBySieYearPeriodCareer(sie,year,per,car,plan){
    return http.get(`/inscripcion/matriculados/${year}/${per}/${car}/${sie}/${plan}`);
  }

  getAllBySieYearPeriodCareerHomologation(sie,year,per,car,plan){
    return http.get(`/inscripcion/matriculados_homologation/${year}/${per}/${car}/${sie}/${plan}`);
  }

  getAllBySieYearPeriodCareerForInscription(sie,year,per,car,plan){
    return http.get(`/inscripcion/matriculados_for_inscription/${year}/${per}/${car}/${sie}/${plan}`);
  }

  getAllByPersonSieCareer(per){
    return http.get(`/persona/getCarrerasByPersonaId/${per}`);    
  }

  getInscriptionTypeHomologation()
  {
    return http.get(`/inscripcion-tipo/homologation-reincorporation`)
  }
  // getAll(){
  //   return http.get("/maestro-inscripcion");
  // }

    // getInstitutionAreas(id){
    //   return http.get(`/jurisdiccion-geografica/2001/codigo/${id}`);
    // }

    // get(id) {
    //   return http.get(`/jurisdiccion-geografica/2001/codigo/${id}`);
    // }
  
  create(data) {
    return http.post("/inscripcion/nuevo", data);
  }

  delete(matricula_id)
  {
    return http.delete(`/inscripcion/${matricula_id}`)
  }

  deleteMatricula(matricula_id)
  {
    return http.delete(`/inscripcion/matricula/${matricula_id}`)
  }
  //transitability

  createTransitability(arrayData){
    return http.post('inscripcion/nuevo-transitabilidad',arrayData)
  }


  checkInscription(aula_id,matricula_id)
  {
    return http.get(`/estudiante-inscripcion/check_inscription/${aula_id}/${matricula_id}`)
  }

  getResolutionsStudent(payload)
  {
    return http.post('/instituto-plan-estudio-carrera/resolutions-student', payload)
  }

  getMatriculaEstudianteHistory(matricula_estudiante_id)
  {
    return http.get(`/matricula-estudiante/historial-matricula-estudiante/${matricula_estudiante_id}`)
  }
  // update(id, data) {
  //   return http.put(`/maestro-inscripcion/`, data);
  // }

  // updateStatus(data) {
  //   return http.put(`/maestro-inscripcion/changeStatusById`, data);
  // }

  // delete(id) {
  //   return http.delete(`/maestro-inscripcion/${id}`);
  // }
  
  //   deleteAll(): Promise<any> {
  //     return http.delete(`/users`);
  //   }
  
  //   findByTitle(title: string): Promise<any> {
  //     return http.get(`/users?title=${title}`);
  //   }
  }
  
  export default new InscriptionTeacherService();