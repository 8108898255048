import http from "@/http-common";
class NoteTypeDataService {
    getAll(){
      return http.get("/nota-tipo/lista-itt");
    }

    // getInstitutionAreas(id){
    //   return http.get(`/jurisdiccion-geografica/2001/codigo/${id}`);
    // }

    // get(id) {
    //   return http.get(`/jurisdiccion-geografica/2001/codigo/${id}`);
    // }
  
    //   create(data: any): Promise<any> {
    //     return http.post("/users", data);
    //   }
    
    //   update(id: any, data: any): Promise<any> {
    //     return http.put(`/users/${id}`, data);
    //   }
    
    //   delete(id: any): Promise<any> {
    //     return http.delete(`/users/${id}`);
    //   }
    
    //   deleteAll(): Promise<any> {
    //     return http.delete(`/users`);
    //   }
    
    //   findByTitle(title: string): Promise<any> {
    //     return http.get(`/users?title=${title}`);
    //   }

  }
  
  export default new NoteTypeDataService();