
import http from "@/http-common";
class CourseDataService {
    // getAll(){
    //   return http.get("/carrera-autorizada/cursos/ie/");
    // }

    getInstitutionCourses(id){
      return http.get(`/carrera-autorizada/cursos/ie/${id}`);
    }

    get(institution_id) {
      return http.get(`/carrera-autorizada/cursos/ie/${institution_id}`);
    }

    getAreas()
    {
        return http.get(`/area-tipo/cursos`);
    }

    getCourses()
    {
        return http.get(`/carrera-tipo/cursos-cortos`);
    }

    getAcademicLevel()
    {
        return http.get(`/nivel-academico-tipo/cursos`);
    }
    
    getRegimeStudy(intervalo_gestion_tipp,curso)
    {
        return http.get(`/intervalo-gestion-tipo/cursos`);
    }
  //   create(data: any): Promise<any> {
  //     return http.post("/users", data);
  //   }
  
  //   update(id: any, data: any): Promise<any> {
  //     return http.put(`/users/${id}`, data);
  //   }
  
  //   delete(id: any): Promise<any> {
  //     return http.delete(`/users/${id}`);
  //   }
  
  //   deleteAll(): Promise<any> {
  //     return http.delete(`/users`);
  //   }
  
  //   findByTitle(title: string): Promise<any> {
  //     return http.get(`/users?title=${title}`);
  //   }
  }
  
  export default new CourseDataService();