<template>
  <div class="home">

    <Card >
        <template #title> Bienvenido al sistema ITT Académico   </template>
        <template #content>
          <div class="flex justify-content-center">
           <!-- <strong>Total: 490</strong>  -->
           
            <!-- <Chart type="doughnut" :data="chartData" :options="chartOptions" class="w-full md:w-30rem" /> -->
          </div>
        </template>
    </Card>
  
    
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import InstitutionDataService from '@/services/InstitutionDataService';
const store = useStore()

const breadcrumbs= ref([{
  name: 'Inicio',
  path: '/',
  active: false
}])

const chartData = ref();
const chartOptions = ref({
    cutout: '60%'
});

const setChartData = () => {
    const documentStyle = getComputedStyle(document.body);
    let labels = [
      'Chuquisaca',
      'La Paz',
      'Cochabamba',
      'Oruro',
      'Potosi',
      'Tarija',
      'Santa Cruz',
      'Beni',
      'Pando'
    ]

    let data = [
      22,
      133,
      85,
      49,
      41,
      38,
      97,
      19,
      6
    ]

    let backgroundColor = ['rgb(238,121,24)', 'rgb(252,191,2)','rgb(94,85,157)', 'rgb(139,47,134)', 'rgb(57,177,115)','rgb(227,29,131)' ,'rgb(34,174,227)','rgb(231,64,58)','rgb(18,151,66)']
    return {
        labels: labels,
        datasets: [
            {
                data: data,
                backgroundColor: backgroundColor,
                hoverBackgroundColor: backgroundColor
            }
        ]
    };
};

const checkAuth = async () =>
{
  let response = await InstitutionDataService.checkAuth()
  console.log(response.data)
}

onMounted( ()=>{
  // console.log('loading breadcrumbs')
  store.dispatch('loadBreadcrumbs',breadcrumbs)
  chartData.value = setChartData();
  checkAuth()
} )



</script>
