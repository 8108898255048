<template>
    <Dialog v-model:visible="dialog" :closable="false" modal header="Migracion de Matriculados" :style="{ width: '50rem' }">
        
        <template #header>
            <div class="flex justify-content-between">
                <div class="">
                    <img src="@/assets/plan_icon3.svg" style="width: 42px;" />
                </div>
                <div class="align-items-center  pl-3">
                    <div > <strong>{{ selectedCareer.carrera }} </strong>  <small class="text-cyan">{{ selectedCareer.nivel_academico }}</small></div>
                    <small class="text-blue-500">Plan de Estudio: <strong> {{ selectedCareer.plan_estudio_resolucion_numero_resolucion }} </strong>  - <Tag :value="selectedCareer.regimen_estudio" :severity="selectedCareer.regimen_estudio === 'AÑO' ? 'warning' : 'success'" class="pb-0 pt-0" /></small>
                </div>
            </div>
        </template>
        <div class="grid">
            <div class="col-12 md:col-12 lg:col-12">
                <div class="flex justify-content-between">

                    <div class="flex flex-column">
                        <span>DE:</span> 
                        <Dropdown v-model="periodo" :options="periodos" optionLabel="periodo" placeholder="Seleccione un perido" class="w-22rem">
                        
                            <template #value="slotProps">
                                <div v-if="slotProps.value" class="flex justify-content-between  align-content-center">   
                                    <div class="flex align-content-center flex-wrap ">
                                        <i class='pi pi-calendar pl-3 pr-3 pt-1'></i>
                                        <span class="font-semibold">{{ `${slotProps.value.periodo} / ${ slotProps.value.gestion_tipo_id}` }}</span> 
                                    </div>
                                    <div class="flex w-10rem justify-content-end pr-2">
                                        <Tag icon="bx bxs-user-detail text-lg" severity="primary" :value="`${slotProps.value.estudiantes ?? ''} / Estudiantes`" class=""></Tag>
                                    
                                    </div>          
                                </div>
                                <span v-else>
                                    {{ slotProps.placeholder }}
                                </span>
                            </template>
                            <template #option="slotProps">
                                <div class="flex  justify-content-between  align-content-center ">
                                    <div class="flex align-content-center flex-wrap ">
                                        <i class='pi pi-calendar pl-3 pr-3 pt-1'></i>
                                        <span class="font-semibold">{{ `${slotProps.option.periodo} / ${slotProps.option.gestion_tipo_id}` }}</span> 
                                    </div>
                                    <div class="flex w-10rem justify-content-end pr-2">
                                        <Tag icon="bx bxs-user-detail text-lg" severity="primary" :value="`${slotProps.option.estudiantes ?? ''} / Estudiantes`" class=""></Tag>
                                    
                                    </div>                                    
                                </div>
                            </template>
                        </Dropdown>
                    </div>
                    <div class="flex flex-column justify-content-center align-items-center"> 
                        <span class="text-white">.</span>
                        <i class=' text-4xl text-blue-500 bx bxs-chevron-right'></i>
                     </div>
                    <div class="flex flex-column"> 
                        <span>A:</span>
                        <div class="border-round-md border-solid border-1 border-primary bg-primary w-22rem h-4rem">
                            <div class="flex justify-content-between  align-content-center  align-items-center flex-wrap h-4rem">
                                <div class="flex align-content-center   flex-wrap ">
                                    <i class='pi pi-calendar pl-3 pr-3 pt-1'></i>
                                    <span class="text-white font-semibold">{{ `${selectedCareer.operativo.periodo} / ${selectedCareer.operativo.gestion}` }}</span> 
                                </div>
                                <div class="flex w-10rem align-items-center justify-content-end pr-2">
                                    <Tag icon="bx bxs-user-detail text-lg" severity="danger" :value="`${students ?? ''} / Estudiantes`" class="bg-white text-black-alpha-90 opacity-50"></Tag> 
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    

                </div>                   

            </div>
        </div>
        <div class="flex justify-content-end gap-2 pt-3">
            <Button type="button" label="Cancelar" severity="secondary"  @click="closeImport"></Button>
            <Button type="button" label="Importar" severity="success"  @click="importMatriculados"></Button>
        </div>
    </Dialog>
</template>
<script setup>
import { ref, onMounted, defineProps, defineEmits, computed  } from 'vue';
import RegistrationStudentService from '@/services/RegistrationStudentService';
const props = defineProps(['dialog','institucion_plan_estudio_carrera','selectedCareer', 'periodos','students' ])
const emit = defineEmits(['closeImport']) 
const dialog = computed( ()=>{
    return props.dialog
})

const periodo = ref()

const institucion_plan_estudio_carrera = computed( () => {
    return props.institucion_plan_estudio_carrera;
})

const selectedCareer = computed( () =>{
    return props.selectedCareer
})

const periodos = computed( ()=>{
    return props.periodos
})

const students = computed( ()=> {
    return props.students
})

const importMatriculados = () => {

    let payload = { 
        institutoPlanEstudioCarreraId: selectedCareer.value.instituto_plan_estudio_carrera_id,
        institucionEducativaId: selectedCareer.value.institucion_educativa_id,
        fromGestionTipoId: periodo.value.gestion_tipo_id,
        fromPeriodoTipoId: periodo.value.periodo_tipo_id,
        toGestionTipoId: selectedCareer.value.operativo.gestion_tipo_id,
        toPeriodoTipoId:  selectedCareer.value.operativo.periodo_tipo_id
    }
    console.log('payload',payload)

    RegistrationStudentService.importMatriculados(payload).then(response =>{
        console.log(response.data)
        closeImport() 
    } )
}

const closeImport = () =>{
    console.log('emited closeimport')
    emit('closeImport',false)
}

</script>