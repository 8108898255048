<template>
     <Toast position="bottom-right" />
    <div class="grid">
        <div class="col-12">
            <Card>
                <template #title>
                    <div class="flex justify-content-between w-full">
                        <div class="flex flex-column">
                            <div > <strong>CURSO:</strong> <span >{{ course.nombre }}</span> </div>
                            <!--span class="text-sm text-500"> {{ course.instituto?.tipo_artistica }} </span-->
                        </div>
                        <div class="flex justify-content-end">
                            <div class="flex align-items-start">
                                <div class="btn-group pr-2" v-if="operativo" >        
                                    <button type="button" :class="`btn btn-primary dropdown-toggle `" data-bs-toggle="dropdown" aria-expanded="false"
                                        v-tooltip.lefts="{ value: `<h5 class='text-white'>Gestion: ${operativo.gestion} </h5><h5 class='text-white'>Evento:${operativo.evento} </h5> `, escape: true, class: 'custom-error' }" 
                                    >
                                        <div class="kdropdown-button">
                                            <div class="kicon" >
                                                <i class="duotone icon-pencil" v-if="operativo.id === operativo_edit.id"></i>
                                                <i class="duotone icon-eye-open" v-else></i>
                                            </div>
                                            <div class="kcontent">
                                                <strong style="font-size: 10px;"> {{ operativo.evento }} - {{ operativo.gestion }} </strong>
                                                <strong v-if="operativo.modalidad_id>0"> {{ operativo.modalidad }} </strong> 
                                                <strong v-else> Periodo: {{ operativo.periodo }}</strong>
                                            </div>
                                        </div>                  
                                    </button>
                                    <ul class="dropdown-menu dropdown-menu-end dropdown-menu-lg-start">
                                        <li v-for="(periodo, index ) in periodos_ademicos" :key="index">
                                            <div class="dropdown-item kitem-list " @click="setOperativo(periodo)"> 
                                            
                                                <div class="kicon">
                                                    
                                                    <i class="duotone icon-pencil" v-if="periodo.id === operativo_edit.id"></i>
                                                    <i class="duotone icon-eye-open" v-else></i>
                                                </div>
                                                <div class="kcontent">
                                                    <span>{{ periodo.evento }} - {{ periodo.gestion }} </span>
                                                    <span v-if="periodo.modalidad_id>0"> {{ periodo.modalidad }}</span>
                                                    <span v-else>Periodo: {{ periodo.periodo }} </span>
                                                </div>
                                                
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <Button icon="pi pi-arrow-left" severity="secondary" label="" @click="$router.go(-1)" />
                            </div> 
                        </div>
                    </div>
                   
                    
                </template>
                <template #content>
                    <div class="grid">
                        <div class="col flex flex-column">
                            <div > <strong>ÁREA:</strong> <span >{{ course.area?.nombre }}</span> </div>
                            <div > <strong>DISCIPLINA:</strong> <span > {{ course.disciplina?.nombre  }}</span> </div>
                        </div>
                    </div>
                    <div class="col-12 text-right">
                        <Button label="Adicionar Estudiante" icon="pi pi-user-plus" severity="success" @click="openNew" class="p-button-success" />
                    </div>
                    <div class="col-12 text-center mt-4">
                        <h3><strong>TOTAL DE CLASES DEFINIDAS</strong></h3>
                    </div>
                    <Panel header="Estudiantes">
                        <template #header>
                            <div class="flex justify-content-between w-full">
                                <div class="flex flex-column">
                                    <span :class="`text-normal font-semibold ${docente.bajaTipoId===0?'text-blue-500':'text-gray-500'}`" v-for="(docente, index_docente) in classroom?.aulasDocentes" :key="index_docente"> DOCENTE: {{ `${docente.maestroInscripcion.persona.nombre ?? ''} ${docente.maestroInscripcion.persona.paterno ?? ''} ${docente.maestroInscripcion.persona.materno ?? ''}` }}  <Tag severity="warning" value="Info" rounded v-if="docente.bajaTipoId === 3 "> deshabilitado </Tag>  </span>
                                    <span class="text-normal font-semibold text-blue-500" > PARALELO: {{  paralelo[0]?.nombre_paralelo}}  <Tag severity="success" value="Info" rounded> {{ classroom?.turnoTipo?.turno ?? 'TARDE' }} </Tag>   </span> 
                                    <span class="text-green-400 font-semibold" > APROBADOS: {{ this.aprobados }} </span>
                                    <span class="text-red-400 font-semibold" > REPROBADOS: {{ this.reprobados }} </span>
                                    <span class="text-yellow-400 font-semibold" > NO SE PRESENTO: {{ this.no_asistidos }} </span>
                                </div>
                                <div class="flex align-items-center">
                                    <router-link  v-tooltip.left="{value: 'Registro de Notas'}" class="icon-button" :to="`/record_academic/${this.$route.params.aula_id}/${this.$route.params.career_id}/0`" target="_blank"    >
                                        <div class="kicon-button-danger" >
                                            <i class='bx bxs-file-pdf'></i>
                                        </div>
                                    </router-link>     
                                    <strong>TOTAL ESTUDIANTES : {{ estudiantes.length }}    </strong>

                                </div>
                            </div>
                        </template>
                        <table class="table">
                            <thead>
                                <th>N°</th>
                                <th>C.I.</th>
                                <th>Apellido Paterno</th>
                                <th>Apellido Materno</th>
                                <th>Nombres</th>
                                <th>Nro. Asistencias a Clases</th>
                                <th>Estado</th>
                                <th>Observaciones</th>
                            </thead>
                            <tbody>
                                
                                    <tr v-for="(estudiante,index) in estudiantes" :key="index">
                                        <td>{{ index+1 }}</td>
                                        <td>{{ estudiante.persona.carnetIdentidad }}</td>
                                        <td>{{ estudiante.persona.paterno }}</td>
                                        <td>{{ estudiante.persona.materno }}</td>
                                        <td>{{ estudiante.persona.nombre }}</td>
                                        <td>{{ estudiante.nro_asistencia }}</td>
                                        <td>{{ estudiante.estado }}</td>
                                        <td>{{ estudiante.observaciones }}</td>
                                    
                                    </tr>
                                
                            </tbody>
                        </table>
                    </Panel>

                </template>
              
            </Card>
        </div>
    </div>
    <Dialog v-model:visible="recordDialog" header="Registrar Estudiantes" :modal="true" class="p-fluid" maximizable :style="{ width: '900px' }" responsive="true">
  <template #header>
    <h4 class="p-input-icon-left">Registrar Estudiante</h4>
  </template>

  <!-- Sección para la búsqueda -->
  <div class="p-fluid formgrid grid" >                                 
    <div class="field col-12">
      <form @submit.prevent="onSubmitSearch" class="flex flex-column gap-2">
        <div class="p-inputgroup">
          <InputText id="identityCard" v-model="formSearch.identityCard" placeholder="Número C.I." />
          <i class="mdi mdi-minus" aria-hidden="true" style="display: flex; align-items: center;"></i>
          <InputText id="complement" v-model="formSearch.complement" placeholder="Complemento C.I."/>
          <Button label="Buscar" type="submit" :loading="loadingSubmit" />
        </div>
    </form> 
    </div>
  </div>

  <!-- Sección del formulario si no está en búsqueda -->
  <div class="p-fluid" >
    <!-- Datos personales -->
    <div class="formgrid grid">
      <!-- Cédula de Identidad -->
      <div class="field col-12 md:col-4">
        <label for="identityCard">Cédula de Identidad</label>
        <InputText id="identityCard" v-model="registro.carnet_identidad" :disabled="isIdentityCardDisabled" :useGrouping="false" placeholder="Ingrese Cédula de Identidad" />
      </div>

      <!-- Complemento -->
      <div class="field col-12 md:col-2">
        <label for="complemento">Complemento</label>
        <InputText id="complemento" v-model="registro.complemento" :disabled="isComplementoDisabled" placeholder="Complemento C.I." />
      </div>

      <!-- Tipo de C.I. -->
      <div class="field col-12 md:col-3">
        <label for="cedula_tipo_id">Tipo de C.I.</label>
        <div class="flex flex-wrap gap-3">
          <div class="flex align-items-center">
            <RadioButton v-model="registro.cedula_tipo_id" :disabled="isCedulaTipoIdDisabled" inputId="identityCardTypeN" name="cedula_tipo_id" value="1" />
            <label for="identityCardTypeN" class="ml-2">Nacional</label>
          </div>
          <div class="flex align-items-center">
            <RadioButton v-model="registro.cedula_tipo_id" :disabled="isCedulaTipoIdDisabled" inputId="identityCardTypeE" name="cedula_tipo_id" value="2" />
            <label for="identityCardTypeE" class="ml-2">Extranjero</label>
          </div>
        </div>
      </div>

      <!-- Expedido -->
      <div class="field col-12 md:col-3">
        <label for="issued">Expedido</label>
        <AutoComplete v-model="registro.nacimiento_localidad" :disabled="isIssuedDisabled" id="issued" optionLabel="lugar" :suggestions="filteredIssued" @complete="searchIssued" dropdown forceSelection />
      </div>

      <!-- Fecha de Nacimiento -->
      <div class="field col-12 md:col-3">
        <label for="birthdate">Fecha de Nacimiento</label>
        <Calendar id="birthdate" v-model="registro.fecha_nacimiento" :disabled="isBirthdateDisabled" dateFormat="dd/mm/yy" />
      </div>

      <!-- Nombre(s) -->
      <div class="field col-12 md:col-4">
        <label for="name">Nombre(s)</label>
        <InputText id="name" v-model="registro.nombre" :disabled="isNameDisabled" placeholder="Nombre" />
      </div>

      <!-- Apellido Paterno -->
      <div class="field col-12 md:col-4">
        <label for="firstLastName">Apellido Paterno</label>
        <InputText id="firstLastName" v-model="registro.paterno" :disabled="isFirstLastNameDisabled" placeholder="Apellido Paterno" />
      </div>

      <!-- Apellido Materno -->
      <div class="field col-12 md:col-4">
        <label for="secondLastName">Apellido Materno</label>
        <InputText id="secondLastName" v-model="registro.materno" :disabled="isSecondLastNameDisabled" placeholder="Apellido Materno" />
      </div>
    </div>
  </div>

    <!-- Botones del modal -->
    <template #footer>
        <div>
        <!--div class="text-left">
            <Button icon="pi pi-search" label="Nueva búsqueda" @click="search = !search" />
        </div-->
        <div class="button-container">
            <Button pButton pRipple label="Cancelar" icon="pi pi-times" text @click="recordDialog = false" class="m-0 mr-1" />
            <Button pButton pRipple label="Guardar" icon="pi pi-check" text @click="saveRecord()" class="m-0" :loading="submitted" />
        </div>
        </div>
    </template>
    </Dialog>


    </template>
<script>
import CareerDataServie from '@/services/CareerDataServie';
import ClassroomDataService from '@/services/ClassroomDataService';
import TeacherNoteDataService from '@/services/TeacherNoteDataService';
import NoteTypeDataService from '@/services/NoteTypeDataService';
import FormacionSuperiorArtisticaService from '@/services/FormacionSuperiorArtisticaService'
import { useRouter } from "vue-router";
import RegistrationStudentService from '@/services/RegistrationStudentService';
import InstitutionDataService from '@/services/InstitutionDataService';
import InscriptionStudentService from '@/services/InscriptionStudentService';
import ModalImportStudents from '@/components/ModalImportStudents.vue';
import PersonDataService from '@/services/PersonDataService'
import { useConfirm } from "primevue/useconfirm";


const router = useRouter(); 

const nextPage = (event) => {
    for (let field in event.formData) {
        formObject.value[field] = event.formData[field];
    }
    router.push(nestedRouteItems.value[event.pageIndex + 1].to);
};

const prevPage = (event) => {
    router.push(nestedRouteItems.value[event.pageIndex - 1].to);
};

const complete = (event) => {
    for (let field in event.formData) {
        formObject.value[field] = event.formData[field];
    }
};


const openNew = () => {
    submitted.value = false;
    recordDialog.value = true;
    search.value = true;
    //console.log(selectedCareer.value);
    router.push(nestedRouteItems.value[0].to);
};

export default{
    data:()=>({
        recordDialog:Boolean= false,
        formSearch:{ 
            identityCard: null,
            complement: '',
        },
        search:Boolean = false,
        paralelo:{},
        type:'',
        dataArmado:{},
        course:{},
        career:{},
        operativo: null,
        registro: {},
        classroom:{},
        students:[],
        estudiantes:[],
        note_type:{},
        operativo_edit: null,
        aula: {},
        isIdentityCardDisabled: false,
        isComplementoDisabled:false,
        isCedulaTipoIdDisabled:false,
        isIssuedDisabled:false,
        isBirthdateDisabled: false,
        isNameDisabled: false,
        isFirstLastNameDisabled: false,
        isSecondLastNameDisabled: false,
        periodos_ademicos:[],
        items: [
            {
                label: '2023',
                icon: 'bx bxs-calendar',
                command: () => {
                    // toast.add({ severity: 'success', summary: 'Updated', detail: 'Se Cambio de agestion 2023', life: 3000 });
                }
            },
        ],
        
        nestedRouteItems : [
            {
                label: 'Personal',
                to: '/registration-student/form/person/info'
            },
        ],
        reprobados: 0,
        no_asistidos: 0,
        aprobados:0

    }),
    mounted(){
        console.log('curso_id',this.$route.params.id);
        console.log('paralelo_id',this.$route.params.paralelo_id);
        this.getCursoFormacionArtistica();
        this.listarEstudiantesInscritos();
        /*this.getCareer()
        this.getClassroom()
        // this.getClassroomEstudents()
        this.getAula()
        this.getNoteType()
        this.getPeriodosAcademicos()
        this.getCurrentPeriod()*/
    },
    methods:
    {
        listarEstudiantesInscritos(){
            FormacionSuperiorArtisticaService.listarInscripcionEstudianteArtistica(this.$route.params.paralelo_id)
                .then((response)=>{
                    console.log('response.datas===>>',response.data);
                    this.estudiantes=response.data.data;
                })
        },

        nextPage(even){
            for (let field in event.formData) {
                formObject.value[field] = event.formData[field];
            }
            router.push(nestedRouteItems.value[event.pageIndex + 1].to);
        },

        getCareer() {
            CareerDataServie.get(this.$route.params.career_id)
                .then((response) => {
                    this.career = response.data.data
                    // this.setIntervals()
                    this.getCurrentPeriod()
                })
        },
        getCurrentPeriod() {
            CareerDataServie.getCurrentPeriod(this.$route.params.career_id)
                .then((response) => {
                    this.operativo = response.data.data
                    this.operativo_edit = response.data.data
                    this.getStudents()
                    console.log('operativo',response.data.data)
                    
                })
        },
        getPeriodosAcademicos()
        {
            CareerDataServie.getPeriodosAcademicos(this.$route.params.career_id)
                .then((response)=>{
                    this.periodos_ademicos = response.data.data
                    console.log('periodos academicos',response.data)
        
                })
        },
        setOperativo(new_operativo)
        {
            this.operativo = new_operativo
            this.getStudents()
        },
        getPromedio(student){
            // let promedio = parseInt( (parseInt(student.teorica) + parseInt(student.practica))/2 )
            return parseInt(student.teorica) + parseInt(student.practica)
        },
        async getAula()
        {
            let response = await TeacherNoteDataService.getStudentNotes(this.$route.params.aula_id,this.$route.params.career_id,0)
            console.log(response.data)
            this.aula = response.data.aula
    
        },
        getColorStatus(nota){
            let color=  ''
          

            if( parseFloat(nota) === 0 )
            {
               color = 'text-yellow-400' 
            }else{

                if(nota>0 && nota<61)
                {
                    color = 'text-red-500'
                }else{
                    color = 'text-green-500'
                }

            }

            return color
        },
        async getStudents(){
            
            // this.aula = aula
            let response = await TeacherNoteDataService.getStudents(this.$route.params.aula_id)
            this.students = response.data.data

            // if(this.operativo.evento === 'Calificaciones')
            // {
                let i=0
                let a = 0
                let r = 0
              await Promise.all(  this.students.map(async student => {
                    let result = await TeacherNoteDataService.getStudentNoteModalidad(student.instituto_estudiante_inscripcion_id,this.operativo.modalidad_id)
                    // console.log('result',result.data.data)
                    let practica = _.find(result.data.data,(objeto)=>{ return objeto.nota_tipo === 'PRÁCTICA' } )
                    // console.log('nota_practica',practica)
                    let teorica = _.find(result.data.data,(objeto)=>{ return objeto.nota_tipo === 'TEORÍA' } )
                    let suma = _.find(result.data.data,(objeto)=>{ return objeto.nota_tipo === 'SUMA' } )
                
                    if(teorica)
                    {
                        student.teorica = parseFloat(teorica.cuantitativa) 
                    }
                    if(practica)
                    {
                        student.practica = parseFloat(practica.cuantitativa) 
                    }
                    if(suma)
                    {
                        student.suma = parseFloat(suma.cuantitativa)
                        console.log('suma',student.suma)
                        if(parseFloat(suma.cuantitativa) === 0)
                        {
                            i++
                            console.log('asistido ', suma.cuantitativa)
                            console.log(i)
                        }else{
                            if(parseFloat(suma.cuantitativa) >= 1 && parseFloat(suma.cuantitativa) < 61)
                            {
                                r += 1
                            }
                            else{
                                a += 1
                            }
                        }
                    }

                   
                    return student
                }))

                this.no_asistidos = i
                this.aprobados = a
                this.reprobados = r
            console.log(response.data.data)
        },
        getClassroom()
        {
            ClassroomDataService.get(this.$route.params.aula_id)
                .then((response)=>{
                    console.log('aula ',response.data)
                    this.classroom = response.data
                })
        },
        getClassroomEstudents()
        {
            ClassroomDataService.getInscribed(this.$route.params.aula_id)
                .then((response)=>{
                    console.log(response.data.data);
                    this.students = response.data.data
                    let n1 = 0
                    let n2 = 0
                    this.students.forEach(item => {
                        n1= this.randomNumber(5,30)
                        n2 = this.randomNumber(50,70)
                        item.nota1 = n1
                        item.nota2 = n2
                        item.nota_final = n1+n2
                        return item
                    });
                    console.log(this.students)
                } )
        },
        getNoteType()
        {
            ClassroomDataService.getNoteType(7)
                .then((response)=>{
                    this.note_type = response.data
                })
        },
        randomNumber(min, max) {
            return parseInt( Math.random() * (max - min) + min );
        },
        getCursoFormacionArtistica(){
            console.log('entra al curso',this.$route.params.id);
            FormacionSuperiorArtisticaService.getById(this.$route.params.id)
            .then((response)=>{
                //this.institution = response.data
                console.log('response.data===>',response.data);
                this.course = response.data.data;
            })

            FormacionSuperiorArtisticaService.getParaleloData(this.$route.params.paralelo_id)
            .then((response)=>{
                //this.institution = response.data
                console.log('response.data===>',response.data);
                this.paralelo = response.data.data;
            })
        },
        openNew()  {
            console.log("entra aqui?");
            this.recordDialog = true;
            this.search = true;
        },
        onSubmitSearch(){
            console.log('formSearch',this.formSearch);
            PersonDataService.getByIdentityCard({carnetIdentidad: this.formSearch.identityCard, complemento: this.formSearch.complement}).then((data) => {
                console.log("jose_data", data.data.data);
                
                if(data.data.statusCode == 200){
                    this.type = 'existe';
                    console.log('entra por verdad');
                    this.$toast.add({ severity: 'info', summary: 'Info', detail: 'Cédula de Identidad ENCONTRADA en los registros del SEGIP', life: 3050 });
                    this.registro = data.data.data[0];
                    this.isIdentityCardDisabled = true;
                    this.isComplementoDisabled = true;
                    this.isCedulaTipoIdDisabled = true;
                    this.isIssuedDisabled = true;
                    this.isBirthdateDisabled = true;
                    this.isNameDisabled = true;
                    this.isFirstLastNameDisabled = true;
                    this.isSecondLastNameDisabled = true;
                    //this.registro.cedula_tipo_id = parseInt(this.registro.cedula_tipo_id);
                    console.log('this.registro',this.registro);
                    //this.$toast.add({ severity: 'info', summary: 'Info', detail: 'Cédula de Identidad no encontrado', life: 3050 });
                    this.search = false;
                    //router.push(nestedRouteItems.value[0].to);
                    }
                    else{
                        this.type = 'nuevo';
                        console.log('persona no encontra');
                        this.$toast.add({ severity: 'info', summary: 'Info', detail: 'Cédula de Identidad NO encontrada en los registros del SEGIP', life: 3050 });
                        this.dataArmado.complemento = '';
                        this.registro = {};
                        this.registro.complemento = '';
                        this.isIdentityCardDisabled = false;
                        this.isComplementoDisabled = false;
                        this.isCedulaTipoIdDisabled = false;
                        this.isIssuedDisabled = false;
                        this.isBirthdateDisabled = false;
                        this.isNameDisabled = false;
                        this.isFirstLastNameDisabled = false;
                        this.isSecondLastNameDisabled = false;
                    }
            });
        },

        saveRecord()
        {
            if(this.type == 'nuevo'){
                console.log('NUEVOOOOOO =>',this.registro);
                //VALIDAR SEGIP
                this.validarSegip(this.registro);
            }
            else{
                //existe
                //registra la inscripcion
                this.registro.carnet_identidad=this.registro.carnet_identidad;
                this.registro.estado = 'APROBADO';
                this.registro.nro_asistencia = 0;
                this.registro.observaciones = '';
                this.registro.paralelo_id = parseInt(this.$route.params.paralelo_id);
                this.registro.persona_id = this.registro.id
                console.log('este es el data antes de la inscripcion', this.registro);
                //invocar el servicio de inscripcion
                FormacionSuperiorArtisticaService.inscripcionEstudianteArtistica( this.registro)
                .then((response)=>{
                    this.listarEstudiantesInscritos();
                    //this.institution = response.data
                    console.log('response.data===>',response.data);
                    //this.course = response.data.data;
                    this.recordDialog = false;
                })
            }
        },

        validarSegip(datosPersona){
            let payload = {
                carnetIdentidad: datosPersona.carnet_identidad,
                complemento: (datosPersona.complemento.trim()).toUpperCase(),
                paterno: (datosPersona.paterno.trim()).toUpperCase(),
                materno: (datosPersona.materno.trim()).toUpperCase(),
                nombre: (datosPersona.nombre.trim()).toUpperCase(),
                fechaNacimiento: datosPersona.fecha_nacimiento, 
                cedulaTipoId: parseInt(datosPersona.cedula_tipo_id) 
            }
            console.log('payload ==> ', payload)
            PersonDataService.checkSegip(payload)
            .then((response)=>{
                console.log('ok',response)
                if(response.data.statusCode === 201)
                {
                    this.$toast.add({ severity: 'success', summary: 'Success Message', detail: 'Registro completado', life: 3000 })
                    
                    //REGISTRAR A LA PERSONA
                this.dataArmado.carnetIdentidad=this.registro.carnet_identidad;
                this.dataArmado.complemento=this.registro.complemento;
                this.dataArmado.paterno=this.registro.paterno;
                this.dataArmado.materno=this.registro.materno;
                this.dataArmado.nombre=this.registro.nombre;
                this.dataArmado.fecha_nacimiento = new Date(this.registro.fecha_nacimiento).toISOString();
                this.dataArmado.fechaNacimiento = (this.dataArmado.fecha_nacimiento.split('T'))[0];
                this.dataArmado.carnetIbc=" ";
                this.dataArmado.cedulaTipoId=1;
                this.dataArmado.ciExpedidoTipoId=1;
                this.dataArmado.codigoRda=" ";
                this.dataArmado.dobleNacionalidad=" ";
                this.dataArmado.email=" ";
                this.dataArmado.estadoCivilTipoId=" ";
                this.dataArmado.expedidoUnidadTerritorialId=2;
                this.dataArmado.generoTipoId=" ";
                this.dataArmado.id=" ";
                this.dataArmado.libretaMilitar=" ";
                this.dataArmado.maternoIdiomaTipoId=" ";
                this.dataArmado.nacimientoFolio=" ";
                this.dataArmado.nacimientoLibro=" ";
                this.dataArmado.nacimientoLocalidad=" ";
                this.dataArmado.nacimientoOficialia=" ";
                this.dataArmado.nacimientoPartida=" ";
                this.dataArmado.nacimientoUnidadTerritorialId=1;
                this.dataArmado.pasaporte=" ";
                this.dataArmado.sangreTipoId=" ";
                this.dataArmado.telefono=" ";
                this.dataArmado.tieneDiscapacidad=" ";
                this.dataArmado.persona_id=0;
                this.dataArmado.generoTipoId=1;
                this.dataArmado.estadoCivilTipoId=1;
                this.dataArmado.sangreTipoId=1;
                this.dataArmado.maternoIdiomaTipoId=1;
                this.dataArmado.segipTipoId=1;
                this.dataArmado.dobleNacionalidad=false;
                this.dataArmado.tieneDiscapacidad=false;
                console.log("data armado", this.dataArmado);
                PersonDataService.create(this.dataArmado)
                    .then((response)=>{
                        if(response.data.statusCode ==201)
                        {
                            console.log(response.data.data);
                            this.registro.estado = 'APROBADO';
                            this.registro.nro_asistencia = 0;
                            this.registro.observaciones = '';
                            this.registro.paralelo_id = parseInt(this.$route.params.paralelo_id);
                            this.registro.persona_id = response.data.data;
                            console.log('este es el data antes de la inscripcion', this.registro);
                            //invocar el servicio de inscripcion
                            FormacionSuperiorArtisticaService.inscripcionEstudianteArtistica( this.registro)
                            .then((response)=>{
                                this.recordDialog = false;
                                //this.institution = response.data
                                console.log('response.data===>',response.data);
                                this.listarEstudiantesInscritos();
                                //this.course = response.data.data;
                            })

                            this.$toast.add({ severity: 'success', summary: 'Success Message', detail: 'Registro completado', life: 3000 })
                            this.dialog = false;
                        }
                    })
                    
                }
                else
                {
                    this.$toast.add({ severity: 'info', summary: 'Info', detail: 'No se pudo contrastar la información con Segip, verifique los datos ingresados sean correctos ', life: 3050 });
                }
            })
            .catch((error)=>{
                    console.log('error', error.response)
                    this.$toast.add({ severity: 'info', summary: 'Info', detail: 'No se pudo contrastar la información con Segip, verifique los datos ingresados sean correctos ', life: 3050 });
            })
        }
    
    }
}
</script>

<style scoped>
.button-container {
  display: flex;
  justify-content: flex-end; /* Alinea los botones a la derecha */
  gap: 10px; /* Espacio entre los botones */
  margin-top: 1rem; /* Espacio superior opcional */
}
</style>