<template>
    <Toast position="bottom-right" />
    <Card>
        
        <template #title>
          {{ title }}
        </template>
        <template #subtitle v-if="sede" > <strong> Sede Central: {{ sede.institucionEducativa }} </strong><br>  <strong> ID: {{ sede.id }}</strong>  </template>
        <template #content>
            <Accordion :multiple="true" :activeIndex="[0]">
                <AccordionTab :header="sede?'1. Datos Generales Sucursal':'1. Datos Generales'">
                
                    <div class="formgrid grid">
                        <div class="field col-12 md:col-8">
                                <label>Denominacion</label>
                                <InputText type="text" class="p-inputtext-sm w-full"   v-model="institution.institucion_educativa" />
                               
                        </div>
                        <div class="field col-12 md:col-4">
                                <label>Tipo de Institucion</label>
                                <Dropdown v-model="institution_type" :options="institution_types" optionLabel="educacion" class=" p-inputtext-sm w-full" placeholder="Seleccionar " />
                        </div>
                       
                        <div class="field col-12 md:col-4">
                                <label>Resolucion Ministerial</label>
                                <InputMask id="basic" v-model="institution.numero_resolucion"  class="p-inputtext-sm w-full" mask="9999/9999" placeholder="0000/yyyy" />
                               
                        </div>
                        
                        <div class="field col-12 md:col-4">
                                <label>Fecha de Resolucion</label>
                                <Calendar class="p-inputtext-sm w-full" v-model="institution.fecha_resolucion"  dateFormat="dd/mm/yy"/>
                                <!-- <InputText type="text" class="p-inputtext-sm w-full" v-model="institution.resolution_date" /> -->
                        </div>


                        <div class="field col-12 md:col-4">
                                <label>Caracter Jurirido</label>
                                <Dropdown v-model="juridic" :options="juridic_list" optionLabel="dependencia" class=" p-inputtext-sm w-full" placeholder="Seleccionar " />
                        </div>

                        <div class="field col-12 md:col-4">
                                <label>Nivel</label>
                                <MultiSelect v-model="institution.nivels" :options="niveles" optionLabel="nivel" placeholder="Seleccionar" :maxSelectedLabels="3" class="w-full " />
                                <!-- <Dropdown v-model="institution.nivel" :options="niveles" optionLabel="nivel" class=" p-inputtext-sm w-full" placeholder="Seleccionar Tipo" /> -->
                        </div>

                        <div class="field col-12 md:col-8">
                                <label>Observacion</label>
                                <InputText type="text" class="p-inputtext-sm w-full" v-model="institution.observacion" />
                        </div>
                     
                    </div>
                    
                </AccordionTab>
                <AccordionTab :header="sede?'2. Ubicacion del local Educativo Sucursal':'2. Ubicacion del local Educativo'">

                    <div class="formgrid grid">
                        <div class="field col-12 md:col-4 ">
                            <h4 >Codigo de Localidad Educativa</h4>
                                <div class="flex ">
                                    <div class="p-inputgroup flex-1">
                                        <InputText placeholder="Ingrese Codigo L.E." v-model="jurisdiccion_geografica_id"/>
                                        <Button icon="pi pi-search" severity="warning" @click="findEducationalPlace()" />
                                    </div>
                                    <div class="pl-1"></div>
                                    <Button  v-tooltip.bottom="'Registrar Localidad Educativa'" icon="pi pi-plus" severity="success" @click="createForm()"  />
                                </div>
                                
                        </div>
                        
                    </div>
              
                    <div class="formgrid grid" v-if="location">
                        
                        <div class="field col-12 md:col-4">
                                <label>Departamento</label>
                                <InputText type="text" class="p-inputtext-sm w-full" v-model="location.data.departamento" disabled/>
                        </div>
                        <div class="field col-12 md:col-4">
                                <label>Provincia</label>
                                <InputText type="text" class="p-inputtext-sm w-full" v-model="location.data.provincia" disabled />
                        </div>
                        <div class="field col-12 md:col-4">
                                <label>Municipio</label>
                                <InputText type="text" class="p-inputtext-sm w-full" v-model="location.data.municipio" disabled />
                        </div>
                        <div class="field col-12 md:col-4">
                                <label>Canton</label>
                                <InputText type="text" class="p-inputtext-sm w-full" v-model="location.data.canton" disabled />
                        </div>
                        <div class="field col-12 md:col-4">
                                <label>Localidad</label>
                                <InputText type="text" class="p-inputtext-sm w-full" v-model="location.data.localidad" disabled />
                        </div>
                        <div class="field col-12 md:col-4">
                                <label>Zona</label>
                                <InputText type="text" class="p-inputtext-sm w-full" v-model="location.zone" disabled />
                        </div>
                        <div class="field col-12 md:col-12">
                                <label>Dirección</label>
                                <InputText type="text" class="p-inputtext-sm w-full" v-model="location.adress" disabled />
                        </div>
                        
                        <div class="field col-12 md:col-12">
                            <!-- <GMapMap
                                :center="{lat: 51.093048, lng: 6.84212}"
                                :zoom="10"
                                style="width: 100%; height: 400px"
                            >
                            </GMapMap> -->
                            <GoogleMap api-key="AIzaSyCXyIROL1yNeF8Jz9mAiOxh-QMlQAhUGkI" style="width: 100%; height: 500px" :center="center" :zoom="16">
                                <Marker :options="{ position: center }" />
                            </GoogleMap>
                        </div>
                      
                    </div>
                    <div class="formgrid grid" v-if="form">
                        <div class="field col-12 md:col-4 mb-0">
                            <label for="department">Departamento</label><br>
                            <AutoComplete v-model="form.department" id="department" optionLabel="lugar" :suggestions="filteredDepartment" @complete="searchDepartment" dropdown forceSelection @change="listProvince" :disabled='department.length === 0' />
                            <small v-show="validationErrors.department && submitted" class="p-error">es requerido</small>
                        </div>
                        <div class="field col-12 md:col-4 mb-0">
                            <label for="province">Provincia</label> <br>
                            <AutoComplete v-model="form.province" id="province" optionLabel="lugar" :suggestions="filteredProvince" @complete="searchProvince" dropdown forceSelection @change="listMunicipality" :disabled='!form.department || province.length === 0' />
                            <small v-show="validationErrors.province && submitted" class="p-error">es requerido</small>
                        </div>
                        <div class="field col-12 md:col-4 mb-0">
                            <label for="municipality">Municipio</label> <br>
                            <AutoComplete v-model="form.municipality" id="municipality" optionLabel="lugar" :suggestions="filteredMunicipality" @complete="searchMunicipality" dropdown forceSelection @change="listCommunity" :disabled='!form.province || municipality.length === 0' />
                            <small v-show="validationErrors.municipality && submitted" class="p-error">es requerido</small>
                        </div>
                        <div class="field col-12 md:col-4 mb-0">
                            <label for="community">Comunidad</label> <br>
                            <AutoComplete v-model="form.community" id="community" optionLabel="lugar" :suggestions="filteredCommunity" @complete="searchCommunity" dropdown forceSelection @change="listLocality" :disabled='!form.municipality || community.length === 0' />
                            <small v-show="validationErrors.community && submitted" class="p-error">es requerido</small>
                        </div>

                        <div class="field col-12 md:col-4 mb-0">
                            <label for="municipality">Localidad</label> <br>
                            <AutoComplete v-model="form.locality" id="locality" optionLabel="lugar" :suggestions="filteredLocality" @complete="searchLocalityX" dropdown forceSelection  :disabled='!form.community || locality.length === 0' />
                            <small v-show="validationErrors.locality && submitted" class="p-error">es requerido</small>
                        </div>
                        <!-- <div class="field col-12 md:col-4 mb-0">
                            <label for="location">Localidad</label>
                            <InputText id="location" type="text" v-model="form.location" />
                            <small v-show="validationErrors.location && submitted" class="p-error">es requerido</small>
                        </div> -->
                        <div class="field col-12 md:col-4">
                                <label>Zona</label>
                                <InputText type="text" class="p-inputtext-sm w-full" v-model="form.zone" />
                        </div>
                        <div class="field col-12 md:col-4">
                                <label>Dirección</label>
                                <InputText type="text" class="p-inputtext-sm w-full" v-model="form.adress" />
                        </div>
                    </div>
                    <div id="map"></div>
            
                </AccordionTab>
              
            </Accordion>
        </template>
        <template #footer>
            <Button icon="pi pi-check" label="Guardar"  class="p-button-success" @click="createInstitute()" />
            <Button icon="pi pi-times" label="Cancelar" class="p-button-secondary" @click="router.push('/institutions')" style="margin-left: .5em" />
        </template>
    </Card>

    
</template>
<script setup>
import InstitutionTypeDataService from "@/services/InstitutionTypeDataService"
import EducationalPlaceDataService from "@/services/EducationalPlaceDataService"
import InstitutionDataService from "@/services/InstitutionDataService";
import JuridicDataService from "@/services/JuridicDataService"
import TerritoryTypeDataService from '@/services/TerritoryTypeDataService';

import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { computed, onMounted, ref } from "vue";
import { useToast } from "primevue/usetoast";
import { useRoute, useRouter } from "vue-router";

const toast = useToast()
const router = useRouter()
const route = useRoute()
const institution_type = ref(null)
const institution_types= ref([])
const location = ref(null)
const form = ref(null) 
const jurisdiccion_geografica_id = ref("")
const institution = ref({})
const sede = ref(null)
const juridic_list = ref([])
const juridic = ref({})
const center = {lat: 40.689247, lng: -74.044502 }
const validationErrors = ref({})
const niveles = ref(
    [
        {
            id: 500,
            nivel: 'Técnico Superior'
        },
        {
            id: 501,
            nivel: 'Técnico Medio'
        },
        {
            id: 501,
            nivel: 'Capacitación'
        }
    ]
)

// const country = ref({});
const department = ref([]);
const province = ref([]);
const municipality = ref([]);
const community = ref([]);
const locality = ref([]);

onMounted(()=>{
    getInstitutionTypes()
    getJuridicList()
    listDeparment()
})
const getInstitutionTypes = async () =>{
    let response = await InstitutionTypeDataService.getAll()
    institution_types.value = response.data
}

const getJuridicList = async ()=>{
    let response = await JuridicDataService.getAll()
    juridic_list.value = response.data
}

const findSede = async ()=>{
    if(route.params.institution_id>0)
    {
       let response = await InstitutionDataService.get(route.params.institution_id)
       sede.value = response.data.data
    }
}
const title = computed(()=>{
    let title = route.params.id > 0 ? 'Editar':'Registrar'
    if(route.params.institution_id>0)
    {
        title += ' Sucursal '
    }
    title +=" Instituto"
    return title
})

const dateToDMY = (date)=> {
    let d = date.getDate();
    let m = date.getMonth() + 1; //Month from 0 to 11
    let y = date.getFullYear();
    return '' + (d <= 9 ? '0' + d : d) + '/' + (m<=9 ? '0' + m : m) + '/' + y;
}

const findEducationalPlace = async () =>{
    location.value = null
    let response = await EducationalPlaceDataService.get(jurisdiccion_geografica_id.value)
    if(response.data.statusCode == 404)
    {
        let message=""
        response.data.message.forEach(element => {
            message= element + '\n'
        });
        toast.add({ severity: 'info', summary: 'SIE', detail: message, life: 3000 });
        form.value = {}
    }

    if(response.data.statusCode==201)
    {
        location.value = response.data
        center.lat = location.value.data.cordx ?? 40.689247
        center.lng = location.value.data.cordy ?? -74.044502
        form.value = null
    }

}

//logica de llenado del formulario 
//departament
const listDeparment = () => {
    filteredDepartment.value = [];
    department.value = [];
    if(form.value)
    {
        form.value.department = null;
        form.value.province = null;
        form.value.municipality = null;
        form.value.community = null;
        form.value.locality = null;
    }   
    
    TerritoryTypeDataService.getDepartment2001(1).then((data) => { //1 = bolivia
        department.value = data.data.data;
        //para recuperar objeto prellenado en formulario pero creo que no sera necesario
        // if (!Object.is(props.formData.department)){
        //     form.value.department = (department.value)[findIndexDataById(department.value, props.formData.department)];
        // }
    });
}
const filteredDepartment = ref();
const searchDepartment = (event) => {    
    // let _items = [...department.value.keys()];
    setTimeout(() => {
        if (!event.query.trim().length) {
            filteredDepartment.value = [...department.value];
        } else {
            filteredDepartment.value = department.value.filter((item) => {
                return item.lugar.toLowerCase().startsWith(event.query.toLowerCase());
            });
        }
    }, 100);
}
//province


const listProvince = () => {
    filteredProvince.value = [];
    province.value = [];
    if(form.value)
    {
        form.value.province = null;
        form.value.municipality = null;
        form.value.community = null;
        form.value.locality = null;
        if(!form.value.department){
            return;
        } 
        if(!form.value.department.id){
            return;
        } 

    }
    TerritoryTypeDataService.getProvince2001(form.value.department.id).then((data) => {
        province.value = data.data.data;
        // if (!Object.is(props.formData.province)){
        //     form.value.province = (province.value)[findIndexDataById(province.value, props.formData.province)];
        // }
    });
}
const filteredProvince = ref();
const searchProvince = (event) => {
    // let _items = [...province.value.keys()];
    setTimeout(() => {
        if (!event.query.trim().length) {
            filteredProvince.value = [...province.value];
        } else {
            filteredProvince.value = province.value.filter((item) => {
                return item.lugar.toLowerCase().startsWith(event.query.toLowerCase());
            });
        }
    }, 100);
}

//municipality

const listMunicipality = () => {
    filteredMunicipality.value = [];
    municipality.value = [];
    if(form.value){
        form.value.municipality = null;
        form.value.community = null;
        form.value.locality = null;
        if(!form.value.province){
            return;
        }
        if(!form.value.province.id){
            return;
        }
    
        TerritoryTypeDataService.getMunicipality2001(form.value.province.id).then((data) => {
            municipality.value = data.data.data;
            // if (!Object.is(props.formData.municipality)){
            //     form.value.municipality = (municipality.value)[findIndexDataById(municipality.value, props.formData.municipality)];
            // }
        });
    }
}
const filteredMunicipality = ref();
const searchMunicipality = (event) => {
    // let _items = [...municipality.value.keys()];
    setTimeout(() => {
        if (!event.query.trim().length) {
            filteredMunicipality.value = [...municipality.value];
        } else {
            filteredMunicipality.value = municipality.value.filter((item) => {
                return item.lugar.toLowerCase().startsWith(event.query.toLowerCase());
            });
        }
    }, 100);
}
//comunity

const listCommunity = () => {
    filteredCommunity.value = [];
    community.value = [];
    form.value.locality = null;
    form.value.community = null;
    if(form.value)
    {
        if(!form.value.municipality){
            return;
        }
        if(!form.value.municipality.id){
            return;
        }
        TerritoryTypeDataService.getCanton2001(form.value.municipality.id).then((data) => {
            community.value = data.data.data;
            // if (!Object.is(props.formData.community)){
            //     form.value.community = (community.value)[findIndexDataById(community.value, props.formData.community)];
            // }
        });
    }
}
const filteredCommunity = ref();
const searchCommunity = (event) => {
    // let _items = [...community.value.keys()];
    setTimeout(() => {
        if (!event.query.trim().length) {
            filteredCommunity.value = [...community.value];
        } else {
            filteredCommunity.value = community.value.filter((item) => {
                return item.lugar.toLowerCase().startsWith(event.query.toLowerCase());
            });
        }
    }, 100);
}
// locality
const listLocality = () => {
    filteredLocality.value = [];
    locality.value = [];
    if(form.value)
    {
        // if(!form.value.community){
        //     return;
        // }
        // if(!form.value.community.id){
        //     return;
        // }
        TerritoryTypeDataService.getLocality2001(form.value.community.id).then((data) => {
            locality.value = data.data.data;
            console.log(locality.value)
            // if (!Object.is(props.formData.locality)){
            //     form.value.locality = (locality.value)[findIndexDataById(locality.value, props.formData.locality)];
            // }
        });
    }
}
const filteredLocality = ref();
const searchLocalityX = (event) => {
    // let _items = [...municipality.value.keys()];
    console.log('lanzando evento', event)
    setTimeout(() => {
        if (!event.query.trim().length) {
            filteredLocality.value = [...locality.value];
        } else {
            filteredLocality.value = locality.value.filter((item) => {
                return item.lugar.toLowerCase().startsWith(event.query.toLowerCase());
            });
        }
    }, 100);
}

const createForm = ()=>{
    form.value = {}
    location.value = null
}


const createInstitute = async () =>{

    let new_institution = null

    let nivel_tipo_id = []
    console.log('niveles',institution.value.nivels)
    institution.value.nivels.forEach((level)=>{
        nivel_tipo_id.push({id: level.id})
    })

    if(location.value)
    {
        new_institution= {
                jurisdiccion_geografica_id: location.value.data.codigo_edificio_educativo,
                institucion_educativa: institution.value.institucion_educativa,
                educacion_tipo_id: institution_type.value.id,
                dependencia_tipo_id: juridic.value.id, //juridiccion tipo
                fecha_resolucion: institution.value.fecha_resolucion,
                numero_resolucion: institution.value.numero_resolucion,
                sucursal_codigo: sede.value?sede.value.id:0,//ver opcion de crear sede
                sucursal_nombre: sede.value?"SUBSEDE":"SEDE", //colocar conforme sea el caso si es sub sede debe colocarce SUBSEDE y el id en la sucucarl codigo 
                observacion: institution.value.observacion,
                departamento_id: null,
                provincia_id: null,
                municipio_id: null,
                canton_id: null,
                localidad_id: null,
                zona: null,
                adress: null,
                latitud: 40.689247,
                longitud: -74.044502,
                nivel_tipo: nivel_tipo_id,
            }
       

    }else{
        new_institution= {
                jurisdiccion_geografica_id: null,
                institucion_educativa: institution.value.institucion_educativa,
                educacion_tipo_id: institution_type.value.id,
                dependencia_tipo_id: juridic.value.id, //juridiccion tipo
                fecha_resolucion: institution.value.fecha_resolucion,
                numero_resolucion: institution.value.numero_resolucion,
                sucursal_codigo: sede.value?sede.value.id:0,//ver opcion de crear sede
                sucursal_nombre: sede.value?"SUBSEDE":"SEDE", //colocar conforme sea el caso si es sub sede debe colocarce SUBSEDE y el id en la sucucarl codigo 
                observacion: institution.value.observacion,
                departamento_id: form.value.department.id,
                provincia_id: form.value.province.id,
                municipio_id: form.value.municipality.id,
                canton_id: form.value.community.id,
                localidad_id: form.value.locality.id,
                zona: form.value.zone,
                adress: form.value.adress,
                latitud: 40.689247,
                longitud: -74.044502,
                nivel_tipo: nivel_tipo_id,
            }
    }

    console.log(new_institution)
    let response = await InstitutionDataService.create(new_institution)
    
        if(response.data.statusCode==500)
        {   
            let message=""
            response.data.message.forEach(element => {
                message= element + '\n'
            });
            toast.add({ severity: 'info', summary: 'SIE', detail: message, life: 3000 });
        }
        
        if(response.data.statusCode==201)
        {
            
            toast.add({ severity: 'success', summary: 'Success Message', detail: 'Registro completado', life: 3000 })
            router.push('/institutions')  
            
        }

}


//         createInstituto()
//         {
//             console.log(this.institution_type) 
//             if(this.location)
//             {

//                 let new_institution= {
//                 "jurisdiccion_geografica_id": this.location.data.id,
//                 "institucion_educativa": this.institution.institucion_educativa,
//                 "educacion_tipo_id": this.institution_type.id,
//                 "dependencia_tipo_id": this.juridic.id, //juridiccion tipo
//                 "fecha_resolucion": this.dateToDMY(this.institution.fecha_resolucion),
//                 "numero_resolucion": this.institution.numero_resolucion,
//                 "sucursal_codigo": this.sede?this.sede.id:0,//ver opcion de crear sede
//                 "sucursal_nombre": this.sede?"SUBSEDE":"SEDE", //colocar conforme sea el caso si es sub sede debe colocarce SUBSEDE y el id en la sucucarl codigo 
//                 "observacion": this.institution.observacion
//                 }
//                 console.log(new_institution)
//                 InstitutionDataService.create(new_institution)
//                     .then((response) => {
                        
//                         if(response.data.statusCode==500)
//                         {   
//                             let message=""
//                             response.data.message.forEach(element => {
//                                 message= element + '\n'
//                             });
//                             this.$toast.add({ severity: 'info', summary: 'SIE', detail: message, life: 3000 });
//                         }
                        
//                         if(response.data.statusCode==201)
//                         {
                            
//                             this.$toast.add({ severity: 'success', summary: 'Success Message', detail: 'Registro completado', life: 3000 })
//                             this.$router.push('/institutions')  
                          
//                         }


//                         console.log(response.data);
                        
//                     })
//                     .catch((e) => {
//                         console.log(e);
//                     }); 


//             }else{
//                 this.$toast.add({ severity: 'error', summary: 'No se pudo Resitrar', detail: 'Se requiere una ubicacion para el instituto', life: 3000 });
//             }
//         },
//    

//     components:{
//         GoogleMap, Marker 
//     }


// }

</script>
<style>
    #map{ height: 180px;}
</style>