import http from "@/http-common";
class SubjectDataService {
    
    
    constructor(controller){
      this.controller = controller
    }

    getAll(){
      return http.get("/asignatura-tipo");
    }

    searchSubject(query,controller)
    {
      // this.controller = new AbortController()
      // return http.get(`/asignatura-tipo/search?query=${query}`,{signal:controller.signal})
      return http.get(`/asignatura-tipo/search?query=${query}`)
    }

    createStudyPlan(data)
    {
      return http.post("/plan-estudio-asignatura/prerequisito",data);
    }

    // getController()
    // {
    //   return this.controller
    // }
    // getTimes(intervalo_gestion_tipo_id,nivel_academico_tipo_id){
    //     return http.get(`/tiempo-estudio-tipo/itt/${intervalo_gestion_tipo_id}/${nivel_academico_tipo_id}`)
    // }

    
    // getInstitutionAreas(id){
    //   return http.get(`/jurisdiccion-geografica/2001/codigo/${id}`);
    // }

    // get(id) {
    //   return http.get(`/jurisdiccion-geografica/2001/codigo/${id}`);
    // }
  
  //   create(data: any): Promise<any> {
  //     return http.post("/users", data);
  //   }
  
  //   update(id: any, data: any): Promise<any> {
  //     return http.put(`/users/${id}`, data);
  //   }
  
  //   delete(id: any): Promise<any> {
  //     return http.delete(`/users/${id}`);
  //   }
  
  //   deleteAll(): Promise<any> {
  //     return http.delete(`/users`);
  //   }
  
  //   findByTitle(title: string): Promise<any> {
  //     return http.get(`/users?title=${title}`);
  //   }
  }
  // const  controller = new  AbortController();
  export default new SubjectDataService();