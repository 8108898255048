<template>
    <Toast position="bottom-right" />
        <Card>
        <template #title>
            <div class="flex flex-column w-full">
                <span> CURSO: {{ course.nombre }}</span>
                <span class="text-sm font-s text-blue-300">{{ course.instituto_id }} : {{ course.instituto?.tipo_artistica }} </span> 
            </div>
        </template>
        <template #content>
            <div class="formgrid grid">
                <div class="field col-12 md:col-6">
                    <Panel header="Datos Generales del Curso" toggleable>
                       
                        <div class="grid">
                            <div class="col-6">
                                <table class="table table-sm">
                                    <tbody>
                                       
                                        <tr>
                                            <td class=" text-end "><strong>Nombre:</strong> </td>
                                            <td class="">{{ course.nombre }}</td>
                                        </tr>
                                        <tr>
                                            <td class=" text-end "><strong>Área:</strong> </td>
                                            <td class=""> {{ course.area?.nombre }}</td>
                                        </tr>
                                        <tr>
                                            <td class=" text-end "><strong>Disciplina:</strong> </td>
                                            <td class=""> {{ course.disciplina?.nombre }}</td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                            <div class="col-6">
                                <table class="table table-sm">
                                    <tbody>
                                        <tr>
                                            <td class=" text-end "> <strong>Nivel de Formación:</strong></td>
                                            <td class=""> {{ course.niveles?.nivel }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                                
                    </Panel>
                </div>

                <div class="field col-12 md:col-6" v-if="course">
                    <CourseManagementArtistic @updateData="updateGestion"></CourseManagementArtistic>
                </div>
                <div class="field col-12">
                    <div class="flex justify-content-between">
                        <SplitButton class="w-16rem" :label="state_selected?.estado??''" icon="pi pi-map" :model="items" v-if="career" />
                        <!--div class="flex justify-content-end">
                            <Button icon="pi pi-plus" label="Adicionar Resolución de Plan de Estudio" severity="secondary" size="small" @click="addResolution()" v-if="has_period && career" />
                            <Message v-else>Debe Adicionar un Periodo Academico</Message>
                        </div-->
                    </div>
                </div>
                        <div class="field col-12 md:col-6" v-for="(paralelo,index) in paralelos" :key="index">
                            <Panel :header="`Resolución de Plan de Estudio `" toggleable :collapsed="true" 
                            :pt="{
                                header: { class: 'bg-secondary' },
                                title: { class: 'text-white' },
                                icons: { class: 'text-white'},
                                toggler: { class: 'text-white hover:bg-primary-reverse' }
                            }"    
                                >
                                <template #header>
                                    <div class="flex align-items-center ">
                                        <span class="text-white font-bold pr-3"> PARALELO : {{ paralelo.nombre_paralelo ?? ''}}</span>
                                        <span class="font-semibold" style="color: #53afd3;" > TURNO :{{ turnos.turno }}</span>
                                    </div>
                                </template>
                                <template #icons>
                                    <button class="p-panel-header-icon p-link mr-2" @click="checkStudentsArtistic(paralelo)">
                                        <span class="bx bxs-plus-circle text-green-400 "></span>
                                    </button>
                                    <div class="p-panel-header-icon " style="border-radius: 0px; width: 35px;">
                                        <i class="bx bxs-user-detail text-white " ></i>
                                        <Badge :value="paralelo?.total_estudiantes" severity="success" style="font-size: 8px; margin-bottom: 1px !important;" ></Badge>
                                    </div>
                                </template>

                                <table class="table w-full">
                                    <thead>
                                        <th>Código</th>
                                        <th>Materia</th>
                                        <th>Docente</th>
                                        <th>Estudiantes</th>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(aula, aula_index) in paralelo.list" :key="aula_index">
                                            <td class="text-sm" >{{ aula.abreviacion }}</td>
                                            <td class="text-sm">{{ aula.asignatura }}</td>
                                            <td class="text-sm"> {{ `${docentes.nombre ?? ''} ${aula.paterno ?? ''} ${aula.materno ?? '' }` }}</td>
                                            <td class="text-sm pl-0 pr-0 p-overlay-badge">
                                                <div class=" flex justify-content-end ">
                                                    <Badge :value="aula.total_estudiantes" severity="success" v-if="aula.total_estudiantes !== '0'"></Badge>
                                                    <Button type="button" icon="bx bxs-user-detail" @click="checkStudents(aula.aula_id)"  class="" v-tooltip.bottom="'Ver Estudiantes'"  :severity="aula.total_estudiantes === '0' ? 'secondary' : '' " />
                                                </div>

                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            
                            </Panel>
                        </div>
                    
               
            </div>
            
        </template>
    </Card>

    <Dialog v-model:visible="dialog" modal :header="study_resolution.id?'Editar Resolución de Plan de Estudio':'Registro de Resolución de Plan de Estudio'" :style="{ width: '50vw' }">
        <Message v-if="!study_resolution.id">Las Resoluciones de Plan de Estudio <strong>0210/2023 , 0637/2019</strong>  ya se encuentran disponibles en el listado de resoluciones de planes de Estudio vigentes </Message>
        <div class="formgrid grid">
            
            
            <div class="col" v-if="!study_resolution.id" >
                    <label >Desea usar una Resolucion de Plan de Estudio General <strong>0210/2023 o 0637/2019</strong>?</label>
                    <SelectButton v-model="current_resolution_question" :options="options" aria-labelledby="basic" />
            </div>
            
            <Divider layout="vertical" v-if="!study_resolution.id" />
            <div class="col " v-if="current_resolution_question=='SI'" >
                Resoluciones de planes de estudio vigentes:
                <br>
                <br>   
                <div v-for="(item, index ) in current_resolutions" :key="index" class="flex align-items-center">
                    
                    <RadioButton v-model="current_resolution"  :value="item"  />
                    <label class="ml-3 mt-2"> <strong>{{ item.numero_resolucion }} <Tag severity="info" :value="item.denominacion" v-if="item.denominacion"></Tag> <Tag severity="success" :value="item.mencion" v-if="item.mencion"></Tag> </strong> </label>
                </div>
                
                <!-- <Listbox v-model="current_resolution" :options="current_resolutions" optionLabel="numero_resolucion" class="w-full md:w-14rem" /> -->
            </div>
            <div :class="study_resolution.id ? 'field col-12 md:col-12' : 'field col-12 md:col-8'" v-if="current_resolution_question=='NO' || study_resolution.id" >
                <div class="formgrid grid">
                    <div class="field col-12 md:col-12">
                        <label >Numero de Resolución</label>
                        <InputMask id="basic" v-model="study_resolution.numero_resolucion" :disabled="study_resolution.id"  class="p-inputtext-sm w-full" mask="9999/9999" placeholder="0000/yyyy" />
                     

                        <!-- <InputText type="text"   /> -->
                    </div>
                    <div class="field col-12 md:col-12">
                        <label >Fecha de Resolución</label>
                        <Calendar class="p-inputtext-sm w-full" v-model="study_resolution.fecha_resolucion"  dateFormat="dd/mm/yy" :disabled="study_resolution.id"/>
                    </div>
                    <div class="field col-12 md:col-12">
                        <label >Denominación del Titulo</label>
                        <InputText type="text" class="p-inputtext-sm w-full" v-model="study_resolution.denominacion" />
                    </div>
                    <div class="field col-12 md:col-12">
                        <label >Mención</label>
                        <InputText type="text" class="p-inputtext-sm w-full" v-model="study_resolution.descripcion" />
                    </div>
                </div>
            </div>
        </div>
        <template #footer>
            <Button label="Cancelar" icon="pi pi-times" @click="dialog = false" text />
            <Button label="Guardar" icon="pi pi-check" @click="createStydeResolution()" autofocus v-if="!study_resolution.id" :disabled="button_desabled" />
            <Button label="Actualizar" icon="pi pi-check" @click="updateStudyResolution()" autofocus v-else :disabled="button_desabled" />
        </template>
    </Dialog>

    <Dialog v-model:visible="dialog_history" modal :header="`Historial del Plan de Estudio ${study_plan?.study_plan?.numeroResolucion}`" :style="{ width: '50vw' }">
        <Timeline :value="study_plan?.histories">
            <template #opposite="slotProps">
                <div class="flex flex-column">
                    <span> {{slotProps.item.estadoInstituto.estado}} </span>
                    <small class="p-text-secondary">{{slotProps.item.fechaRegistro}}</small>
                </div>
            </template>
            <template #content="slotProps">
                <div class="flex flex-column">
                    
                    <InlineMessage :severity="slotProps.item.estadoInstituto.id === 8 || slotProps.item.estadoInstituto.id == 4?'error':'info' ">{{slotProps.item.observacion  }}</InlineMessage>
                </div>
               
               
            </template>
        </Timeline>
    </Dialog>

    <Dialog v-model:visible="dialog_edit_resolution" modal :header="`Editar Resolución de Plan de Estudio `" :style="{ width: '40vw' }">
        <div class="formgrid grid">
            <div class="field col-12 md:col-12">
                <label >Numero de Resolución</label>
                <InputMask id="basic" v-model="resolution_plan_edit.numeroResolucion"   class="p-inputtext-sm w-full" mask="9999/9999" placeholder="0000/yyyy" />
                

                <!-- <InputText type="text"   /> -->
            </div>
            <div class="field col-12 md:col-12">
                <label >Fecha de Resolución</label>
                <Calendar class="p-inputtext-sm w-full" v-model="resolution_plan_edit.fecha_resolucion_date"  dateFormat="dd/mm/yy" />
            </div>
            <div class="field col-12 md:col-12">
                <label >Denominación del Titulo</label>
                <InputText type="text" class="p-inputtext-sm w-full" v-model="resolution_plan_edit.denominacion" />
            </div>
            <div class="field col-12 md:col-12">
                <label >Mención</label>
                <InputText type="text" class="p-inputtext-sm w-full" v-model="resolution_plan_edit.descripcion" />
            </div>
        </div>
        <template #footer>
            <Button label="Cancelar" icon="pi pi-times" @click="dialog_edit_resolution = false" text />
            <Button label="Actualizar" icon="pi pi-check" @click="updateResolutionPlan()" />
        </template>
    </Dialog>
    
 
</template>
<script>

import FormacionSuperiorArtisticaService from '@/services/FormacionSuperiorArtisticaService'
import AcademicOffertDataService from '@/services/AcademicOffertDataService';
import CourseManagementArtistic from '@/components/CourseManagementArtistic.vue';
import InstitutionDataService from '@/services/InstitutionDataService'


export default
{
    data:()=>({
        course:{},
        paralelos:[],
        dialog:false,
        current_resolution_question:'',
        current_resolution:null,
        current_resolutions:[],
        study_resolution:{},
        study_resolutions:[],
        states: [],
        options:['SI','NO'],
        current_period: null,
        state_selected: null,
        gestion: null,
        periodo: null,
        has_period: false,
        button_desabled: false,
        dialog_history: false,
        study_plan: null,
        dialog_edit_resolution: false,
        resolution_plan_edit: null,
    }),
    async mounted(){
        this.getCursoFormacionArtistica();
        this.getParalelos();
        this.getTurnos();
        this.getInstitution()
        //this.getCareer()
        //Promise.all([this.getOperativeActive(),this.getEstados(),this.getCareer(),this.reloadPage()])

    },
    methods:
    {
        getCursoFormacionArtistica(){
            console.log('entra al curso',this.$route.params.id);
            FormacionSuperiorArtisticaService.getById(this.$route.params.id)
            .then((response)=>{
                //this.institution = response.data
                console.log('response.data===>',response.data);
                this.course = response.data.data;
            })
        },
        checkStudentsArtistic(paralelo)
        {
            console.log('paralelo',paralelo);
            console.log('entra a la planilla',this.$route.params.id);
            this.$router.push(`/classroomArtistica/${this.$route.params.id}/${paralelo.id}`)
        },
        getParalelos(){
            FormacionSuperiorArtisticaService.getParalelos(this.$route.params.id)
            .then((response)=>{
                this.paralelos = response.data.data;
                console.log('this.paralelos',this.paralelos);
            })
        },
        async getTurnos()
        {
            let response = await AcademicOffertDataService.getTurnos()
            //console.log('respturnos',data)
            this.turnos = response.data;
            console.log('this.turnos',this.turnos);
        },

        getInstitution()
        {
            InstitutionDataService.getInfo(this.$route.params.id)
            .then((response)=>{
                this.institution = response.data
                console.log("institution", response.data)
            })
        },
    },
    components:{
        CourseManagementArtistic
    }
}
</script>
