import http from "@/http-common";
class ClassroomDataService {
    // getAll(){
    //   return http.get("/area-tipo");
    // }

    // getInstitutionAreas(id){
    //   return http.get(`/jurisdiccion-geografica/2001/codigo/${id}`);
    // }

    get(aula_id) {
      return http.get(`/aula/${aula_id}`);
    }
    
    getInscribed(aula_id)
    {
        return http.get(`/inscripcion/byAulaId/${aula_id}`)
    }

    getNoteType(note_type_id)
    {
        return http.get(`/nota-tipo/${note_type_id}`)
    }
  //   create(data: any): Promise<any> {
  //     return http.post("/users", data);
  //   }
  
  //   update(id: any, data: any): Promise<any> {
  //     return http.put(`/users/${id}`, data);
  //   }
  
  //   delete(id: any): Promise<any> {
  //     return http.delete(`/users/${id}`);
  //   }
  
  //   deleteAll(): Promise<any> {
  //     return http.delete(`/users`);
  //   }
  
  //   findByTitle(title: string): Promise<any> {
  //     return http.get(`/users?title=${title}`);
  //   }
  }
  
  export default new ClassroomDataService();