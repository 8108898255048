<template>

  <GDialog v-model="parent_dialog" max-width="500">
    <div class="card" >
  
  <div class="card-body">
    <h5 class="card-title">Registrar Estudiantes </h5> 
    
    
    <div class="form-horizontal form-material mx-2">
      
      <div class="form-group">
        <label class="col-md-12 mb-0">Nombres</label>
        <div class="col-md-12">
          <input type="text" class="form-control ps-0 form-control-line"  v-model="item.names" >
        </div>
      </div>

      <div class="form-group">
        <label class="col-md-12 mb-0">Apellidos</label>
        <div class="col-md-12">
          <input type="text" class="form-control ps-0 form-control-line"  v-model="item.lastnames" >
        </div>
      </div>

      <div class="form-group">
        <label class="col-md-12 mb-0">Unidad Educativa</label>
        <div class="col-md-12">
          <input type="text" class="form-control ps-0 form-control-line"  v-model="item.unit_educative" >
        </div>
      </div>

      <div class="form-group">
        <label class="col-md-12 mb-0">Edad</label>
        <div class="col-md-12">
          <input type="text" class="form-control ps-0 form-control-line"  v-model="item.age" >
        </div>
      </div>


    </div>

    
    <div class="d-grid gap-2 d-md-flex justify-content-md-end">
      
      <button class=" btn btn-secondary" @click="closeModal()"> Cerrar</button> 
      <button class=" btn btn-success mx-auto mx-md-0 text-white" @click="sendStudent()"> Guardar</button>

    </div>
   
  </div>
</div>
  
  </GDialog>
</template>
<script>
  
    export default{
        props:{
          dialog: Boolean,
          student: Object
        },
        data:()=>({
            

        }),
        methods:{
            sendStudent(){
              this.$emit('student',this.item)

            },
            closeModal(){
              this.$emit('close',false)
            }
        },
        computed:
        {
          parent_dialog()
          {
            return this.dialog
          },
          item(){
            let item = this.student
            return item
          }
        },
        
    
        
    }
</script>