import axios from "axios";
import authHeader from "@/helpers/authHeader";
const url = process.env.VUE_APP_URL;

const apiClient = axios.create();

// Add a request interceptor
apiClient.interceptors.request.use( (config)=> {
  // Do something before request is sent
  config.baseURL = url
 
    // const token = localStorage.getItem("access_token");

    // if(token)
    // {
      // config.headers.Authorization = `Bearer ${token}`;
      config.headers.Authorization = authHeader()
    // }
   

  return config;
}, (error) => {
  // Do something with request error
  console.log('error',error)
  return Promise.reject(error);
});

  apiClient.interceptors.response.use( (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    // console.log('todo bien')
    return response;
  }, (error) => {
  
    console.log('ups error ',error)
    // alert('error')
    // if(error.response.status === 404 || error.response.status === 401 ) // el 404 se removio por causa de la imagen del instituto
    if( error.response.status === 401 )  //solo si no esta autorizado
    {
      console.log('close sesion')
      window.location.href = '/login'
      localStorage.clear();
    }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  })


// }

export default apiClient;
