<template>
    <Toast position="bottom-right" />
    <div class="flex flex-row-reverse flex-wrap">
        <Button type="button" size="small" label="Adicionar Carrera" icon="pi pi-plus" @click="createCareerInst()" />
    </div>

    <DataTable :value="careers" paginator :rows="5" :rowsPerPageOptions="[5, 10, 20, 50]" >
        <Column style="width: 10%"> 
            <!-- <template #body="{ data,index }"> -->
                <template #body="{data}">
                <!-- <button type="button" class="btn btn-primary"> </button> -->
                <div class="btn-group me-2" role="group" aria-label="First group"> 
                    <Button v-tooltip="'Ver Detalle'" icon="mdi mdi-eye" text raised rounded aria-label="Filter" @click="redirectCareerView(data.carrera_autorizada_id)" />
                </div>
               
    
            </template>
        </Column>
        <Column field="carrera.carrera" header="CARRERA" style="width: 15%"></Column>
        <!-- <Column field="area" header="Area" style="width: 25%"></Column>        -->
        <Column field="programas.nombre" header="PROGRAMA" style="width: 15%"></Column>
        <Column field="areas.nombre" header="ÁREA" style="width: 15%"></Column>
        <Column field="nivelinst.nivel" header="NIVELES" style="width: 15%"></Column>
        <Column field="total_hab" header="TOTAL HAB." style="width: 10%"></Column>
        <Column field="total_clases" header="TOTAL CLASES" style="width: 10%"></Column>
        <Column field="carga_horaria" header="CARGA HORARIA" style="width: 10%"></Column>
        <Column field="tiempo_estudio" header="TIEMPO DE ESTUDIO" style="width: 10%"></Column>
        <!-- <Column field="representative.name" header="Tipo de Tramite" style="width: 25%"></Column> -->
    </DataTable>

    <Dialog v-model:visible="dialog" modal :header="title" :style="{ width: '80vw' }">
        <div class="formgrid grid">

            <div class="field col-12 md:col-6">
                    <label>Área</label>
                    <Dropdown v-model="registro.areas_formacion_artistica_id"  filter :options="areas" optionLabel="nombre" class=" p-inputtext-sm w-full " placeholder="Seleccionar Área" @change="filterProgramas()"
                    :class="{ 'p-invalid': !registro.areas_formacion_artistica_id && submitted }"
                    />
                    <small v-if="!registro.areas_formacion_artistica_id && submitted" class="p-error">Este campo es obligatorio.</small>
            </div>  
            
            <div class="field col-12 md:col-6">
                    <label>Programa</label>
                    <Dropdown v-model="registro.programas_formacion_artistica_id"  filter :options="programas" optionLabel="nombre" class=" p-inputtext-sm w-full " placeholder="Seleccionar Programa" @change="filterCarreras()"
                    :class="{ 'p-invalid': !registro.programas_formacion_artistica_id && submitted }"
                    />
                    <small v-if="!registro.programas_formacion_artistica_id && submitted" class="p-error">Este campo es obligatorio.</small>
            </div>  
            
            <div class="field col-12 md:col-6">
                    <label>Carrera</label>
                    <Dropdown  v-model="registro.carreras_formacion_artistica_id" :options="carrera" optionLabel="carrera" class=" p-inputtext-sm w-full" placeholder="Seleccionar Carrera" 
                    :class="{ 'p-invalid': !registro.carreras_formacion_artistica_id && submitted }"
                    />
                    <small v-if="!registro.carreras_formacion_artistica_id && submitted" class="p-error">Este campo es obligatorio.</small>
            </div>

            <div class="field col-12 md:col-6">
                    <label>Nivel de Formación</label>
                    <!--Dropdown v-model="registro.nivel_formacion_artistica_id" :options="nivel" optionLabel="nivel" class=" p-inputtext-sm w-full" placeholder="Seleccionar Tipo" /-->
                    <Dropdown @change = "getPlanEstudio()" v-model="registro.nivel_formacion_artistica_id" :options="nivel" optionLabel="nivel" class=" p-inputtext-sm w-full" placeholder="Seleccionar Nivel" 
                    :class="{ 'p-invalid': !registro.nivel_formacion_artistica_id && submitted }"
                    />
                    <small v-if="!registro.nivel_formacion_artistica_id && submitted" class="p-error">Este campo es obligatorio.</small>
            </div>
            
            <div class="field col-12 md:col-6">
                    <label>Total Hab.</label>
                    <InputNumber id="number-input" class="p-inputtext-sm w-full" v-model="registro.total_hab"  :disabled="false"  
                    :class="{ 'p-invalid': !registro.total_hab && submitted }"
                    />
                    <small v-if="!registro.total_hab && submitted" class="p-error">Este campo es obligatorio.</small>
            </div>

            <div class="field col-12 md:col-6">
                    <label>Total Clases</label>
                    <InputNumber id="number-input" class="p-inputtext-sm w-full" v-model="registro.total_clases"  :disabled="false"  
                    :class="{ 'p-invalid': !registro.total_clases && submitted }"
                    />
                    <small v-if="!registro.total_clases && submitted" class="p-error">Este campo es obligatorio.</small>
            </div>  

            <div class="field col-12 md:col-6">
                    <label>Carga Horaria</label>
                    <InputNumber id="number-input" class="p-inputtext-sm w-full" v-model="registro.carga_horaria"  :disabled="false"  
                    :class="{ 'p-invalid': !registro.carga_horaria && submitted }"
                    />
                    <small v-if="!registro.carga_horaria && submitted" class="p-error">Este campo es obligatorio.</small>
            </div>

         
            <div class="field col-12 md:col-4">
                    <label>Tiempo Estudio</label>
                    <InputNumber id="number-input" class="p-inputtext-sm w-full" v-model="registro.tiempo_estudio"  :disabled="false"  
                    :class="{ 'p-invalid': !registro.tiempo_estudio && submitted }"
                    />
                    <small v-if="!registro.tiempo_estudio && submitted" class="p-error">Este campo es obligatorio.</small>
            </div>  

            
            <!--div class="field col-12 md:col-4">
                    <label>Descripción</label>
                    <InputText type="text" class="p-inputtext-sm w-full" v-model="career.descripcion" />
            </div--> 

            <!--div class="field col-12 md:col-4">
                    <label>Observación</label>
                    <InputText type="text" class="p-inputtext-sm w-full" v-model="career.resuelve" />
            </div-->  
            
        </div>
        <template #footer>
            <Button label="Cancelar" icon="pi pi-times" @click="dialog = false" text />
            <Button label="Guardar" icon="pi pi-check" @click="submitForm" autofocus :disabled="!isFormValid" />
        </template>
    </Dialog>
</template>
<script >
import InstitutionDataService from '@/services/InstitutionDataService'
import AreaDataService from '@/services/AreaDataService'
import CareerTypeDataService from '@/services/CareerTypeDataService'
import TrainingLevelDataService from '@/services/TrainingLevelDataService'
import StudyRegimenDataService from '@/services/StudyRegimenDataService'
import StudyTimeDataService from '@/services/StudyTimeDataService'
import ResolutionTypeDataService from '@/services/ResolutionTypeService'
import CareerDataService from '@/services/CareerDataServie'
import CurseDataService from '@/services/CurseDataService'
import ArtisticaAreasService from '@/services/ArtisticaAreasService'
import ArtisticaProgramasService from '@/services/ArtisticaProgramasService'
import ArtisticaCarreraService from '@/services/ArtisticaCarreraService'
import ArtisticaNivelService from '@/services/ArtisticaNivelService'
import FormacionSuperiorArtisticaInstitutionsService from '@/services/FormacionSuperiorArtisticaInstitutionsService'
import CareerDataServie from '@/services/CareerDataServie'

export default{
    name: 'CareerInsitution',
    props: {
        iu_id: Number,
        sucursal_id: Number,
    },
    data: ()=>({
        careers:[],
        dialog: false,
        career:{},
        career_types:[],
        career_type:{},
        registro: {},
        areas: [],
        carrera: [],
        area:{},
        programas: [],  
        carrera: [],  
        academic_levels:[],
        academic_level:{},
        study_regimens: [],
        study_regimen:{},
        study_times:[],
        study_time: null,
        resolution_types: [],
        resolution_type: {},
        institution:{},
        areas_formacion_artistica_id: null,
        programas_formacion_artistica_id: null,
        carreras_formacion_artistica_id: null,
        nivel_formacion_artistica_id: null,
        total_hab: '',
        total_clases: '',
        carga_horaria: '',
        tiempo_estudio: ''
    }),
    mounted(){
        console.log("obteniendo Carreras")
        this.getInstitution()
        this.retrieveCareers()
        this.retrieveAreasInstitutions()
        this.retrieveProgramas()
        this.retrieveCareerTypes()
        this.retrieveTrainingLevel()
        this.retrieveStudyRegimens()
        this.retrieveResolutionTypes()
        this.retrieveCarreraInstitutions()
        this.retrieveNivelInstitutions()
        this.getCursosByInstitutoId()
        this.submitForm();
        
    },
    methods:{
        createCareerInst(){
            this.registro = {};
            this.dialog = true
            this.career = {}
            this.type = 'nuevo';
        },
        getInstitution()
        {
            InstitutionDataService.getInfo(this.$route.params.id)
            .then((response)=>{
                this.institution = response.data
                console.log("institution", response.data)
            })
        },
        retrieveCareers()
        {
            CurseDataService.getInstitutionCourses(this.$route.params.id) 
                .then((response) => {
                    // console.log(response.data.data);
                    this.careers = response.data.data;
                })
                .catch((e) => {
                    console.log(e);
                }); 
        },
        retrieveAreasInstitutions(){
            console.log('entra al servicio del front');
            ArtisticaAreasService.getAll()
                .then((response)=>{
                    this. areas = response.data.data
                     console.log('joseeee', response.data)
                })
                .catch((e)=>{
                    console.log(e)
                })
        },
        retrieveCarreraInstitutions(){
            console.log('entra al servicio del front');
            ArtisticaCarreraService.getAll()
                .then((response)=>{
                    this. carrera = response.data.data
                     console.log('joseeee', response.data)
                })
                .catch((e)=>{
                    console.log(e)
                })
        },
        retrieveProgramas(){
            console.log('entra al servicio del front');
            ArtisticaProgramasService.getAll()
                .then((response)=>{
                    this. programas = response.data.data
                     console.log('programasss', programas)
                })
                .catch((e)=>{
                    console.log(e)
                })
        },
        retrieveNivelInstitutions(){
            console.log('entra al servicio del front');
            ArtisticaNivelService.getAll()
                .then((response)=>{
                    this. nivel = response.data.data
                     console.log('jose', response.data)
                })
                .catch((e)=>{
                    console.log(e)
                })
        },
        retrieveCareerTypes()
        {
            CurseDataService.getCourses()
                .then((response)=>{
                    this.career_types = response.data.data
                    // console.log(response.data)
                })
        },
        retrieveTrainingLevel()
        {
            CurseDataService.getAcademicLevel()
                    .then((response)=>{
                    this.academic_levels = response.data
                    
                    // console.log(response.data)
                })
        },
        retrieveStudyRegimens()
        {
            CurseDataService.getRegimeStudy()
                .then((response)=>{
                    this.study_regimens = response.data
                    console.log("regimen tipo",response.data)
                })
        },
        retrieveResolutionTypes()
        {
            ResolutionTypeDataService.getAll()
                .then((response)=>{
                    // this.study_regimens = response.data
                    this.resolution_types = response.data
                    console.log(response.data)
                })
        },
        reloadTimes()
        {
            console.log("procesando cambios")
            if(Object.hasOwn(this.academic_level, 'id') && Object.hasOwn(this.study_regimen, 'id') )
            {
                console.log("obteniendo lista")
                StudyTimeDataService.getTimes(this.academic_level.id,this.study_regimen.id)
                    .then((response)=>{
                        let array_times = []
                        //para todos los casos de posibles convinaciones siempre debe votar un array unidimencional 
                        response.data.forEach(element => {
                            array_times.push({id:element})
                        });
                        this.study_times = array_times
                        console.log(this.study_times)
                    })
            }
            // console.log(Object.hasOwn(this.training_level, 'id'))
            // console.log(Object.hasOwn(this.study_regimen, 'id'))
        },
        redirectCareerView(id)
        {
            this.$router.push(`/careerArtistic/${id}`)
            console.log('id ===>',id);
        },
        dateToDMY(date) {
            var d = date.getDate();
            var m = date.getMonth() + 1; //Month from 0 to 11
            var y = date.getFullYear();
            return '' + (d <= 9 ? '0' + d : d) + '/' + (m<=9 ? '0' + m : m) + '/' + y;
        },
        saveCareerInstitutions()
        {
            if(this.type == 'nuevo'){
                /*CREA LA CARRERA AUTORIZADA*/ 
                console.log(this.career);
                console.log('registro ===>',this.registro);
                let new_career = {
                    "sucursal_id":this.institution.sucursal_id ,
                    "carrera_tipo_id": this.registro.carreras_formacion_artistica_id.id,
                    "area_tipo_id": 1,
                    "fecha_resolucion": '17/11/2024' ,
                    "resolucion_tipo_id": 1,
                    "numero_resolucion": "260",
                    "descripcion": this.registro.carreras_formacion_artistica_id.carrera??'',
                    "resuelve": '',
                    "carga_horaria": this.registro.carga_horaria,
                    "tiempo_estudio":  this.registro.tiempo_estudio,
                    "intervalo_gestion_tipo_id": 4,
                    "nivel_academico_tipo_id": this.registro.nivel_formacion_artistica_id.id??'',
                }  
             console.log('new_career',new_career);
            this.bunton_disabled = true
            CareerDataService.create(new_career)
                .then((response)=>{
                    console.log(response);
                    if(response.data.statusCode ==201)
                    {
                        let carrera_autorizada_id = response.data.data;    
                        console.log(new_career);
                        //NUEVO REGISTRO DE MODAL
                        this.registro.areas_formacion_artistica_id = this.registro.areas_formacion_artistica_id.id;
                        this.registro.programas_formacion_artistica_id = this.registro.programas_formacion_artistica_id.id;
                        this.registro.carreras_formacion_artistica_id = this.registro.carreras_formacion_artistica_id.id;
                        this.registro.nivel_formacion_artistica_id = this.registro.nivel_formacion_artistica_id.id;
                        this.registro.total_hab = this.registro.total_hab;
                        this.registro.total_clases = this.registro.total_clases;
                        this.registro.carga_horaria = this.registro.carga_horaria;
                        this.registro.tiempo_estudio = this.registro.tiempo_estudio;
                        this.registro.estado = true;
                        this.registro.institucion_educativa_id = this.$route.params.id;
                        this.registro.carrera_autorizada_id = carrera_autorizada_id;
                        console.log("registro-carreras", this.registro);
                        FormacionSuperiorArtisticaInstitutionsService.create(this.registro)
                            .then((response)=>{
                                if(response.data.statusCode ==201)
                                {
                                    //INSERTAR OPERATIVO
                                    let payload= {
                                        carrera_autorizada_id: parseInt(this.registro.carrera_autorizada_id),
                                        gestion_tipo_id: parseInt(2024),
                                        periodo_tipo_id: parseInt(0),
                                        fecha_inicio:'17-11-2024',
                                        fecha_fin:'17-11-2024',
                                        observacion: '',
                                        evento_tipo_id: parseInt(1) ,
                                        modalidad_evaluacion_tipo_id: 0 
                                    }
                                    console.log('payload calendario academico',payload)
                                    // operativo-carrera-autorizada/carrera/17
                                    CareerDataServie.createPeriodo(payload)
                                        .then(async(response)=>{
                                        console.log('response create',response)
                                        })   
                                         
                                    //INSERTAR EL PLAN DE ESTUDIO
                                    CareerDataService.createCarrerStudyResolutionByActive(this.registro)
                                    .then((response)=>{
                                        this.getCursosByInstitutoId();        
                                        this.$toast.add({ severity: 'success', summary: 'Success Message', detail: 'Registro completado', life: 3000 })
                                        this.dialog = false;
                                    })
                                    
                                }
                            })
                    }
                    else{
                        
                    }
                });
               
            }
            if(this.type == 'editar'){
                //EDITAR REGISTRO.
                this.registro.areas_formacion_artistica_id = this.registro.areas_formacion_artistica_id.id;
                this.registro.programas_formacion_artistica_id = this.registro.programas_formacion_artistica_id.id;
                this.registro.carreras_formacion_artistica_id = this.registro.carreras_formacion_artistica_id.id;
                this.registro.nivel_formacion_artistica_id = this.registro.nivel_formacion_artistica_id.id;
                this.registro.total_hab = this.registro.total_hab;
                this.registro.total_clases = this.registro.total_clases;
                this.registro.carga_horaria = this.registro.carga_horaria;
                this.registro.tiempo_estudio = this.registro.tiempo_estudio;
                FormacionSuperiorArtisticaInstitutionsService.editar(this.registro,data.id)
                    .then((response)=>{
                        if(response.data.statusCode ==201)
                        {
                            console.log(response.data);
                            this.getCursosByInstitutoId();        
                            this.$toast.add({ severity: 'success', summary: 'Success Message', detail: 'Registro completado', life: 3000 })
                            this.dialog = false;
                        }
                    })
                FormacionSuperiorArtisticaService.create(this.registro,data.id)
                    .then((response)=>{
                        if(response.data.statusCode ==201)
                        {
                            console.log(response.data);
                            this.getAll();        
                            this.$toast.add({ severity: 'success', summary: 'Success Message', detail: 'Registro completado', life: 3000 })
                        }
                    })
            }
                        
        },

        getCursosByInstitutoId(){
            console.log('listar resultado');
            FormacionSuperiorArtisticaInstitutionsService.getCursosByInstitutoId(this.$route.params.id)
                .then((response)=>{
                    if(response.data.statusCode == 200)
                    {
                        console.log('**********************');
                        console.log(response.data.data);
                        this.careers = [];
                        this.careers = response.data.data;
                        //this.$toast.add({ severity: 'success', summary: 'Success Message', detail: 'Registro completado', life: 3000 })
                    }
            })
        },
        submitForm() {
            console.log("aquiiiiiiiiiiiii")
            this.submitted = true;
            if (this.isFormValid) {
                // Guardar los datos del formulario
                console.log("Formulario válido, guardando datos...", this.registro);
                this.saveCareerInstitutions();
            }
         },
         filterProgramas(){
            console.log('area seleccionada => ', this.registro.areas_formacion_artistica_id);
            this.programas = this.registro.areas_formacion_artistica_id.programas;
        },
         filterCarreras(){
            console.log('programa seleccionado => ', this.registro.programas_formacion_artistica_id);
            this.carrera = this.registro.programas_formacion_artistica_id.carrera;
        },
        getPlanEstudio(){
            console.log(this.registro);
            FormacionSuperiorArtisticaInstitutionsService.getPlanEstudioById(this.registro.carreras_formacion_artistica_id.id,this.registro.nivel_formacion_artistica_id.id)
                .then((response)=>{
                    console.log(response);
                    this.registro.plan_estudio_carrera_id = response.data[0].id
                    console.log('this.registro',this.registro);
            })
        }
    },
    computed: {
        institution_id()
        {
            return this.iu_id
        },
        title ()
        {
            return "Adicionar Carrera"
        },
        isFormValid() {
            return (
            this.registro.areas_formacion_artistica_id &&
            this.registro.programas_formacion_artistica_id &&
            this.registro.carreras_formacion_artistica_id &&
            this.registro.nivel_formacion_artistica_id &&
            this.registro.total_hab &&
            this.registro.total_clases &&
            this.registro.carga_horaria &&
            this.registro.tiempo_estudio
            );
        },

       
        
    }
}
</script>