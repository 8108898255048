<template>
    <div id="mapViewer" style="height: 400px;width: 100%;"></div>
</template>
<script setup>
import "leaflet/dist/leaflet.css";
import InstitutionDataService from "@/services/InstitutionDataService";
import L from "leaflet";
import { ref, onMounted, defineProps, defineEmits  } from 'vue';
const center= ref([-16.5061403904391, -68.12774675921834])  
const url= ref('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png')
const attribution =ref('© <a href="https://openstreetmap.org">OpenStreetMap</a>')  // Attribution
const markerLatLng = ref( [-16.5061403904391, -68.12774675921834 ])
const title = ref('titulo')
const institution = ref(null)
const props = defineProps(['institution_id'])
const emit = defineEmits(['updateLocation'])

onMounted( async ()=>{
    console.log('load map')
    let response = await InstitutionDataService.getInfo(props.institution_id)
    institution.value = response.data
    if(institution.value.cordx &&  institution.value.cordy)
    {
        center.value = [institution.value.cordx, institution.value.cordy]
        markerLatLng.value  = [institution.value.cordx, institution.value.cordy]
    }
    title.value = institution.value.institucion_educativa
    console.log('response', response.data)
    
    loadMap()
})

const loadMap = () =>{
    const mapDiv = L.map("mapViewer").setView(center.value, 15);
    const homeIcon = L.icon({
    iconUrl: '/location.png',
    popupAnchor: [0, -15],
    iconSize: [40, 40],
    // ...
    });
    L.tileLayer( url.value, {
        maxZoom: 19,
        attribution: attribution.value
    }).addTo(mapDiv);

    emit('updateLocation',markerLatLng.value)
    let markerMain = new L.marker(markerLatLng.value, {icon: homeIcon,draggable:'true'}).addTo(mapDiv)
        .bindPopup(`${title.value}`)
        .openPopup();
    
    

    markerMain.on('dragend',function(event){
        console.log('dragend',event)
        markerMain = event.target
        let position = markerMain.getLatLng()
        markerLatLng.value = [position.lat, position.lng]
        markerMain.setLatLng(new L.LatLng(position.lat, position.lng),{draggable:'true'})
        mapDiv.addLayer(markerMain)
        updatePosition()
    })
  
    mapDiv.on('click',function(e){
        console.log('click event', e)
        markerMain.setLatLng(new L.LatLng(e.latlng.lat, e.latlng.lng),{draggable:'true'})
        markerLatLng.value = [e.latlng.lat, e.latlng.lng]
        mapDiv.addLayer(markerMain)
        updatePosition()
    })

    const updatePosition = ()=>
    {
        emit('updateLocation',markerLatLng.value)
    }
}


</script>