<template>
    
    <Dialog v-model:visible="visible" modal :header="title "   :style="{ width: '70vw' }">
       
        <div class="kdialog">
            
            <div class="kcustom-header">
                    
                    <div class="title" > 
                        <Dropdown v-model="item.planEstudioAsignatura.regimenGradoTipo" :options="regimens" filter optionLabel="regimenGrado" placeholder="Regimen" @change="getAsignaturas" />
                        : <Dropdown v-model="item.planEstudioAsignatura.asignaturaTipo" :options="asignaturas" filter optionLabel="asignatura" placeholder="Asignatura" />
                    </div>
                    <div class="icons">
                        <div class=" kicon-button-secondary"  @click="item.aulas.push({id:0,paraleloTipo:{},aulasDetalles:[],institutoEstudianteInscripcions:[], isNew:true })" v-tooltip.left="'Adicionar Paralelo'">
                            <i class='bx bxs-file-blank' ></i>

                        </div>
                    </div>
                    <!-- {{item }} -->
            </div>
    
            <div class="kcustom-content">
                <div class="card border border-1   text-dark mb-3  bg-light rounded-top" v-for="(aula,index2) in item.aulas " :key="index2" >
                <div class="card-header  bg-keyrus-dark   "> 
                    <div class="grid">
                        <div class="col">
                            Paralelo:  <Dropdown v-model="aula.paraleloTipo" :options="parallels" filter optionLabel="paralelo"
                                    placeholder="Seleccione" />
                            Cupo: <InputText type="text" v-model="aula.cupo " class="w-3rem" /> 
                            Turno:  <Dropdown v-model="aula.turnoTipo" :options="turnos" filter optionLabel="turno"
                                    placeholder="Seleccione" />
                        </div>
                        <div class="col flex align-content-center justify-content-end  ">
                            <div class="kicon-button-primary "  @click="aula.aulasDetalles.push({id:0,diaTipo:{}})"  v-tooltip.bottom="'Adicionar Horario'" >
                                <i class="bx bxs-plus-circle"></i>
                            </div>
                            <div class="pr-1"></div>
                            <div class="kicon-button-danger "  @click="removeItemAula(item.aulas,index2,aula)"  v-tooltip.bottom="'Eliminar Paralelo'" v-if="aula.institutoEstudianteInscripcions.length === 0" >
                                <i class="bx bxs-trash-alt"></i>
                            </div>
                           
                            <Avatar v-badge="aula.institutoEstudianteInscripcions.length" class="p-overlay-badge" icon="bx bxs-user-detail" size="large"  v-tooltip.left="'Estudiantes Inscritos'" v-else   />

                        </div>
                      
                    </div>
                    
                </div>
                <table class="table">
                    <thead>
                        <th>Aula</th>
                        <th>Dia</th>
                        <th>Hora Inicio</th>
                        <th>Hora Fin</th>
                        <th></th>
                    </thead>
                    <tbody>
                        <tr v-for="(dia,index3) in aula.aulasDetalles ">
                           
                            <td> <InputText type="text" class="w-3rem border-round" v-model="dia.numeroAula" /> </td>
                            <td><Dropdown v-model="dia.diaTipo" :options="days" optionLabel="dia"
                                placeholder="Seleccione Dia" /></td>
                            <td><Calendar id="calendar-timeonly" v-model="dia.horaInicio" timeOnly
                                class="w-5rem border-round" /></td>
                            <td><Calendar id="calendar-timeonly" v-model="dia.horaFin" timeOnly
                                class="w-5rem border-round" /></td>
                            <td>
                                <div class="kicon-button-danger "   @click="removeItemDetalle(aula.aulasDetalles, index3,dia)" v-tooltip.left="'Eliminar Horario'" >
                                    <i class="pi pi-times"></i>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                </div>
                <!-- {{ item }} -->
            </div>
        </div>   


        <template #footer>
            <Button label="Cancelar" icon="pi pi-times" text  @click="closeDialog()" />
            <Button label="Guardar" icon="pi pi-check" @click="SaveAcademicOffert()" />
        </template>
    </Dialog>
  
</template>
<script>

import CareerDataServie from '@/services/CareerDataServie';
import StudyRegimenDataService from '@/services/StudyRegimenDataService';
import ParallelDataService from '@/services/ParallelDataService';
import DayDataService from '@/services/DayDataService';
import AcademicOffertDataService from '@/services/AcademicOffertDataService';
export default{
    props: {
        dialog: Boolean,
        subject: Object,
        subjects: Array,
        title: String,
    },
    data: () => ({
        regimens: [],
        parallels: [],
        days: [],
        form:{},
        current_period:null,
        asignaturas:[],
        turnos:[]
    }),
    mounted() {
        this.getParallels();
        this.getDays();
        this.getInit();
        this.getCurrentPeriod();
        this.getTurnos();
        // this.getInstitutoPlanEstudioCarrera()
    },
    methods: {
        closeDialog() {
            console.log("enter methods");
            this.$emit("closeDialog", false);
        },
        getParallels() {
            ParallelDataService.getAll()
                .then((response) => {
                // console.log('paralelos',response.data)
                this.parallels = []
                 response.data.forEach(paralelo => {
                    this.parallels.push({id:paralelo.id,paralelo:paralelo.paralelo})
                 });

            });
        },
        
        async getTurnos()
        {
            let response = await AcademicOffertDataService.getTurnos()
            console.log('resp',response.data)
            this.turnos = response.data
        },
        removeItemAula(list, index,aula) {
            // console.log(aula)
            if(aula.id>0)
            {
                this.item.eliminado_aulas.push({id:aula.id})

                aula.aulasDetalles.forEach(detalle => {
                    this.item.eliminado_detalles.push({id:detalle.id})
                });
            }
            

            list.splice(index, 1)
        },
        removeItemDetalle(list, index,dia) {
            if(dia.id>0)
            {
                this.item.eliminado_detalles.push({id:dia.id})
            }
            list.splice(index, 1)
        },
        getDays() {
            DayDataService.getAll()
                .then((reponse) => {
                this.days = []
                reponse.data.forEach(dia => {
                    this.days.push({id:dia.id, dia: dia.dia})
                });
                console.log(this.days)
                })
        },
        async getInit() {
            let response = await CareerDataServie.get(this.$route.params.career_id);
            this.career = response.data.data;
            if (this.career.regimen_estudio === "AÑO") {
                let result = await CareerDataServie.getRegimenGrades("year");
                this.regimens = result.data.data;
                // console.log(result.data.data)
            }
            else {
                let result = await CareerDataServie.getRegimenGrades("semester");
                this.regimens = result.data.data;
            }
            response = await AcademicOffertDataService.getInstitutoPlanStudioCarrera(this.$route.params.plan_estudio_carrera_id);
            console.log('plan studio carrera',response.data.data)
            this.instituto_plan_studio_carrera = response.data.data;

            

            //    let result = await StudyRegimenDataService.getSubjets(this.instituto_plan_studio_carrera.planEstudioCarreraId, response.data.data.id)
            //   regimen_grado.subjects = result.data.data
        },
        SaveAcademicOffert(){
            this.closeDialog()
            let payload = {}
            payload.gestion_tipo_id=this.current_period.gestion_tipo_id
            payload.periodo_tipo_id=this.current_period.periodo_tipo_id
            payload.instituto_plan_estudio_carrera_id = parseInt(this.$route.params.plan_estudio_carrera_id) 
            payload.plan_estudio_asignatura_id = this.item.planEstudioAsignatura.asignaturaTipo.plan_estudio_asignatura_id
            
            payload.aulas = []
            this.item.aulas.forEach(aula => {
                let new_aula = {
                    id: aula.id,
                    cupo: parseInt(aula.cupo) ,
                    paralelo_tipo_id: aula.paraleloTipo.id,
                    turno_tipo_id: aula.turnoTipo.id,
                    detalles: []
                }
                
                aula.aulasDetalles.forEach(detalle => {
                    
                    let inicio = detalle.horaInicio
                    let fin = detalle.horaFin
                    if(detalle.id === 0)
                    {
                        let tini = new Date(detalle.horaInicio)
                        console.log('tini',tini)
                        inicio = `${tini.getHours()}:${tini.getMinutes()}`
                        let tfin = new Date(detalle.horaFin)
                        console.log('tfin',tfin)
                        fin = `${tfin.getHours()}:${tfin.getMinutes()}`
                    }
                    let new_detalle = {
                        id: detalle.id,
                        dia_tipo_id: detalle.diaTipo.id,
                        hora_inicio: inicio,
                        hora_fin: fin,
                        numero_aula:  detalle.numeroAula
                    }
                    new_aula.detalles.push(new_detalle)
                });

                payload.aulas.push(new_aula)
            });
            
            payload.eliminado_aulas = this.item.eliminado_aulas
            payload.eliminado_detalles = this.item.eliminado_detalles
        

            AcademicOffertDataService.editSubjectsAcademicOffer(payload)
                .then((response)=>{
                    console.log(response)
                    this.$store.dispatch('showMessage',{severity:'success',title:'Oferta Academica',message:'Se guardaron los cambios, el modulo esta en construccion disculpe las molestias'})
                    this.$emit("updateData",true);
                })

        },

        async getCurrentPeriod() {
            
            let response = await CareerDataServie.getCurrentPeriod(this.$route.params.career_id)
                console.log(response.data.data)    
            this.current_period = response.data.data
                // await this.getTeachers()
                //     this.getSubjects()
                // // })
        },
        async getAsignaturas(){
            console.log('obtengiendo asignaturas',this.item.planEstudioAsignatura.regimenGradoTipo)
            if(this.item.planEstudioAsignatura.regimenGradoTipo){
                let response = await AcademicOffertDataService.getSubjectsStudyPlanByRegimenId(this.$route.params.plan_estudio_carrera_id,this.item.planEstudioAsignatura.regimenGradoTipo.id)
                // console.log('asignaturas',response.dta)
                this.asignaturas = response.data.data

            }else
            {
                this.asignaturas = []
            }
        },
        setAsignaturas(asignaturas_edit)
        {
            console.log('set asignaturas',asignaturas_edit)

            this.asignaturas = asignaturas_edit
        }
        // getInstitutoPlanEstudioCarrera()
        // {
        //     AcademicOffertDataService.getInstitutoPlanStudioCarrera(this.$route.params.plan_estudio_carrera_id)
        //         .then((response) => {
        //         this.instituto_plan_studio_carrera = response.data.data
        //         } ) 
        // },
    },
    computed: {
        visible: {
            get() {
                return this.dialog;
            },
            set() {
                this.$emit("closeDialog", false);
            }
        },
        item() {
            let item = this.subject;
            return item;
        },
        // current_period()
        // {
        //     let current_period = this.career
        //     return current_period
        // }
        // asignaturas() {
        //     let asignaturas = this.subjects
        //     return asignaturas
        // }
    },
    
}
</script>
