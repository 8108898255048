import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// import axios from 'axios'
// window.axios = require('axios').default;

//window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

import PrimeVue from 'primevue/config';

/* Import main.scss file on bootup */
import '@/scss/app.scss';


import 'primeflex/primeflex.css'

//theme prime
import "primevue/resources/themes/lara-light-blue/theme.css";     
// import "primevue/resources/themes/tailwind-light/theme.css";     
   
//core prime

//components prime
import 'primeicons/primeicons.css';
import "primevue/resources/primevue.min.css";
import 'animate.css'; 
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';   // optional
import Row from 'primevue/row';                   // optional
import Button from 'primevue/button';
import Card from 'primevue/card';
import InputText from 'primevue/inputtext';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Dropdown from 'primevue/dropdown';
import Divider from 'primevue/divider';
import Checkbox from 'primevue/checkbox';
import Calendar from 'primevue/calendar';
import Panel from 'primevue/panel';
import Dialog from 'primevue/dialog';

import RadioButton from 'primevue/radiobutton'

import SelectButton from 'primevue/selectbutton';
import Listbox from 'primevue/listbox';
import TabMenu from 'primevue/tabmenu';
import Breadcrumb from 'primevue/breadcrumb';

import Chart from 'primevue/chart';

import { GDialog } from 'gitart-vue-dialog'
import 'gitart-vue-dialog/dist/style.css'
import Tooltip from 'primevue/tooltip';


// import VueGoogleMaps from '@fawmi/vue-google-maps'


import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import Tag from 'primevue/tag';
import Toolbar from 'primevue/toolbar';
import InputSwitch from 'primevue/inputswitch';
import InputNumber from 'primevue/inputnumber';

import AutoComplete from 'primevue/autocomplete';
import InputMask from 'primevue/inputmask';
import Steps from 'primevue/steps';

import MultiSelect from 'primevue/multiselect';
import Fieldset from 'primevue/fieldset';
import SpeedDial from 'primevue/speeddial';
import SplitButton from 'primevue/splitbutton';
import Splitter from 'primevue/splitter';
import SplitterPanel from 'primevue/splitterpanel';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Avatar from 'primevue/avatar'
import BlockUI from 'primevue/blockui'
import Carousel from 'primevue/carousel'
import VirtualScroller from 'primevue/virtualscroller';
import Message from 'primevue/message';
import InlineMessage from 'primevue/inlinemessage';
import OverlayPanel from 'primevue/overlaypanel';

import ConfirmDialog from 'primevue/confirmdialog';
import ConfirmationService from 'primevue/confirmationservice';
import Badge from 'primevue/badge';
import BadgeDirective from 'primevue/badgedirective';
import VueGoogleMaps from '@fawmi/vue-google-maps'
import Timeline from 'primevue/timeline';
import ProgressSpinner from 'primevue/progressspinner';
import Chip from 'primevue/chip';
import FileUpload from 'primevue/fileupload';

const app = createApp(App)
app.directive('tooltip', Tooltip);
app.directive('badge', BadgeDirective);
// const axiosInstance = axios.create({
//     withCredentials: true,
//   })
//icons  https://boxicons.com/
// import 'boxicons'


app.use(ToastService);
app.use(PrimeVue);  
app.use(router);
app.use(store);
app.use(ConfirmationService);

app.use(VueGoogleMaps, {
   load: {
       key: 'AIzaSyCXyIROL1yNeF8Jz9mAiOxh-QMlQAhUGkI',
       // language: 'de',
   },
})

app.component('GDialog', GDialog)
app.component('DataTable', DataTable)


app.component('Column', Column)
app.component('ColumnGroup',ColumnGroup)
app.component('Row',Row)
app.component('Button',Button)
app.component('Card',Card)
app.component('InputText',InputText)
app.component('Accordion',Accordion)
app.component('AccordionTab',AccordionTab)
app.component('Dropdown',Dropdown)
app.component('Divider',Divider)
app.component('Checkbox',Checkbox)
app.component('Toast',Toast)
app.component('Calendar',Calendar)
app.component('Tag',Tag)
app.component('Panel',Panel)
app.component('Dialog',Dialog)
app.component('Toolbar',Toolbar)

app.component('InputSwitch',InputSwitch)
app.component('InputNumber',InputNumber)
app.component('AutoComplete',AutoComplete)
app.component('SelectButton',SelectButton)

app.component('InputMask',InputMask)
app.component('Steps', Steps);

app.component('Listbox',Listbox)
app.component('RadioButton',RadioButton)
app.component('TabMenu',TabMenu)
app.component('MultiSelect',MultiSelect)
app.component('Breadcrumb',Breadcrumb)
app.component('Fieldset',Fieldset)
app.component('SpeedDial',SpeedDial)
app.component('SplitButton',SplitButton)


app.component('Splitter',Splitter)
app.component('SplitterPanel',SplitterPanel)
app.component('TabView',TabView)
app.component('TabPanel',TabPanel)
app.component('Avatar',Avatar)
app.component('BlockUI',BlockUI)
app.component('Carousel',Carousel)
app.component('VirtualScroller',VirtualScroller)
app.component('Message',Message)
app.component('InlineMessage',InlineMessage)
app.component('Chart',Chart)
app.component('ConfirmDialog',ConfirmDialog)
app.component('OverlayPanel',OverlayPanel)
app.component('Badge',Badge)
app.component('Timeline',Timeline)
app.component('ProgressSpinner',ProgressSpinner)
app.component('Chip',Chip)
app.component('FileUpload',FileUpload)



// //filter
app.config.globalProperties.$filters = {
   dateFormat(value){
      // console.log('value',value)
      if(typeof(value) != 'undefined' && value != null){
         let date = new Date(value)
         // console.log('fecha',date)
         let d = date.getDate();
         let m = date.getMonth() + 1; //Month from 0 to 11
         let y = date.getFullYear();
         return '' + (d <= 9 ? '0' + d : d) + '/' + (m<=9 ? '0' + m : m) + '/' + y;
         // return date.toLocaleDateString('es-pa')
      }
      return ''
   },
   dateString(value)
   {
      let string_date = value.substring(0,10)
      let array = string_date.split('-')
      return `${array[2]}/${array[1]}/${array[0]}`
      //se asume 1986-07-21
   },
   dateCustom(value)
   {
      // let string_date = value.substring(0,10)
      return new Date(value)
   },
   getFecha(isoString)
   {
      let date = new Date(isoString)
      return date.toLocaleDateString("es-ES",{
         weekday: 'long',
         year: 'numeric',
         month: 'long',
         day: 'numeric',
       })
   },
   getHora(isoString)
   {
      let date = new Date(isoString)
      return date.toLocaleTimeString()
   },
   renameFile(string_name) {
      if(string_name.length > 10)
      {
         return string_name.slice(0,10)+'..'+string_name.slice((string_name.length-4),string_name.length)
      }
      return string_name
   }

}
app.mount('#app')


require('bootstrap');
// window.$ = window.jQuery = require('jquery');
// require('./template/app-style-switcher');
// require('./template/custom');
// require('./template/sidebarmenu');
// require('./template/waves'); TODO: check 'hasOwnProperty' error Do not access Object.prototype method 'hasOwnProperty' from target object maybe not to associate html element 
// createApp(App).use(store).use(router).mount('#app')
