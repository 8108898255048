import http from "@/http-common";
class CareerDataService {
    getAll(){
      return http.get("/campo-saber-tipo/itt");
    }

    // getInstitutionAreas(id){
    //   return http.get(`/jurisdiccion-geografica/2001/codigo/${id}`);
    // }

    get(id) {
      return http.get(`/carrera-autorizada/${id}`);
    }
    /*
    get(id) {
      return http.get(`/carrera-autorizada-artistica/${id}`);
    }*/
  
    create(data) {
      return http.post("/carrera-autorizada-resolucion", data);
    }

    show(carrera_autorizada_id)
    {
      return http.get(`/carrera-autorizada-resolucion/show/${carrera_autorizada_id}`)
    }
  
    createCarrerStudyResolution(data)
    {
      return http.post("/plan-estudio-resolucion",data);
    }

    createCarrerStudyResolutionByActive(data)
    {
      //POST: localhost:3006/api/instituto-plan-estudio-carrera
      return http.post("/instituto-plan-estudio-carrera",data);
    }

    getResolutions(id)
    {
      return http.get(`/instituto-plan-estudio-carrera/carrera/${id}`); //fañta enviar la lista
    }
    
    getCurrentResolutions(id)
    {
      return http.get(`/plan-estudio-carrera/resoluciones-carrera/${id}`);
    }

    getGestion()
    {
      return http.get('/gestion-tipo/vigente')
    }

    getPeriodoTipo(intervalo_gestion_tipo_id)
    {
      return http.get(`/periodo-tipo/regimen/${intervalo_gestion_tipo_id}`)
    }

    getPeriodosAcademicos(carrera_autorizada_id)
    {
      return http.get(`/operativo-carrera-autorizada/carrera/${carrera_autorizada_id}`)
    }
    getPeriodosAcademicosGestion(carrera_autorizada_id,gestion_id)
    {
      return http.get(`/operativo-carrera-autorizada/carrera-gestion/${carrera_autorizada_id}/${gestion_id}`)
    }

    createPeriodo(data)
    { 
      return http.post('/operativo-carrera-autorizada',data)
    }

    editPeriodo(payload,carrera_autorizada_id)
    {
      return http.put(`/operativo-carrera-autorizada/fechas/${carrera_autorizada_id}`,payload)
    }

    getCurrentPeriod(carrera_autorizada_id){
      return http.get(`/operativo-carrera-autorizada/vigente/carrera/${carrera_autorizada_id}`)
    }

    generatePeriods(payload)
    { 
      return http.post('/operativo-carrera-autorizada/generar_operativos',payload)
    }

    generateExcepcionalPeriod(payload)
    { 
      return http.post('/operativo-carrera-autorizada/generar_recuperatorio_excepcional',payload)
    }
    
    getCareerAuth(sie){
      return http.get(`/carrera-autorizada/ie/${sie}`);
    }
    
    
    getCareerAuthorized(sie){
      return http.get(`/inscripcion/carreras-autorizadas/ie/${sie}`);
    }

    getCareerAuthorizedArtistic(sie){
      return http.get(`/inscripcion/carreras-autorizadas-artistic/ie/${sie}`);
    }

    //consultar el ide a cristina   
    getEvaluationModalities(id){
      return http.get(`/modalidad-evaluacion-tipo/regimen/${id}`)
    }

    //operativo PARA TRABAJAR EN OFERTA, ASIG DOCENTE
    getOperativoCarrera(career_id)
    {
      return http.get(`/operativo-carrera-autorizada/vigente/carrera/${career_id}`)
    }

    deleteResolution(plan_estudio_resolucion_id,career_id)
    {
      return http.delete(`/plan-estudio-resolucion/asignacion/${plan_estudio_resolucion_id}/${career_id}`)
    }

    // Cambio de estado de operativo en calendario academico
    changeStateOperative(operativo_carrera_autorizada_id)
    {
      return http.get(`/operativo-carrera-autorizada/estado/${operativo_carrera_autorizada_id}`)
    }

    getRegimenGrades(type)
    {
      if(type==='year')
      {
        return http.get('regimen-grado-tipo/years')
      }else
      {
        return http.get('regimen-grado-tipo/semesters')
      }
    }

    updateStateStudyPlan(data)
    {
      return http.post('/plan-estudio-carrera-seguimiento', data)
    }

    getStudyPlanCareer(plan_estudio_carrera_id)
    {
      return http.get(`/plan-estudio-carrera/asignaturas-carrera/${plan_estudio_carrera_id}`)
    }

    editCareerResolution(carrera_autorizada_resolucion_id,payload)
    {
      return http.put(`/carrera-autorizada-resolucion/${carrera_autorizada_resolucion_id}`,payload)
    }

    createCareerResolution(payload)
    {
      return http.post('/carrera-autorizada-resolucion/create',payload)
    }

    deleteCareerResolution(id)
    {
      return http.delete(`/carrera-autorizada-resolucion/${id}`)
    }

    getCareerInsitute(institucion_educativa_sucursal_id)
    {
      return http.get(`/carrera-autorizada-resolucion/career_institute/${institucion_educativa_sucursal_id}`)
    }

    delete(id)
    {
      return http.delete(`/carrera-autorizada/${id}`)
    }

  //   update(id: any, data: any): Promise<any> {
  //     return http.put(`/users/${id}`, data);
  //   }
  
  //   delete(id: any): Promise<any> {
  //     return http.delete(`/users/${id}`);
  //   }
  
  //   deleteAll(): Promise<any> {
  //     return http.delete(`/users`);
  //   }
  
  //   findByTitle(title: string): Promise<any> {
  //     return http.get(`/users?title=${title}`);
  //   }
  }
  
  export default new CareerDataService();