import http from "@/http-common";
class SubDirectionDataService {
    // getAll(){
    //   return http.get("/campo-saber-tipo/itt");
    // }

    getStudyPlans(unidad_territorial_id,estado_insituto_id){
        return http.get(`/institucion-educativa-sucursal/planes-lugar-estado/${unidad_territorial_id}/${estado_insituto_id}`)
    }

    getStudyPlan(id_plan_estudio_carrera){
        return http.get(`/plan-estudio-carrera/asignaturas-carrera/${id_plan_estudio_carrera}`)
    }


    // plan-estudio-carrera-seguimiento/estados/239
    getStudyPlanHistory(id_plan_estudio_carrera)
    {
        return http.get(`/plan-estudio-carrera-seguimiento/estados/${id_plan_estudio_carrera}`)
    }

    getPassabilityList(unidad_territorial_id,estado_insituto_id){
        return http.get(`/matricula-estudiante/list/${estado_insituto_id}/${unidad_territorial_id}`)
    }
    
    // getInstitutionAreas(id){
    //   return http.get(`/jurisdiccion-geografica/2001/codigo/${id}`);
    // }

    // get(id) {
    //   return http.get(`/jurisdiccion-geografica/2001/codigo/${id}`);
    // }
  
  //   create(data: any): Promise<any> {
  //     return http.post("/users", data);
  //   }
  
  //   update(id: any, data: any): Promise<any> {
  //     return http.put(`/users/${id}`, data);
  //   }
  
  //   delete(id: any): Promise<any> {
  //     return http.delete(`/users/${id}`);
  //   }
  
  //   deleteAll(): Promise<any> {
  //     return http.delete(`/users`);
  //   }
  
  //   findByTitle(title: string): Promise<any> {
  //     return http.get(`/users?title=${title}`);
  //   }
  }
  
  export default new SubDirectionDataService();