export const helperMixin = {
    data:()=>({

    }),

    methods: {
        dateToDMY(date_string) {
            let date = new Date(date_string)
            var d = date.getDate();
            var m = date.getMonth() + 1; //Month from 0 to 11
            var y = date.getFullYear();
            return '' + (d <= 9 ? '0' + d : d) + '/' + (m<=9 ? '0' + m : m) + '/' + y;
        },
    }
  };