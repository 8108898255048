import http from "@/http-common";
class AcademicOffertDataService {
    
    // getAll(){
    //   return http.get("/area-tipo");
    // }

    create(data){
        return http.post('oferta-curricular',data)
    }

    getInstitutoPlanStudioCarrera(id){
        return http.get(`/instituto-plan-estudio-carrera/id/${id}`);
    }

    getSubjects(plan_estudio_carrera_id,gestion_tipo_id,periodo_tipo_id)
    {
        return http.get(`/oferta-curricular/byCarreraGestionPeriodo/${plan_estudio_carrera_id}/${gestion_tipo_id}/${periodo_tipo_id}`);
    }

    getTeachers(ritt)
    {
        return http.get(`/maestro-inscripcion/getAllDocentesByUeGestion/${ritt}`);
    }

    assingTeachers(data)
    {
        return http.post('/aula-docente',data)
    }

    getShowSubjects(plan_estudio_carrera_id,gestion_tipo_id,periodo_tipo_id)
    {
        return http.get(`/oferta-curricular/byCarreraGestionPeriodoDocente/${plan_estudio_carrera_id}/${gestion_tipo_id}/${periodo_tipo_id}`);
    }

    getShowSubjectsRegimenGrado(plan_estudio_carrera_id,gestion_tipo_id,periodo_tipo_id,regimen_grado_tipo_id)
    {
        // byCarreraGestionPeriodoRegimenGrado
        return http.get(`/oferta-curricular/byCarreraGestionPeriodoRegimenGrado/${plan_estudio_carrera_id}/${gestion_tipo_id}/${periodo_tipo_id}/${regimen_grado_tipo_id}`);
    }

    //
    getSubjectsCoursesByCareerAuth(carrera_zutorizada_id, matricula_estudiante_id)
    {
        return http.get(`/inscripcion/materiasNuevoByCarreraAutorizadaId/${carrera_zutorizada_id}/${matricula_estudiante_id}`);
    }

    getSubjectsCoursesTransitabilityByCareerAuth(carrera_autorizada_id, matricula_estudiante_id)
    {
        return http.get(`/inscripcion/materiasTransitabilidadByCarreraAutorizadaId/${carrera_autorizada_id}/${matricula_estudiante_id}`)
    }

    getRegularSubjectsCoursesByCareerAuth(carrera_zutorizada_id, matricula_estudiante_id)
    {
        return http.get(`/inscripcion/materiasAntiguoByCarreraAutorizadaId/${carrera_zutorizada_id}/${matricula_estudiante_id}`);
    }
    
    getSubjectsStudyPlanById(plan_estudio_asignatura_id)
    {
        return http.get(`/plan-estudio-asignatura/asignaturas/${plan_estudio_asignatura_id}`);
    }

    getSubjectsStudyPlanByRegimenId(plan_estudio_carrera_id,regimen_id)
    {
        return http.get(`/instituto-plan-estudio-carrera/asignaturas-grado/${plan_estudio_carrera_id}/${regimen_id}`)
    }
    /* save Academic Offert */
    editSubjectsAcademicOffer(data)
    {
        return http.post('/aula/crea-actualiza', data);
    }  

    updateStudyPlanResolution(id, data)
    {
        return http.put(`/plan-estudio-carrera/${id}`,data)
    }

    getRegimenStudio(instituto_plan_estudio_carrera_id){
        return http.get(`/oferta-curricular/getRegimenEstudio/${instituto_plan_estudio_carrera_id}`)
    }

    getParalelos(instituto_plan_estudio_carrera_id, regimen_grado_tipo_id, gestion_tipo_id, periodo_tipo_id)
    {
        console.log('gestion',gestion_tipo_id)
        console.log('periodo',periodo_tipo_id)
        console.log('regimen_grado_tipo',periodo_tipo_id)
        return http.get(`/oferta-curricular/getParalelos/${instituto_plan_estudio_carrera_id}/${regimen_grado_tipo_id}/${gestion_tipo_id}/${periodo_tipo_id} `)
    }

    getTurnos(){
        return http.get(`/turno-tipo`)
    }

    getHomologationsStudent(instituto_plan_estudio_carrera_id, persona_id,regimen_grado_tipo_id)
    {
        return http.get(`/oferta-curricular/homologationEstudiante/${instituto_plan_estudio_carrera_id}/${persona_id}/${regimen_grado_tipo_id}`)
    }
    // getInstitutionAreas(id){
    //   return http.get(`/jurisdiccion-geografica/2001/codigo/${id}`);
    // }

    // get(id) {
    //   return http.get(`/jurisdiccion-geografica/2001/codigo/${id}`);
    // }
  
  //   create(data: any): Promise<any> {
  //     return http.post("/users", data);
  //   }
  
  //   update(id: any, data: any): Promise<any> {
  //     return http.put(`/users/${id}`, data);
  //   }
  
  //   delete(id: any): Promise<any> {
  //     return http.delete(`/users/${id}`);
  //   }
  
  //   deleteAll(): Promise<any> {
  //     return http.delete(`/users`);
  //   }
  
  //   findByTitle(title: string): Promise<any> {
  //     return http.get(`/users?title=${title}`);
  //   }
  }
  
  export default new AcademicOffertDataService();